import React from "react";
import CustomTextarea from "../../../../../../../Components/CustomInputs/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";

const SeriousDiseasesAdditionalInjuryDescriptionField: React.FC = () => {
   const dispatch = useDispatch();

   const AdditionalInjuryDescriptionValueSeriousDiseases = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 5
         )?.additionalInjuryDescription
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "seriousdiseases",
            "AdditionalInjuryDescription",
            visibilityMatrixes
         )}
      >
         <CustomTextarea
            labelText="Podrobný popis vzniku úrazu, či nemoci"
            name="AdditionalInjuryDescription"
            value={AdditionalInjuryDescriptionValueSeriousDiseases || ""}
            onChange={(e) => {
               dispatch(
                  updateFirstStepDynamicFields({
                     value: e.target.value.slice(0, 240),
                     field: "additionalInjuryDescription",
                     typeOfClaimEnum: 5,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               "seriousdiseases.AdditionalInjuryDescription",
               claimFieldErrorsMessages
            )}
            placeholderText="Popište co nejpodrobněji"
            maxCharacters="240"
            highlightMaxCharacters={
               AdditionalInjuryDescriptionValueSeriousDiseases?.length === 240
            }
         />
      </WholeLineContainer>
   );
};

export default SeriousDiseasesAdditionalInjuryDescriptionField;
