import GridContainer from "../../../Components/ReportFormTemplate/GridContainer";
import {
   Card,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import InjurySection from "./ClaimSections/Sections/InjurySection/InjurySection";
import HospitalizationSection from "./ClaimSections/Sections/HospitalizationSection/HospitalizationSection";
import WorkIncapacitySection from "./ClaimSections/Sections/WorkIncapacitySection/WorkIncapacitySection";
import DisabilitySection from "./ClaimSections/Sections/DisabilitySection/DisabilitySection";
import DeathSection from "./ClaimSections/Sections/DeathSection/DeathSection";
import GeneralSection from "./ClaimSections/Sections/GeneralSection/GeneralSection";
import WholeLineContainer from "../../../Components/ReportFormTemplate/WholeLineContainer";
import ChildcareSection from "./ClaimSections/Sections/ChildcareSection/ChildcareSection";
import SeriousDiseasesSection from "./ClaimSections/Sections/SeriousDiseasesSection/SeriousDiseasesSection";
import PermConsequences from "./ClaimSections/Sections/PermconsequencesSection/PermConsequences";
import ImportantContactsSection from "./ClaimSections/Sections/ImportantContactsSection/ImportantContactsSection";
import { Box } from "@mui/material";

export type DynamicFields =
   | "dateOfClaimFrom"
   | "dateOfClaimTo"
   | "timeOfClaim"
   | "additionalInjuryDescription";

const FirstStepContainer = () => {
   return (
      <Card variant="Attentional">
         <Box sx={{ paddingBottom: "1rem" }}>
            <GridContainer>
               <WholeLineContainer>
                  <>
                     <Heading hasMargin={false} mb="1rem" variant="Large">
                        Popis pojistné události
                     </Heading>
                     <Paragraph hasMargin={false}>
                        Pojistnou událost chceme vyřešit co nejrychleji. Bez
                        podrobností o jejím vzniku se neobejdeme. Prosím,
                        vyplňte proto pečlivě následující otázky.
                     </Paragraph>
                  </>
               </WholeLineContainer>

               <InjurySection />
               <HospitalizationSection />
               <WorkIncapacitySection />
               <ChildcareSection />
               <DisabilitySection />
               <SeriousDiseasesSection />
               <DeathSection />
               <PermConsequences />
               <GeneralSection />
               <ImportantContactsSection />
            </GridContainer>
         </Box>
      </Card>
   );
};

export default FirstStepContainer;
