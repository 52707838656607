import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import CustomRadioGroup from "../../../../../Components/CustomInputs/CustomRadioGroup";
import { updateThirdStepGeneralFields } from "../../../../../redux/reducers/claimReducer";
import {
   handleShowErrorMessage,
   resetClaimFieldErrors,
} from "../../../../../redux/reducers/claimFieldErrorsReducer";

const PaymentSelection = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "isPayByBankAccount";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const resetThirdStepFields = () => {
      dispatch(
         updateThirdStepGeneralFields({
            value: "",
            field: "variableNumber",
         })
      );
      dispatch(
         updateThirdStepGeneralFields({
            value: "",
            field: "paymentAddress",
         })
      );
      dispatch(
         updateThirdStepGeneralFields({
            value: "",
            field: "bankCode",
         })
      );
      dispatch(
         updateThirdStepGeneralFields({
            value: "",
            field: "bankAccountNumber",
         })
      );
   };

   return (
      <CustomRadioGroup
         heading=""
         firstLabel="Bankovní převodem"
         secondLabel="Nemám bankovní účet"
         alignment="vertical"
         value={fieldValue}
         updateFunc={(e: any) => {
            dispatch(
               updateThirdStepGeneralFields({
                  value: e === "true" ? true : false,
                  field: fieldNameLowerCase,
               })
            );
            dispatch(resetClaimFieldErrors());
            resetThirdStepFields();
         }}
         validationText={handleShowErrorMessage(
            fieldNameUpperCase,
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      />
   );
};

export default PaymentSelection;
