import React from "react";
import CustomInput from "../../../../../../../Components/CustomInputs/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";

const ChildCareTimeOfClaimField: React.FC = () => {
   const dispatch = useDispatch();

   const TimeOfClaimValueChildcare = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 3
         )?.timeOfClaim
   );
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   return (
      <CustomInput
         labelText="Čas škody"
         placeholderText="Uveďte čas"
         name="TimeOfClaim"
         value={TimeOfClaimValueChildcare || ""}
         onChange={(e) => {
            dispatch(
               updateFirstStepDynamicFields({
                  value: e.target.value,
                  field: "timeOfClaim",
                  typeOfClaimEnum: 3,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            "childcare.TimeOfClaim",
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "childcare",
            "TimeOfClaim",
            visibilityMatrixes
         )}
      />
   );
};

export default ChildCareTimeOfClaimField;
