import MainLayout from "./Components/MainLayout/MainLayout";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import {
   FontFaces as NNFontFaces,
   useLocale as useNNLocale,
} from "@nn-design-system/react-component-library";
import getFontPaths from "@nn-design-system/fonts/dist/webpack";
import { useSelector } from "react-redux";
import { StoreType } from "./redux/store/store";
import withAuth from "./HOC/withAuth";
import ClaimFormPage from "./Pages/ClaimReport/ClaimFormPage";
import PersonSelection from "./Pages/PersonSelection/PersonSelectionPage";
import AnonymClaimSelectionPage from "./Pages/ClaimSelection/AnonymousClaimSelectionPage";
import AuthorizedClaimSelectionPage from "./Pages/ClaimSelection/AuthorizedClaimSelectionPage";
import config from "@nn-design-system/config/web";
import LoginPage from "./Pages/Login/LoginPage";

function App() {
   useNNLocale("cs-CZ");

   const isLoggedIn = useSelector(
      (state: StoreType) => state.authState.isLoggedIn
   );

   const isPersonSelected = useSelector(
      (state: StoreType) => state.authState.isPersonSelected
   );

   config.getFeatureFlags().useAccessibleLightTheme.disable();
   //  config.getFeatureFlags().newFont.disable();

   return (
      <div className="App">
         <MainLayout>
            <NNFontFaces paths={getFontPaths()} />
            <Routes>
               <Route
                  path="/claimSelection/anonym"
                  element={withAuth(AnonymClaimSelectionPage, [
                     isPersonSelected,
                     isLoggedIn,
                  ])}
               />
               <Route
                  path="/claimSelection/authorized"
                  element={withAuth(AuthorizedClaimSelectionPage, [
                     isPersonSelected,
                     isLoggedIn,
                  ])}
               />
               <Route path="/" element={<LoginPage />} />
               <Route
                  path="/personSelection"
                  element={withAuth(PersonSelection, [isLoggedIn])}
               />
               <Route
                  path="/claimReport/anonym"
                  element={withAuth(ClaimFormPage, [
                     isPersonSelected,
                     isLoggedIn,
                  ])}
               />
               <Route
                  path="/claimReport/authorized"
                  element={withAuth(ClaimFormPage, [
                     isPersonSelected,
                     isLoggedIn,
                  ])}
               />
               <Route path="*" element={<NotFoundPage />}></Route>
            </Routes>
         </MainLayout>
      </div>
   );
}

export default App;
