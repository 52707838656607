import React from "react";
import SectionTemplate from "../../Template/SectionTemplate";
import ChildCareAdditionalInjuryDescriptionField from "./ChildcareFields/ChildcareAdditionalInjuryDescriptionField";
import ChildCareDateOfClaimFromField from "./ChildcareFields/ChildcareDateOfClaimFromField";
import ChildCareTimeOfClaimField from "./ChildcareFields/ChildcareTimeOfClaimField";
import ChildcareDateOfClaimToField from "./ChildcareFields/ChildcareDateOfClaimToField";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { Paragraph } from "@nn-design-system/react-component-library";

const ChildcareSection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("childcare", visibilityMatrixes)) return null;
   return (
      <SectionTemplate heading="Ošetřovné dítěte" iconName="FirstAid">
         <ChildCareDateOfClaimFromField />
         <ChildcareDateOfClaimToField />
         <ChildCareTimeOfClaimField />
         <ChildCareAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default ChildcareSection;
