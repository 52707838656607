import React, { useState } from "react";
import { Box } from "@mui/material";
import {
   Button,
   Paragraph,
   SpinnerLoader,
   ToggleButtonGroup,
} from "@nn-design-system/react-component-library";
import useFetch from "../CustomHooks/useFetch";
import { StoreType } from "../redux/store/store";
import { useSelector } from "react-redux";
import CustomInput from "./CustomInputs/CustomInput";
import SanitizeNumber from "../Utils/SanitizeInputs/SanitizeNumber";

type QRCodeSectionProps = {
   QRCodeUrl: string;
   documentUrl: string;
   accessNumber: string;
   claimReportPartitionKey: string;
   claimReportRowKey: string;
   claimId: string;
};

type sendLinkToType = "mobile" | "email" | undefined;

const QRCodeSection: React.FC<QRCodeSectionProps> = ({
   QRCodeUrl,
   documentUrl,
   accessNumber,
   claimReportPartitionKey,
   claimReportRowKey,
   claimId,
}) => {
   const [sendLinkTo, setSendLinkTo] = useState<sendLinkToType>(undefined);
   const [isLoaded, setIsLoaded] = useState(false);

   const { email } = useSelector(
      (state: StoreType) => state.claimState.personModel
   );

   const { throutfulPersonEmail } = useSelector(
      (state: StoreType) => state.claimState.personModel.personThroutful
   );

   const phoneNumber = useSelector(
      (state: StoreType) => state.claimState.personModel.phoneNumber
   );

   const { throutfulPersonPhoneNumber } = useSelector(
      (state: StoreType) => state.claimState.personModel.personThroutful
   );

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isDeathBeingReported = selectedTypeOfClaims.some(
      (claim: string) => claim === "death"
   );

   const actualEmail = isDeathBeingReported ? throutfulPersonEmail : email;
   const actualPhoneNumber = isDeathBeingReported
      ? throutfulPersonPhoneNumber
      : phoneNumber;

   const { token } = useSelector((state: StoreType) => state.tokenState);

   const handleSwitchSendLinkTo = (e: sendLinkToType) => {
      setSendLinkTo(e);
   };

   const { sendRequest: reguestSend2DLinkToAnotherDevice } = useFetch();

   const Send2DLinkToAnotherDevice = () => {
      reguestSend2DLinkToAnotherDevice({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_Send2DLinkToAnotherDevice,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            claimId: claimId,
            documentUrl: documentUrl,
            sendLinkToPhoneNumber: sendLinkTo === "mobile",
            sendLinkToEmail: sendLinkTo === "email",
            accessNumber: accessNumber,
            claimReportRowKey: claimReportRowKey,
            claimReportPartitionKey: claimReportPartitionKey,
            email: actualEmail,
            phoneNumber: actualPhoneNumber,
         },
      });
   };

   return (
      <Box
         sx={{
            display: "flex",
            gap: "2rem",
            "@media (max-width: 500px)": {
               flexDirection: "column",
               gap: ".5rem",
               justifyContent: "center",
               alignItems: "center",
            },
         }}
      >
         <Box
            sx={{
               width: "12rem",
               overflow: "hidden",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <img
               src={QRCodeUrl}
               alt="QR code"
               onLoad={() => {
                  setIsLoaded(true);
               }}
               style={{
                  width: "100%",
                  height: "auto",
                  display: isLoaded ? "block" : "none",
               }}
            />
            {!isLoaded && <SpinnerLoader />}
         </Box>
         <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Paragraph hasMargin={false} mt=".5rem">
               Poslat odkaz adresy podpisu na:
            </Paragraph>
            <Box sx={{ marginTop: ".5rem" }}>
               <ToggleButtonGroup
                  labelText=""
                  onChange={(e: any) => {
                     handleSwitchSendLinkTo(e.target.value);
                  }}
                  toggleButtons={[
                     {
                        text: "Mobil",
                        // iconProps: {
                        //    name: "Phone",
                        // },
                        value: "mobile",
                     },
                     {
                        text: "Email",
                        // iconProps: {
                        //    name: "Envelope",
                        // },
                        value: "email",
                     },
                  ]}
                  value={sendLinkTo}
                  variant="Horizontal"
               />
            </Box>

            <Box sx={{ marginTop: "1rem" }}></Box>
            {sendLinkTo && (
               <Box
                  sx={{ display: "flex", alignItems: "flex-end", gap: ".5rem" }}
               >
                  {sendLinkTo === "mobile" && (
                     <CustomInput
                        labelText=""
                        dataState="ReadOnly"
                        placeholderText="Zadejte telefonní číslo"
                        value={actualPhoneNumber || ""}
                        onChange={() => {}}
                        validationText=""
                     />
                  )}
                  {sendLinkTo === "email" && (
                     <CustomInput
                        labelText=""
                        dataState="ReadOnly"
                        placeholderText="Zadejte e-mail"
                        value={actualEmail || ""}
                        onChange={() => {}}
                        validationText=""
                     />
                  )}
                  <Button
                     variant="SecondaryMedium"
                     onClick={Send2DLinkToAnotherDevice}
                  >
                     Odeslat
                  </Button>
               </Box>
            )}
         </Box>
      </Box>
   );
};

export default QRCodeSection;
