import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { colourInteractionDefault } from "../../Config/colors";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetFiftStepFinishFields } from "../../redux/reducers/claimReducer";

const steps = [
   "Co se stalo",
   "Osobní údaje",
   "Pojistné plnění",
   "Dokumenty",
   "Dokončení hlášení",
];

type CustomStepperProps = {
   completedStep: number;
   activeStep: number;
   setActiveStep: any;
};

const CustomStepper: React.FC<CustomStepperProps> = ({
   completedStep,
   activeStep,
   setActiveStep,
}) => {
   const dispatch = useDispatch();

   const handleStep = (step: number) => {
      dispatch(resetFiftStepFinishFields());
      setActiveStep(step);
   };

   const theme = useTheme();
   const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

   const renderLabel = (label: string, currentStepIndex: number) => {
      if (activeStep === currentStepIndex) {
         return label;
      }
      return isBelowSm ? "" : label;
   };

   return (
      <Box sx={{ width: "100%", margin: "2rem 0 2rem 0" }}>
         <Stepper activeStep={completedStep} alternativeLabel>
            {steps.map((label, index) => (
               <Step
                  key={label}
                  sx={{
                     padding: "0 !important",
                     "& > button": {
                        padding: "24px 8px !important",
                     },
                  }}
               >
                  <StepButton
                     sx={{
                        marginLeft: "0rem",
                        "&  > span > span:first-of-type > svg": {
                           transition: "all ease 300ms",
                        },
                        "&:hover": {
                           "&  > span > span:first-of-type > svg": {
                              transform: "scale(1.2)",
                           },
                        },
                        "& > span:first-of-type > span:first-of-type > svg": {
                           color:
                              index <= completedStep
                                 ? colourInteractionDefault
                                 : "",
                        },
                        "& > span:first-of-type > span:nth-of-type(2) > span": {
                           fontWeight:
                              index === activeStep ? "700 !important" : "500",
                           marginTop: ".5rem",
                        },
                     }}
                     onClick={() => {
                        if (activeStep === index) {
                           return;
                        }
                        handleStep(index);
                     }}
                  >
                     {renderLabel(label, index)}
                  </StepButton>
               </Step>
            ))}
         </Stepper>
      </Box>
   );
};

export default CustomStepper;
