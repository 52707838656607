import React from "react";
import SectionTemplate from "../../Template/SectionTemplate";
import WorkIncapacityDateOfClaimFromField from "./WorkIncapacityFields/WorkIncapacityDateOfClaimFromField";
import WorkIncapacityDateOfClaimToField from "./WorkIncapacityFields/WorkIncapacityDateOfClaimToField";
import WorkIncapacityTimeOfClaimField from "./WorkIncapacityFields/WorkIncapacityTimeOfClaimField";
import WorkIncapacityAdditionalInjuryDescriptionField from "./WorkIncapacityFields/WorkIncapacityAdditionalInjuryDescriptionField";
import { StoreType } from "../../../../../../redux/store/store";
import { useSelector } from "react-redux";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";

const WorkIncapacitySection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("incapacity", visibilityMatrixes)) return null;
   return (
      <SectionTemplate heading="Pracovní neschopnost" iconName="Calendar">
         <WorkIncapacityDateOfClaimFromField />
         <WorkIncapacityDateOfClaimToField />
         <WorkIncapacityTimeOfClaimField />
         <WorkIncapacityAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default WorkIncapacitySection;
