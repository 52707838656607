import React from "react";
import SectionTemplate from "../../Template/SectionTemplate";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import DeathDateOfClaimFromField from "./DeathFields/DeathDateOfClaimFromField";
import DeathDateOfClaimToField from "./DeathFields/DeathDateOfClaimToField";
import DeathTimeOfClaimField from "./DeathFields/DeathTimeOfClaimField";
import DeathAdditionalInjuryDescriptionField from "./DeathFields/DeathAdditionalInjuryDescriptionField";

const DeathSection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("death", visibilityMatrixes)) return null;

   return (
      <SectionTemplate heading="Úmrtí" iconName="Angel">
         <DeathDateOfClaimFromField />
         <DeathDateOfClaimToField />
         <DeathTimeOfClaimField />
         <DeathAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default DeathSection;
