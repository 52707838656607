import { Box } from "@mui/material";
import {
   Card,
   Checkbox,
   Heading,
   Icon,
} from "@nn-design-system/react-component-library";
import { NameType } from "@nn-design-system/react-component-library/dist/components/Icon/generated";
import React, { useState } from "react";
import { claimsType } from "../Config/types/types";
import {
   colourBackgroundDefault,
   colourInteractionDefault,
} from "../Config/colors";

type ClaimCardProps = {
   url: claimsType;
   heading: string;
   icon: NameType;
   selectedClaims: string[];
   toggleClaim(claim: string): void;
};

const ClaimCard: React.FC<ClaimCardProps> = ({
   url,
   heading,
   icon,
   toggleClaim,
}) => {
   const [isCardSelected, setIsCardSelected] = useState(false);

   const checkboxStateHandler = () => {
      return isCardSelected ? "Checked" : "NotChecked";
   };

   return (
      <Box
         onClick={() => {
            toggleClaim(url);
            setIsCardSelected(!isCardSelected);
         }}
         sx={{
            cursor: "pointer",
            position: "relative",
            zIndex: "3",
            transition: "all 200ms ease",
            ":hover": { scale: "1.025" },
            "& > div": {
               backgroundColor: isCardSelected
                  ? `#FFF7EE !important`
                  : `${colourBackgroundDefault} !important`,
               height: "100% !important",
               display: "flex !important",
               alignItems: "center !important",
               width: "100% !important",
               "& > div": {
                  width: "100% !important",
               },
            },
         }}
      >
         <Card variant="Attentional">
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
               }}
            >
               <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
               >
                  <Box sx={{ minWidth: "1.5rem", marginRight: "1rem" }}>
                     <Icon
                        name={icon}
                        color={colourInteractionDefault}
                        variant="Large"
                        customSize="1.75rem !important"
                     />
                  </Box>
                  <Heading
                     mr="1rem"
                     mt=".4rem"
                     variant={"Medium"}
                     hasMargin={false}
                  >
                     {heading}
                  </Heading>
               </Box>
               <Checkbox
                  labelText=""
                  dataState={checkboxStateHandler()}
                  onChange={() => {}}
                  variant="Small"
               />
            </Box>
         </Card>
      </Box>
   );
};

export default ClaimCard;
