import React from "react";
import { useDispatch } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const OtherJobField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "otherJob";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <CustomInput
         labelText="Jiná činnost"
         placeholderText="Uveďtě ostatní výdělečné činnosti"
         value={(fieldValue as string) || ""}
         onChange={(e) => {
            dispatch(
               updateSecondStepGeneralsNestedFields({
                  value: e.target.value,
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            fieldNameUpperCase,
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
         inputRef={ref}
      />
   );
};

export default OtherJobField;
