import { Box } from "@mui/material";
import { Card } from "@nn-design-system/react-component-library";
import React from "react";

type GridContainerProps = {
   children: React.ReactNode;
};

const GridContainer: React.FC<GridContainerProps> = ({ children }) => {
   return (
      <Box
         sx={{
            display: "grid",
            padding: "1rem",
            "@media (max-width: 600px)": {
               padding: "0",
            },
            gridTemplateColumns: {
               xs: "1fr",
               sm: "minmax(10rem, 25rem) minmax(10rem, 25rem)",
            },
            justifyContent: "center",
            columnGap: "4rem",
            rowGap: "1.5rem",
            alignItems: "start",
         }}
      >
         {children}
      </Box>
   );
};

export default GridContainer;
