import { Box } from "@mui/material";
import { SpinnerLoader } from "@nn-design-system/react-component-library";
import { useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";

const LoadingIcon: React.FC = () => {
   const isLoading = useSelector(
      (state: StoreType) => state.loadingState.isLoading
   );
   return (
      <>
         {isLoading ? (
            <Box
               sx={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  display: "grid",
                  placeItems: "center",
                  zIndex: "10000",
                  backdropFilter: "blur(2px)",
                  "& > span": {
                     position: "fixed",
                  },
               }}
            >
               <SpinnerLoader variant="Large" />
            </Box>
         ) : null}
      </>
   );
};

export default LoadingIcon;
