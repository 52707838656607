import React from "react";
import GridContainer from "../../../Components/ReportFormTemplate/GridContainer";
import {
   Card,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import PersonalSection from "./Sections/PersonalSection/PersonalSection";
import WorkSection from "./Sections/WorkSection/WorkSection";
import AddressSection from "./Sections/AdressSection/AddressSection";
import ContactSection from "./Sections/ContactSection/ContactSection";
import PersonThroutfulSection from "./Sections/PersonThroutfulSection/PersonThroutfulSection";
import WholeLineContainer from "../../../Components/ReportFormTemplate/WholeLineContainer";
import icon from "../../../Assets/iconForSecondStep.png";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreType } from "../../../redux/store/store";
import ResponsibilityPersonSection from "./Sections/ResponsibilityPersonSection/ResponsibilityPersonSection";

type SecondStepContainerProps = {};

const SecondStepContainer: React.FC<SecondStepContainerProps> = ({}) => {
   const { isThirdPerson } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );

   const isDeathSelected = useSelector(
      (state: StoreType) => state.claimState.selectedTypeOfClaims
   ).some((item) => item === "death");

   return (
      <>
         <Card variant="Attentional">
            <GridContainer>
               <WholeLineContainer>
                  <Box
                     sx={{
                        display: "grid",
                        gridTemplateColumns: " 1fr auto",
                        gridTemplateAreas: {
                           xs: `
                        'heading icon'
                        'text text'
                        `,
                           sm: `
                        'heading icon'
                        'text icon'
                        `,
                        },
                     }}
                  >
                     <Box sx={{ gridArea: "heading" }}>
                        <Heading hasMargin={false} variant="Large">
                           Vyplňte údaje
                        </Heading>
                     </Box>
                     <Box sx={{ gridArea: "text" }}>
                        <Paragraph hasMargin={false} mt="1rem">
                           {isDeathSelected
                              ? "Při posuzování pojistné události se neobejdeme bez údajů zesnulé osoby."
                              : isThirdPerson
                              ? "Při posuzování pojistné události se neobejdeme bez údajů pojištěné osoby."
                              : "Při posuzování pojistné události se neobejdeme bez vašich údajů."}
                        </Paragraph>
                     </Box>
                     <Box
                        sx={{
                           gridArea: "icon",
                           height: { xs: "2rem", sm: "4rem" },
                           transform: {
                              xs: "translateY(-.5rem)",
                              sm: "translateY(-1rem)",
                           },
                           "& > *": { width: { xs: "4rem", sm: "auto" } },
                        }}
                     >
                        <img src={icon} alt="picture of papers"></img>
                     </Box>
                  </Box>
               </WholeLineContainer>
            </GridContainer>
            <PersonalSection />
            <WorkSection />
            <AddressSection />
            <ContactSection />
            <ResponsibilityPersonSection />
         </Card>
         <PersonThroutfulSection />
      </>
   );
};

export default SecondStepContainer;
