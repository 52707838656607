import { Box } from "@mui/material";
import {
   AttentionBlock,
   IconButton,
   Paragraph,
} from "@nn-design-system/react-component-library";
import React from "react";

type Data = {
   ETag: string;
   OfflineBgColor: string;
   OfflineColor: string;
   OfflineFrom: Date;
   OfflineMessage: string;
   OfflineTo: Date;
   PartitionKey: string;
   RowKey: string;
   SourceApplication: string;
   TargetApplications: string;
   Timestamp: string;
   WarningBgColor: string;
   WarningColor: string;
   WarningFrom: Date;
   WarningMessage: string;
};

type Props = {
   data: Data[];
};

const InfoBlock = ({ data }: Props) => {
   const [displayInfo, setDisplayInfo] = React.useState(true);

   const currentDate = new Date();

   if (!data) {
      return null;
   }

   const result = data.find((item) => {
      const offlineToDate = new Date(item.OfflineTo);
      const warningFromDate = new Date(item.WarningFrom);
      const doesItemIncludeOHS = item.TargetApplications.includes("OHS");

      return (
         currentDate >= warningFromDate &&
         currentDate <= offlineToDate &&
         doesItemIncludeOHS
      );
   });

   if (!result || !displayInfo) {
      return null;
   }
   return (
      <Box
         sx={{
            padding: "0 3px",
            width: "clamp(10rem, 100%, 70em) !important",
            position: "relative",
         }}
      >
         <AttentionBlock variant="Warning">
            <Box sx={{ "& > p": { paddingRight: "3rem" } }}>
               <Paragraph>{result.WarningMessage}</Paragraph>
            </Box>
         </AttentionBlock>
         <Box
            sx={{
               position: "absolute",
               right: ".5rem",
               bottom: "50%",
               transform: "translate(0, 50%)",
            }}
         >
            <IconButton
               onClick={() => {
                  setDisplayInfo(false);
               }}
               iconName="Cross"
            ></IconButton>
         </Box>
      </Box>
   );
};

export default InfoBlock;
