import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { StoreType } from "../../../../../../redux/store/store";
import CustomDateInput from "../../../../../../Components/CustomInputs/CustomDateInput";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import { Paragraph } from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";

const EmployeeToField: React.FC = () => {
	const dispatch = useDispatch();
	const fieldNameLowerCase = "employeeTo";
	const fieldNameUpperCase =
		fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

	const fieldValue = useSelector(
		(state: StoreType) =>
			state.claimState.personModel.employeeModel[fieldNameLowerCase]
	);

	const visibilityMatrixes = useSelector(
		(state: StoreType) => state.visibilityMatrixesState
	);

	const claimFieldErrorsMessages = useSelector(
		(state: StoreType) => state.claimFieldErrorsState
	);
	const ref = React.useRef<HTMLDivElement>(null);
	useScrollToTopErrorInput(ref, fieldNameUpperCase);
	return (
		<Box>
			<CustomDateInput
				labelText="Ukončení pracovního poměru"
				value={fieldValue || undefined}
				onChange={(e: any) => {
					dispatch(
						updateSecondStepGeneralsNestedFields({
							value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
							sectionName: "employeeModel",
							field: fieldNameLowerCase,
						})
					);
				}}
				validationText={handleShowErrorMessage(
					fieldNameUpperCase,
					claimFieldErrorsMessages
				)}
				dependencyValue={shouldRenderSpecField(
					"GeneralFields",
					fieldNameUpperCase,
					visibilityMatrixes
				)}
				inputRef={ref}
			/>
			{shouldRenderSpecField(
				"GeneralFields",
				fieldNameUpperCase,
				visibilityMatrixes
			) && (
				<Paragraph
					mt=".75rem"
					variant="Small"
					hasMargin={false}
				>
					Pokud ještě nedošlo k ukončení pracovního poměru, vyplňte pouze jeho
					počátek.
				</Paragraph>
			)}
		</Box>
	);
};

export default EmployeeToField;
