import React from "react";
import { Box } from "@mui/material";
import {
   Card,
   Heading,
   Select,
} from "@nn-design-system/react-component-library";
import { setCurrentlyReportedPerson } from "../redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import { ReactComponent as DialogIcon } from "../Assets/SpeechBubblesQuestionmark.svg";
import { InsuredPersons } from "../redux/reducers/authReducer";

type PersonSelectionFieldProps = {
   callGetClientClaims: (claimForPersonId: string) => void;
   insuredPersons: InsuredPersons[];
};

const PersonSelectionField: React.FC<PersonSelectionFieldProps> = ({
   callGetClientClaims,
   insuredPersons, // Add this line
}) => {
   const dispatch = useDispatch();

   return (
      <Box
         sx={{
            position: "relative",
            zIndex: "100",
            width: "clamp(16rem, 100%, 35rem)",
         }}
      >
         <Card variant="Attentional">
            <Box sx={{ display: "flex", gap: "1rem" }}>
               <Box sx={{ flexGrow: "1" }}>
                  <Heading hasMargin={false} variant={"Small"}>
                     Pojistnou událost hlásím za
                  </Heading>
                  <Select
                     value=""
                     onChange={(e) => {
                        const value = e.target.value as string;
                        const birthNumber = value.substring(
                           0,
                           value.indexOf(" ")
                        );
                        const fullName = value.substring(
                           value.indexOf(" ") + 1
                        );
                        dispatch(
                           setCurrentlyReportedPerson({
                              name: fullName,
                              birthNumber: birthNumber,
                           })
                        );
                        callGetClientClaims(birthNumber);
                     }}
                     options={
                        insuredPersons.map((person) => ({ // Use insuredPersons prop here
                           value: `${person.personalId} ${person.firstname} ${person.lastname}`,
                           text: `${person.firstname} ${
                              person.lastname
                           } - ${person.personalId.slice(
                              0,
                              person.personalId.length - 4
                           )}/${person.personalId.slice(
                              person.personalId.length - 4
                           )}`,
                        }))
                     }
                     placeholderText="Vyberte osobu"
                  />
               </Box>
               <Box sx={{ marginTop: "1rem" }}>
                  <DialogIcon />
               </Box>
            </Box>
         </Card>
      </Box>
   );
};

export default PersonSelectionField;