import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { updateThirdStepGeneralFields } from "../../../../../../redux/reducers/claimReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import SanitizeNumber from "../../../../../../Utils/SanitizeInputs/SanitizeNumber";

const BankAccountNumberField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "bankAccountNumber";

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const isThereSpecError = () => {
      return claimFieldErrorsMessages.some(
         (error, index) => error.propertyId === "BankAccountNumber"
      );
   };

   return (
      <CustomInput
         labelText="Číslo účtu"
         placeholderText="Zadejte číslo účtu"
         value={(fieldValue as string) || ""}
         onChange={(e) => {
            dispatch(
               updateThirdStepGeneralFields({
                  value: SanitizeNumber(e.target.value),
                  field: fieldNameLowerCase,
               })
            );
         }}
         dataState={isThereSpecError() ? "Invalid" : undefined}
         validationText={""}
      />
   );
};

export default BankAccountNumberField;
