import React from "react";
import CustomTextarea from "../../../../../../../Components/CustomInputs/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const DeathAdditionalInjuryDescriptionField: React.FC = () => {
   const dispatch = useDispatch();

   const AdditionalInjuryDescriptionValueDeath = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 6
         )?.additionalInjuryDescription
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, "death.AdditionalInjuryDescription");
   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "death",
            "AdditionalInjuryDescription",
            visibilityMatrixes
         )}
      >
         <CustomTextarea
            labelText="Popis okolností, za kterých došlo k úmrtí"
            placeholderText="Uveďte okolnosti"
            name="AdditionalInjuryDescription"
            value={AdditionalInjuryDescriptionValueDeath || ""}
            onChange={(e) => {
               dispatch(
                  updateFirstStepDynamicFields({
                     value: e.target.value.slice(0, 240),
                     field: "additionalInjuryDescription",
                     typeOfClaimEnum: 6,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               "death.AdditionalInjuryDescription",
               claimFieldErrorsMessages
            )}
            inputRef={ref}
            maxCharacters="240"
            highlightMaxCharacters={
               AdditionalInjuryDescriptionValueDeath?.length === 240
            }
         />
      </WholeLineContainer>
   );
};

export default DeathAdditionalInjuryDescriptionField;
