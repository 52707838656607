import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import {
   Card,
   Divider,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import InsuredRelationshipField from "./PersonThroutfulFields/InsuredRelevationshipField";
import ThroutfulPersonAddressField from "./PersonThroutfulFields/ThroutfulPersonAddressField";
import ThroutfulPersonEmailField from "./PersonThroutfulFields/ThroutfulPersonEmailField";
import ThroutfulPersonFirstnameField from "./PersonThroutfulFields/ThroutfulPersonFirstnameField";
import ThroutfulPersonLastnameField from "./PersonThroutfulFields/ThroutfulPersonLastnameField";
import ThroutfulPersonIsForeignerField from "./PersonThroutfulFields/ThroutfulPersonIsForeignerField";
import ThroutfulPersonPersonalIdField from "./PersonThroutfulFields/ThroutfulPersonPersonalIdField";
import ThroutfulPersonPhoneNumberField from "./PersonThroutfulFields/ThroutfulPersonPhoneNumberField";
import ThroutfulPersonPlaceOfBirthField from "./PersonThroutfulFields/ThroutfulPersonPlaceOfBirthField";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import ThroutfulPersonPostCodeField from "./PersonThroutfulFields/ThroutfulPersonPostCodeField";
import ThroutfulPersonCityField from "./PersonThroutfulFields/ThroutfulPersonCityField";
import ThroutfulPersonIsContactAddressDifferent from "./PersonThroutfulFields/ThroutfulPersonIsContactAdressDifferentField";
import ThroutfulPersonContactAddressField from "./PersonThroutfulFields/ThroutfulPersonContactAddressField";
import ThroutfulPersonContactCityField from "./PersonThroutfulFields/ThroutfulPersonContactCityField";
import ThroutfulPersonContactPostCodeField from "./PersonThroutfulFields/ThroutfulPersonContactPostCode";
import ThroutfulPersonIsMinor from "./PersonThroutfulFields/ThroutfulPersonIsMinor";
import ThroutfulPersonCitizenshipField from "./PersonThroutfulFields/ThroutfulPersonCitizenshipField";
import ThroutfulPersonChildFirstnameField from "./PersonThroutfulFields/ThroutfulPersonChildFirstname";
import ThroutfulPersonChildLastnameField from "./PersonThroutfulFields/ThroutfulPersonChildLastname";
import ThroutfulPersonChildPersonalIdField from "./PersonThroutfulFields/ThroutfulPersonChildPersonalId";

const PersonThroutfulSection = () => {
   const isMinor = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.personThroutful.throutfulPersonIsMinor
   );

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isDeathClaim = selectedTypeOfClaims.some((claim) => claim === "death");

   if (!isDeathClaim) {
      return null;
   }

   return (
      <Card mt="2rem">
         <GridContainer>
            <WholeLineContainer>
               <>
                  <>
                     <Heading hasMargin={false} variant="Large">
                        Údaje o obmyšlené osobě
                     </Heading>
                     <Paragraph hasMargin={false} mt="1rem">
                        Obmyšlenou osobou je ten, kdo byl pojištěným zvolen jako
                        příjemce plnění v případě jeho úmrtí. Pokud probíhá
                        dědické řízení, může to být i soudem určená osoba.
                     </Paragraph>
                  </>
               </>
            </WholeLineContainer>

            <WholeLineContainer>
               <>
                  <Heading
                     variant={"Medium"}
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     Vztah k zemřelé(mu)
                  </Heading>
                  <Divider variant="Brand"></Divider>
               </>
            </WholeLineContainer>

            <InsuredRelationshipField />
            <ThroutfulPersonIsMinor />

            {isMinor && (
               <>
                  <WholeLineContainer>
                     <>
                        <Heading
                           variant={"Medium"}
                           hasMargin={false}
                           mb=".5rem"
                           mt="1rem"
                        >
                           Osobní údaje nezletilé obmyšlené osoby
                        </Heading>
                        <Divider variant="Brand"></Divider>
                        {/* {isMinor && (
                           <Paragraph mt="1rem" hasMargin={false}>
                              Peníze nemůžeme vyplatit nezletilému. Proto
                              potřebujeme doplnit údaje zákonného zástupce (nebo
                              opatrovníka), kterému prostředky pošleme.
                           </Paragraph>
                        )} */}
                     </>
                  </WholeLineContainer>
                  <ThroutfulPersonChildFirstnameField />
                  <ThroutfulPersonChildLastnameField />
                  <ThroutfulPersonChildPersonalIdField />
               </>
            )}

            <WholeLineContainer>
               <>
                  <Heading
                     variant={"Medium"}
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     {isMinor
                        ? "Osobní údaje zákonného zástupce obmyšlené osoby"
                        : "Osobní údaje obmyšlené osoby"}
                  </Heading>
                  <Divider variant="Brand"></Divider>
                  {isMinor && (
                     <Paragraph mt="1rem" hasMargin={false}>
                        Peníze nemůžeme vyplatit nezletilému. Proto potřebujeme
                        doplnit údaje zákonného zástupce (nebo opatrovníka),
                        kterému prostředky pošleme.
                     </Paragraph>
                  )}
               </>
            </WholeLineContainer>

            <ThroutfulPersonFirstnameField />
            <ThroutfulPersonLastnameField />
            <ThroutfulPersonPlaceOfBirthField />
            <ThroutfulPersonPersonalIdField />
            <ThroutfulPersonIsForeignerField />
            <ThroutfulPersonCitizenshipField />

            <WholeLineContainer>
               <>
                  <Heading
                     variant={"Medium"}
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     Adresa trvalého bydliště
                  </Heading>
                  <Divider variant="Brand"></Divider>
               </>
            </WholeLineContainer>

            <ThroutfulPersonAddressField />
            <ThroutfulPersonCityField />
            <ThroutfulPersonPostCodeField />
            <ThroutfulPersonIsContactAddressDifferent />
            <ThroutfulPersonContactAddressField />
            <ThroutfulPersonContactCityField />
            <ThroutfulPersonContactPostCodeField />

            <WholeLineContainer>
               <>
                  <Heading
                     variant={"Medium"}
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     Kontaktní údaje
                  </Heading>
                  <Divider variant="Brand"></Divider>
               </>
            </WholeLineContainer>

            <ThroutfulPersonPhoneNumberField />
            <ThroutfulPersonEmailField />
         </GridContainer>
      </Card>
   );
};

export default PersonThroutfulSection;
