import React from "react";
import { Card, Heading } from "@nn-design-system/react-component-library";
import GridContainer from "../../../Components/ReportFormTemplate/GridContainer";
import WholeLineContainer from "../../../Components/ReportFormTemplate/WholeLineContainer";
import PaymentSelection from "./Sections/PaymentSelectionSection/PaymentSelection";
import PaymentFields from "./Sections/BankSection/PaymentBankFields";

const ThirdStepContainer: React.FC = () => {
   return (
      <Card variant="Attentional">
         <GridContainer>
            <WholeLineContainer>
               <Heading variant={"Large"} hasMargin={false}>
                  Jakým způsobem chcete pojistné vyplatit?
               </Heading>
            </WholeLineContainer>

            <PaymentSelection />
            <PaymentFields />
         </GridContainer>
      </Card>
   );
};

export default ThirdStepContainer;
