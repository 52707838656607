import { createSlice } from "@reduxjs/toolkit";

export type VisibilityField = {
   propertyId: string;
   isVisible: boolean;
};

export interface VisibilityMatrix {
   typeOfClaimName: string;
   classFields: VisibilityField[];
}

export interface VisibilityMatrixes {
   [key: number]: VisibilityMatrix;
}

const initialState: VisibilityMatrixes = {};

const VisibilityMatrixesSlice = createSlice({
   name: "visibilityMatrixes",
   initialState,
   reducers: {
      setVisibilityMatrixes: (state, action) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      resetVisilityMatrixes: () => {
         return { ...initialState };
      },
   },
});

export const { setVisibilityMatrixes, resetVisilityMatrixes } =
   VisibilityMatrixesSlice.actions;
export default VisibilityMatrixesSlice.reducer;

export const shouldRenderSpecField = (
   typeOfClaim: string,
   field: string,
   visibilityMatrixes: VisibilityMatrixes
): boolean => {
   const matrix: VisibilityMatrix = Object.values(visibilityMatrixes).find(
      (matrix) => matrix.typeOfClaimName === typeOfClaim
   );

   return (
      matrix?.classFields.find(
         (visibilityField: VisibilityField) =>
            visibilityField.propertyId === field
      )?.isVisible ?? false
   );
};

export const shouldRenderSection = (
   typeOfClaim: string,
   visibilityMatrixes: VisibilityMatrixes
) => {
   const sectionMatrix: VisibilityMatrix = Object.values(
      visibilityMatrixes
   ).find((matrix) => matrix.typeOfClaimName === typeOfClaim);

   if (!sectionMatrix) return false;

   return sectionMatrix.classFields.some((field) => field.isVisible === true);
};
