import React, { useEffect } from "react";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomRadioGroup from "../../../../../../../Components/CustomInputs/CustomRadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import {
   Checkbox,
   Heading,
   Paragraph,
   Popover,
} from "@nn-design-system/react-component-library";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const NeedAccountElapsedExternalWaitingTimeField: React.FC = () => {
   const fieldNameLowerCase = "needAccountElapsedExternalWaitingTime";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const { isChild, isThirdPerson } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );

   useEffect(() => {
      dispatch(
         updateFirstStepGeneralFields({
            value: false,
            field: fieldNameLowerCase,
         })
      );
   }, []);

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <div ref={ref}>
            <Checkbox
               labelText={
                  isChild || isThirdPerson
                     ? "Jménem pojištěné osoby žádám o posouzení převodu čekací doby z předchozí pojistné smlouvy, nebo obdobného pojištění"
                     : "Žádám o převod čekací doby z předchozí smlouvy životního pojištění u jiné pojišťovny."
               }
               variant="Small"
               onChange={(e) => {
                  dispatch(
                     updateFirstStepGeneralFields({
                        value: !fieldValue,
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               dataState={fieldValue ? "Checked" : "NotChecked"}
               popoverElement={
                  <Popover
                     headingText=""
                     placement="top-start"
                     testId="testId"
                     variant="Icon"
                  >
                     <Heading hasMargin={false} variant="Small">
                        Co je čekací doba?
                     </Heading>
                     <Paragraph>
                        Období obvykle 2-3 měsíců od počátku pojištění, do
                        kterého se nezahrnují události způsobené nemocí.
                     </Paragraph>
                     <Heading mt="1rem" hasMargin={false} variant="Small">
                        Kdy lze zažádat o její převod?
                     </Heading>
                     <Paragraph>
                        Pokud jste měl/a dříve sjednané obdobné pojištění u NN,
                        nebo jiné pojišťovny a zároveň jste žádal/a o převod
                        čekací doby při sjednání pojistné smlouvy.
                     </Paragraph>
                  </Popover>
               }
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </div>
      </WholeLineContainer>
   );
};

export default NeedAccountElapsedExternalWaitingTimeField;
