import {
   Card,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import LoginFormContainer from "./LoginFormContainer";
import { Box } from "@mui/material";
import { ReactComponent as CheckIcon } from "../../../Assets/Check.svg";

const CardAuthentication = () => {
   return (
      <Box
         sx={{
            "& > div": {
               border: "2px solid  #E6DFDA",
            },
         }}
      >
         <Card variant="Attentional">
            <Box
               sx={{
                  padding: ".5rem",
                  "@media (max-width: 500px)": {
                     padding: "0rem",
                  },
               }}
            >
               <Heading mb="1rem" variant="Large" hasMargin={false}>
                  Předvyplníme za vás
               </Heading>
               <Box
                  sx={{
                     display: "flex",
                     gap: "1rem",
                     margin: "0 0 1rem .5rem",
                  }}
               >
                  <Box sx={{ marginTop: ".2rem" }}>
                     <CheckIcon />
                  </Box>
                  <Paragraph hasMargin={false}>
                     Údaje k vašemu pojištění budete mít automaticky
                     předvyplněné.
                  </Paragraph>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     gap: "1rem",
                     margin: "0 0 0 .5rem",
                  }}
               >
                  <Box sx={{ marginTop: ".2rem" }}>
                     <CheckIcon />
                  </Box>
                  <Paragraph hasMargin={false}>
                     Pohodlný způsob autorizace.
                  </Paragraph>
               </Box>
               <LoginFormContainer />
            </Box>
         </Card>
      </Box>
   );
};

export default CardAuthentication;
