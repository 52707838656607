import SectionTemplate from "../../Template/SectionTemplate";
import FirstDoctorOrAmbulanceNameField from "../GeneralSection/GeneralFields/FirstDoctorOrAmbulanceNameField";
import ContinuedTreatmentDoctorOrAmbulanceNameField from "../GeneralSection/GeneralFields/ContinuedTreatmentDoctorOrAmbulanceNameField";
import PersonalDoctorOrAmbulanceNameField from "../GeneralSection/GeneralFields/PersonalDoctorOrAmbulanceNameField";
import NotaryNameAndAddressHandleEstateField from "../GeneralSection/GeneralFields/NotaryNameAndAddressHandleEstateField";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { Divider, Heading } from "@nn-design-system/react-component-library";

const ImportantContactsSection = () => {
   return (
      <>
         <WholeLineContainer>
            <>
               <Divider mt="1rem" variant="Default"></Divider>
               <Heading variant="Medium" hasMargin={false} mb=".5rem" mt="3rem">
                  Důležité kontakty
               </Heading>
            </>
         </WholeLineContainer>
         <FirstDoctorOrAmbulanceNameField />
         <ContinuedTreatmentDoctorOrAmbulanceNameField />
         <PersonalDoctorOrAmbulanceNameField />
         <NotaryNameAndAddressHandleEstateField />
      </>
   );
};

export default ImportantContactsSection;
