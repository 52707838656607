import { useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";
import { InjuryDescriptionModel } from "../../redux/reducers/claimReducer";

const GetValuesForFirstStepGeneralFields = (
   fieldName: keyof InjuryDescriptionModel,
   dependencyFieldName?: keyof InjuryDescriptionModel
) => {
   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldName]
   ) as InjuryDescriptionModel[keyof InjuryDescriptionModel];

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const dependencyValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[
            dependencyFieldName || fieldName
         ]
   );

   return {
      fieldValue,
      visibilityMatrixes,
      claimFieldErrorsMessages,
      dependencyValue,
   };
};

export default GetValuesForFirstStepGeneralFields;
