import { Box } from "@mui/material";
import React from "react";

type WholeLineContainerProps = {
   children: React.ReactElement;
   dependencyValue?: boolean | null;
   inputRef?: React.RefObject<HTMLDivElement>;
};

const WholeLineContainer: React.FC<WholeLineContainerProps> = ({
   children,
   dependencyValue = true,
   inputRef,
}) => {
   return dependencyValue ? (
      <Box sx={{ gridColumn: { xs: "span 1", sm: "span 2" } }}>
         <div ref={inputRef}>{children}</div>
      </Box>
   ) : null;
};

export default WholeLineContainer;
