import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import { updateFifthStepFields } from "../../../../../redux/reducers/claimReducer";
import CustomRadioGroup from "../../../../../Components/CustomInputs/CustomRadioGroup";
import { handleShowErrorMessage } from "../../../../../redux/reducers/claimFieldErrorsReducer";
import {
	Checkbox,
	Icon,
	Paragraph,
} from "@nn-design-system/react-component-library";
import { useState } from "react";
import { Alert } from "@mui/material";

const IsExposedPersonField = () => {
	const [isExposedPerson, setIsExposedPerson] = useState<boolean | null>(null);
	const dispatch = useDispatch();
	const fieldValue = useSelector(
		(state: StoreType) => state.claimState.reportModel.isExposedPerson
	);

	const visibilityMatrixes = useSelector(
		(state: StoreType) => state.visibilityMatrixesState
	);

	const claimFieldErrorsMessages = useSelector(
		(state: StoreType) => state.claimFieldErrorsState
	);

	return (
		<>
			<WholeLineContainer
				dependencyValue={shouldRenderSpecField(
					"GeneralFields",
					"IsExposedPerson",
					visibilityMatrixes
				)}
			>
				<CustomRadioGroup
					heading="Jste politicky exponovaná osoba?"
					firstLabel="Ano"
					secondLabel="Ne"
					value={isExposedPerson}
					updateFunc={(e: any) => {
						dispatch(
							updateFifthStepFields({
								value: e === "true" ? null : false,
								field: "isExposedPerson",
							})
						);

						setIsExposedPerson(e === "true" ? true : false);
					}}
					validationText={""}
					popoverHeading=""
					popoverText="Politicky exponovanou osobou se rozumí osoba, která je ve významné veřejné funkci s celostátní nebo regionální působností (například náměstek, státní tajemník, ředitel krajského úřadu, starosta obce s rozšířenou působností) nebo také osoba, která je ve vztahu s touto osobou osoba blízká."
					popoverText2="Aktuální definice politicky exponované osoby je uvedena na webových stránkách www.nn.cz/pep."
				/>
			</WholeLineContainer>
			<WholeLineContainer dependencyValue={isExposedPerson}>
				<Checkbox
					labelText="Prohlašuji, že jsem politicky exponovanou osobou ve smyslu zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu."
					variant="Small"
					mt="-1.25rem"
					mb="1rem"
					onChange={() => {
						dispatch(
							updateFifthStepFields({
								value: !fieldValue,
								field: "isExposedPerson",
							})
						);
					}}
					dataState={fieldValue ? "Checked" : "NotChecked"}
				/>
			</WholeLineContainer>
			{handleShowErrorMessage(
				"IsExposedPerson",
				claimFieldErrorsMessages
			) && (
				<WholeLineContainer>
					<>
						<Alert
							icon={<Icon color="#EA650D" name="Alert" />}
							severity="warning"
							//  sx={{
							//     color: "#414141 !important",
							//     fontFamily: "NittiGroteskNN-TextMedium !important",
							//     fontSize: "1rem",
							//     fontWeight: "normal !important",
							//  }}
						>
							<Paragraph hasMargin={false}>
								{handleShowErrorMessage(
									"IsExposedPerson",
									claimFieldErrorsMessages
								)}
							</Paragraph>
						</Alert>
					</>
				</WholeLineContainer>
			)}
		</>
	);
};

export default IsExposedPersonField;
