import { useEffect, useState } from "react";
import useFetch from "./useFetch";
import { useSelector } from "react-redux";
import { StoreType } from "../redux/store/store";

const useGetListOfCountries = () => {
   const [listOfCountries, setListOfCOuntries] = useState<string[]>([
      "Česká republika",
      "Slovensko",
      "Ukrajina",
      "Vietnam",
      "Rusko",
      "Polsko",
      "Německo",
   ]);
   const token = useSelector((state: StoreType) => state.tokenState.token);

   const {
      sendRequest: sendRequestGetListOfCountries,
      data: dataGetListOfCountries,
   } = useFetch<string[]>();

   const countriesToFilter = [
      "Česko",
      "Slovensko",
      "Ukrajina",
      "Vietnam",
      "Rusko",
      "Polsko",
      "Německo",
      "Abcházie",
   ];

   useEffect(() => {
      if (dataGetListOfCountries) {
         setListOfCOuntries((prev) => {
            return [
               ...prev,
               ...dataGetListOfCountries.filter(
                  (country) => !countriesToFilter.includes(country)
               ),
            ];
         });
      } else {
         sendRequestGetListOfCountries({
            method: "GET",
            url:
               process.env.REACT_APP_BaseURL! +
               process.env.REACT_APP_GetListOfCountries,
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
            },
         });
      }
   }, [dataGetListOfCountries]);

   return { listOfCountries: listOfCountries };
};

export default useGetListOfCountries;
