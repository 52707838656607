import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/authReducer";
import guidReducer from "../reducers/guidReducer";
import tokenReducer from "../reducers/tokenReducer";
import loadingReducer from "../reducers/loadingReducer";
import pagesToRenderReducer from "../reducers/pagesToRenderReducer";
import ClaimReducer from "../reducers/claimReducer";
import visibilityMatrixesReducer from "../reducers/visibilityMatrixesReducer";
import claimFieldErrorsReducer from "../reducers/claimFieldErrorsReducer";
import filesReducer from "../reducers/filesReducer";

const store = configureStore({
   reducer: {
      authState: authReducer,
      guidState: guidReducer,
      tokenState: tokenReducer,
      loadingState: loadingReducer,
      pagesToRenderState: pagesToRenderReducer,
      claimState: ClaimReducer,
      visibilityMatrixesState: visibilityMatrixesReducer,
      claimFieldErrorsState: claimFieldErrorsReducer,
      filesState: filesReducer,
   },
});

export type StoreType = ReturnType<typeof store.getState>;
export default store;
