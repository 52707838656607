import { useState } from "react";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { useDispatch } from "react-redux";
import { setLoadingOff, setLoadingOn } from "../redux/reducers/loadingReducer";

type ApiHookData<T> = {
   data: T | null;
   loading: boolean;
   error: any;
   sendRequest: (options: FetchOptions) => Promise<void>;
   resetFetch: () => void;
   fetchFired: boolean;
};

type FetchOptions = {
   url: string;
   method: "GET" | "POST" | "PUT" | "DELETE";
   headers: any;
   data?: any;
};

function useFetch<T = any>(): ApiHookData<T> {
   const [data, setData] = useState<T | any>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const [error, setError] = useState<any>(null);
   const [fetchFired, setFetchFired] = useState<boolean>(false);

   const dispatch = useDispatch();

   const sendRequest = async (options: FetchOptions): Promise<void> => {
      setLoading(true);
      dispatch(setLoadingOn());
      try {
         const headers = {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMKey,
            "Content-Security-Policy": "<policy-directive>",
            ...options.headers,
         };
         const config: AxiosRequestConfig<T> = {
            url: options.url,
            method: options.method,
            data: options.data,
            headers,
         };
         const response: AxiosResponse<T> = await axios.request(config);
         setData(response.data);
         setError(null);
      } catch (err: any) {
         setError(err);
      } finally {
         setLoading(false);
         setFetchFired(!fetchFired);
         dispatch(setLoadingOff());
      }
   };
   const resetFetch = (): void => {
      setData(null);
      setLoading(false);
      setError(null);
   };

   return { data, loading, error, sendRequest, resetFetch, fetchFired };
}

export default useFetch;
