import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, HashRouter, MemoryRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";

const isEmbedded = false; // set to true or false depending on your condition

const Router = isEmbedded ? MemoryRouter : BrowserRouter;

const root = ReactDOM.createRoot(
   document.getElementById("root") as HTMLElement
);
root.render(
   <Router>
      <Provider store={store}>
         <App />
      </Provider>
   </Router>
);
