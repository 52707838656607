import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
   Button,
   Heading,
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import { StoreType } from "../../redux/store/store";
import { claimCards, claimsType } from "../../Config/types/types";
import {
   resetClaimModel,
   setSelectedTypeClaims,
} from "../../redux/reducers/claimReducer";
import { useTheme } from "@mui/material/styles";
import { resetClaimFieldErrors } from "../../redux/reducers/claimFieldErrorsReducer";
import { resetVisilityMatrixes } from "../../redux/reducers/visibilityMatrixesReducer";
import ClaimCard from "../../Components/ClaimCard";
import { resetFiles } from "../../redux/reducers/filesReducer";
import icon from "../../Assets/peopleTableIcon.png";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";

const AuthorizedClaimSelectionPage: React.FC = () => {
   const [selectedClaims, setSelectedClaims] = useState<claimsType[]>([]);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   function toggleClaim(claim: claimsType) {
      const index = selectedClaims.indexOf(claim);
      if (index === -1) {
         setSelectedClaims([...selectedClaims, claim]);
      } else {
         setSelectedClaims([
            ...selectedClaims.slice(0, index),
            ...selectedClaims.slice(index + 1),
         ]);
      }
   }

   const { pages } = useSelector(
      (state: StoreType) => state.pagesToRenderState
   );

   const currentlyReportedPerson = useSelector(
      (state: StoreType) => state.authState.currentlyReportedPerson
   );

   const renderClaimCards = () => {
      const filteredCards = claimCards.filter((card) =>
         pages.includes(card.url)
      );

      // const filteredCards = claimCards;

      return filteredCards.map((card) => (
         <ClaimCard
            key={card.url}
            icon={card.icon}
            url={card.url}
            heading={card.heading}
            toggleClaim={toggleClaim}
            selectedClaims={selectedClaims}
         />
      ));
   };

   const handleCreateClaim = () => {
      dispatch(setSelectedTypeClaims(selectedClaims));
      navigate(`/claimReport/authorized`);
   };

   useEffect(() => {
      dispatch(resetClaimModel());
      dispatch(resetClaimFieldErrors());
      dispatch(resetVisilityMatrixes());
      dispatch(resetFiles());
   }, [dispatch]);

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   const displaySelectedClaimsMessage = () => {
      return selectedClaims.length === 0
         ? "Pro pokračování prosím vyberte oblast hlášení"
         : selectedClaims.length > 4
         ? `Máte vybráno ${selectedClaims.length} událostí k hlášení`
         : `Máte ${
              selectedClaims.length === 1
                 ? `vybranou 1 událost`
                 : `vybrané ${selectedClaims.length} události`
           } k hlášení.`;
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            "@media (max-width: 800px)": {
               width: "85%",
            },
            "@media (max-width: 550px)": {
               width: "92.5%",
            },
            position: "relative",
            // alignItems: "center",
         }}
      >
         <Box
            sx={{
               display: "grid",
               gridTemplateColumns: { xs: "8rem 1fr", sm: "14rem 1fr" },
               gridGap: 10,
               margin: "3rem 0 2rem 0",
            }}
         >
            <Box
               sx={{
                  gridColumn: "1 / span 2",
               }}
            >
               <Heading hasMargin={false} mb="1rem" variant={"Large"}>
                  Co o vás víme?
               </Heading>
            </Box>
            <Box sx={{}}>
               <Heading variant={"Small"} hasMargin={false}>
                  Pojistnou událost hlásím za:
               </Heading>
            </Box>
            <Box sx={{}}>
               <Paragraph variant={"Small"} hasMargin={false}>
                  {currentlyReportedPerson.name}
               </Paragraph>
            </Box>
            <Box sx={{}}>
               <Heading variant={"Small"} hasMargin={false}>
                  Rodné číslo:
               </Heading>
            </Box>
            <Box sx={{}}>
               <Paragraph variant={"Small"} hasMargin={false}>
                  {`${currentlyReportedPerson.birthNumber.slice(
                     0,
                     currentlyReportedPerson.birthNumber.length - 4
                  )}/${currentlyReportedPerson.birthNumber.slice(
                     currentlyReportedPerson.birthNumber.length - 4
                  )}`}
               </Paragraph>
            </Box>
         </Box>
         <Box sx={{ zIndex: 50 }}>
            <Heading variant="Large" hasMargin={false} mt="1rem">
               Vyberte, co chcete hlásit
            </Heading>
            <Paragraph hasMargin={false} mt="1rem" mb="1rem">
               Pokud se chystáte hlásit více událostí, které spolu souvisí,
               stačí je vybrat.
               <Box
                  component={"span"}
                  sx={{
                     fontWeight: `${fontWeightTokens.strongImportance.get}`,
                     fontFamily: `${fontFamilyTokens.strongImportance.value}`,
                     marginLeft: "4px",
                  }}
               >
                  Spojíme je do jednoho hlášení.
               </Box>
            </Paragraph>
         </Box>
         <Box
            sx={{
               display: "grid",
               gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr)) ",
               justifyContent: "center",
               alignContent: "center",
               gap: "2rem",
               gridAutoColumns: "1fr",
            }}
         >
            {renderClaimCards()}
         </Box>
         <Box
            sx={{
               display: "flex",
               justifyContent: isSmallScreen ? "center" : "space-between",
               flexDirection: isSmallScreen ? "column" : "row",
               alignItems: "center",
               marginTop: "3rem",
               flexWrap: "wrap",
               marginBottom: "2rem",
               "@media (max-width: 700px)": {
                  flexDirection: "column",
                  marginBottom: "0rem",
               },
            }}
         >
            <Button
               variant="Secondary"
               iconProps={{
                  name: "ArrowLeft",
                  placement: "Left",
               }}
               onClick={() => {
                  navigate("/personSelection");
               }}
            >
               Zpět na výběr osob
            </Button>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "@media (max-width: 700px)": {
                     flexDirection: "column",
                     marginTop: "1rem",
                     "& > button": { margin: ".5rem 0 1rem 0" },
                  },
               }}
            >
               <Paragraph hasMargin={false} mr="1rem" variant="MediumCentered">
                  {displaySelectedClaimsMessage()}
               </Paragraph>
               {selectedClaims.length ? (
                  <Button
                     onClick={() => {
                        handleCreateClaim();
                     }}
                  >
                     Přejít na hlášení
                  </Button>
               ) : null}
            </Box>
         </Box>
         <Box
            sx={{
               position: "absolute",
               top: "-1.5rem",
               left: "clamp(16rem, 45vw, 35rem)",
               transform: "scale(.8)",
               display: { xs: "none", sm: "block" },
            }}
         >
            <img src={icon} alt="icon of documents"></img>
         </Box>
      </Box>
   );
};

export default AuthorizedClaimSelectionPage;
