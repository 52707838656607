import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { updateFifthStepFields } from "../../../../../redux/reducers/claimReducer";
import { Box } from "@mui/material";
import {
   Checkbox,
   Heading,
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import AgentFirstNameField from "./AgentFirstNameField";
import AgentLastNameField from "./AgentLastNameField";
import AgentPhoneNumberField from "./AgentPhoneNumberField";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import { resetAgentErrors } from "../../../../../redux/reducers/authReducer";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";

const AgentSection = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "gdprAgreement";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.reportModel[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const dependencyValue = useSelector(
      (state: StoreType) => state.claimState.reportModel.gdprAgreement
   );

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <>
            <WholeLineContainer
               dependencyValue={shouldRenderSpecField(
                  "GeneralFields",
                  fieldNameUpperCase,
                  visibilityMatrixes
               )}
            >
               <Box>
                  <Paragraph hasMargin={false} mb=".75rem">
                     <Box
                        component={"span"}
                        sx={{
                           fontWeight: `${fontWeightTokens.strongImportance.get}`,
                           fontFamily: `${fontFamilyTokens.strongImportance.value}`,
                        }}
                     >
                        Chci sdílet informace se svým poradcem{" "}
                     </Box>
                     (nepovinné)
                  </Paragraph>
                  <Checkbox
                     labelText="Souhlasím s tím, aby můj poradce měl přístup k informacím o tomto pojistném hlášení."
                     variant="Small"
                     onChange={() => {
                        dispatch(resetAgentErrors());
                        dispatch(
                           updateFifthStepFields({
                              value: !fieldValue,
                              field: fieldNameLowerCase,
                           })
                        );
                     }}
                     dataState={fieldValue ? "Checked" : "NotChecked"}
                  />
               </Box>
            </WholeLineContainer>
            <GridContainer>
               <AgentFirstNameField />
               <AgentLastNameField />
               <AgentPhoneNumberField />
               <WholeLineContainer dependencyValue={dependencyValue}>
                  <>
                     <Heading hasMargin={false} variant="Small" mb=".5rem">
                        GDPR souhlas
                     </Heading>
                     <Paragraph>
                        Pojištěný tímto dává pojišťovně souhlas s tím, aby
                        poskytla pojišťovacímu zprostředkovateli
                        identifikovanému v oddílu Zástupce NN údaje o jeho
                        zdravotním stavu, které pojišťovna bude mít k dispozici
                        v souvislosti s hlášenou pojistnou událostí a které
                        budou podkladem rozhodnutí o způsobu likvidace. Souhlas
                        se vztahuje na zdravotní údaje sdělené pojišťovně
                        pojištěným i jinými osobami (např. lékařem). Účelem
                        předání údajů o zdravotním stavu pojišťovacímu
                        zprostředkovateli je zajištění komplexních služeb a
                        řádné a odborné péče, která souvisí s upisováním, resp.
                        se změnou pojistného rizika, a likvidací pojistných
                        událostí. Kompletní informace o zpracování osobních
                        údajů klientů jsou uvedeny na webových stránkách
                        pojišťovny na adrese: www.nn.cz/ochrana-osobnich-udaju/.
                     </Paragraph>
                     <Paragraph hasMargin={false} mt="1rem">
                        Pojištěný je oprávněn tento souhlas kdykoliv písemně
                        odvolat prostřednictvím níže uvedených kontaktních údajů
                        pojišťovny. V takovém případě nebudou údaje o zdravotním
                        stavu pojištěného dále pojišťovacímu zprostředkovateli
                        předávány. Tento souhlas se vždy vztahuje pouze k dané
                        škodní události. V případě další škodní události se
                        pojištěný bude moci rozhodnout, zda tento souhlas
                        pojišťovně znovu udělí. V případě pojištěné osoby, která
                        není plně svéprávná, ověří zástupce NN oprávnění
                        zastupující osoby, která za ni jedná.
                     </Paragraph>
                  </>
               </WholeLineContainer>
            </GridContainer>
         </>
      </WholeLineContainer>
   );
};

export default AgentSection;
