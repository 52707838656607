import { Alert } from "@mui/material";
import { Icon, Paragraph } from "@nn-design-system/react-component-library";
import React from "react";

type ErrorMessageProps = {
   errorMessage: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
   return errorMessage ? (
      <Alert icon={<Icon color="#EA650D" name="Alert" />} severity="warning">
         <Paragraph hasMargin={false}>{errorMessage}</Paragraph>
      </Alert>
   ) : null;
};

export default ErrorMessage;
