import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export type guidInitialState = {
   guid: string | undefined;
};

const initialState: guidInitialState = {
   guid: undefined,
};

const authSlice = createSlice({
   name: "guid",
   initialState,
   reducers: {
      generateGUID: (state) => {
         state.guid = uuidv4();
      },
   },
});

export const { generateGUID } = authSlice.actions;
export default authSlice.reducer;
