import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { updateFifthStepAgentFields } from "../../../../../redux/reducers/claimReducer";
import CustomInput from "../../../../../Components/CustomInputs/CustomInput";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import { useScrollToTopErrorInput } from "../../../../../CustomHooks/useScrollToTopErrorInput";
import React from "react";

const AgentFirstNameField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "agentFirstname";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.agentModel[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const { agentFirstname } = useSelector(
      (state: StoreType) => state.authState.agentErorrs
   );

   const dependencyValue = useSelector(
      (state: StoreType) => state.claimState.reportModel.gdprAgreement
   );

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <CustomInput
         labelText="Jméno"
         placeholderText="Uveďte křestní jméno poradce"
         value={(fieldValue as string) || ""}
         onChange={(e) => {
            dispatch(
               updateFifthStepAgentFields({
                  value: e.target.value,
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={agentFirstname}
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
         inputRef={ref}
      />
   );
};

export default AgentFirstNameField;
