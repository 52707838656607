import { Box } from "@mui/material";
import React from "react";
import {
   Button,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import icon from "../../Assets/NotFoundImg.png";

const NotFoundPage: React.FC = () => {
   const navigate = useNavigate();
   return (
      <Box
         sx={{
            marginTop: "5rem",
            display: "flex",
            padding: "1rem",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            flexWrap: "wrap",
         }}
      >
         <Box>
            <Heading variant="Large" hasMargin={false}>
               Stránka nenalezena
            </Heading>
            <Paragraph hasMargin={false} mt="1rem">
               Omlouváme se, ale stránku, na kterou směřoval váš požadavek, se
               nepodařilo najít.
            </Paragraph>
            <Paragraph hasMargin={false}>
               Stránka byla odstraněna, je dočasně nedostupná nebo neexistuje.
            </Paragraph>
            <Box
               sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "1rem",
                  marginTop: "1rem",
                  alignItems: { xs: "center", sm: "" },
               }}
            >
               <Button
                  onClick={() => {
                     navigate("/");
                  }}
               >
                  Chci nahlásit událost online
               </Button>
               <Button
                  variant="Secondary"
                  onClick={() => {
                     window.open("https://www.nn.cz", "_blank");
                  }}
               >
                  Otevřít web NN
               </Button>
            </Box>
         </Box>
         <Box sx={{}}>
            <img src={icon} alt="papers"></img>
         </Box>
      </Box>
   );
};

export default NotFoundPage;
