import React, { useState, useEffect } from "react";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Divider,
   Heading,
   Paragraph,
   Select,
} from "@nn-design-system/react-component-library";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import BusinessDescriptionField from "./WorkFields/BusinessDescriptionField";
import BusinessFromDateField from "./WorkFields/BusinessFromDateField";
import EmployeeToField from "./WorkFields/EmployeeToField";
import EmployeeFromField from "./WorkFields/EmployeeFromField";
import OtherJobField from "./WorkFields/OtherJobField";
import UnemployedFromField from "./WorkFields/UnemploeydFromField";
import ProfessionField from "./WorkFields/ProfessionField";
import EmployerNameAndAddressField from "./WorkFields/EmployerNameAndAdressField";
import { StoreType } from "../../../../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import { updateSecondStepGeneralsNestedFields } from "../../../../../redux/reducers/claimReducer";
import IsEmployed from "./WorkFields/IsEmployed";
import {
   handleShowErrorMessage,
   resetClaimFieldErrors,
} from "../../../../../redux/reducers/claimFieldErrorsReducer";
import IsWorkedDuringIncapacityFromField from "./WorkFields/IsWorkedDuringIncapacityFromField";
import IsWorkedDuringIncapacityToField from "./WorkFields/IsWorkedDuringIncapacityToField";
import HaveProblemHealthForHardWorkingInCapacityField from "./WorkFields/HaveProblemHealthForHardWorkingInCapacityField";

type WorkSectionProps = {};

type UserWorkStateType =
   | ""
   | "isEmployed"
   | "isEnterPrenuer"
   | "unemployed"
   | "IsStudent"
   | "IsHousewife"
   | "IsPensioner"
   | "isOtherJob";

const WorkSection: React.FC<WorkSectionProps> = ({}) => {
   const [userWorkState, setUserWorkState] = useState<UserWorkStateType>("");
   const dispatch = useDispatch();

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const { isThirdPerson } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );

   const resetFields = () => {
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: null,
            field: "unemployedFrom",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: "",
            field: "profession",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: "",
            field: "otherJob",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: "",
            field: "employerNameAndAddress",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: null,
            sectionName: "employeeModel",
            field: "employeeTo",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: null,
            sectionName: "employeeModel",
            field: "employeeFrom",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: null,
            sectionName: "enterprenuerModel",
            field: "businessFromDate",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: "",
            sectionName: "enterprenuerModel",
            field: "businessDescription",
         })
      );
      dispatch(
         updateSecondStepGeneralsNestedFields({
            value: null,
            field: "haveProblemHealthForHardWorkingInCapacity",
         })
      );
   };

   const {
      isStudent,
      isEmployed,
      isOtherJob,
      isEnterPrenuer,
      isHousewife,
      isPensioner,
   } = useSelector((state: StoreType) => state.claimState.personModel);

   useEffect(() => {
      if (isStudent) {
         setUserWorkState("IsStudent");
      } else if (isOtherJob) {
         setUserWorkState("isOtherJob");
      } else if (isHousewife) {
         setUserWorkState("IsHousewife");
      } else if (isEnterPrenuer) {
         setUserWorkState("isEnterPrenuer");
      } else if (isPensioner) {
         setUserWorkState("IsPensioner");
      } else if (isEmployed) {
         setUserWorkState("isEmployed");
      } else if (isEmployed === false) {
         setUserWorkState("unemployed");
      }
   }, []);

   useEffect(() => {
      // resetFields();
      const updateWorkFields = (field: string) => {
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: field === "IsStudent" ? true : false,
               field: "isStudent",
            })
         );
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: field === "IsHousewife" ? true : false,
               field: "isHousewife",
            })
         );
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: field === "IsPensioner" ? true : false,
               field: "isPensioner",
            })
         );
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: field === "isEmployed" ? true : false,
               field: "isEmployed",
            })
         );
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: field === "isEnterPrenuer" ? true : false,
               field: "isEnterPrenuer",
            })
         );
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: field === "isOtherJob" ? true : false,
               field: "isOtherJob",
            })
         );
      };

      if (userWorkState.length > 0) {
         updateWorkFields(userWorkState);
      }
   }, [userWorkState, dispatch]);

   if (
      !shouldRenderSpecField("GeneralFields", "IsEmployed", visibilityMatrixes)
   ) {
      return null;
   }

   if (
      !shouldRenderSpecField("GeneralFields", "OtherJob", visibilityMatrixes)
   ) {
      return (
         <GridContainer>
            <WholeLineContainer>
               <>
                  <Heading
                     variant={"Medium"}
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     {isThirdPerson
                        ? "Zaměstnání pojištěné osoby"
                        : "Vaše zaměstnání"}
                  </Heading>
                  <Divider variant="Brand"></Divider>
               </>
            </WholeLineContainer>
            <IsEmployed />
            <ProfessionField />
            <EmployerNameAndAddressField />
            <EmployeeFromField />
         </GridContainer>
      );
   }

   const renderWorkQuestions = () => {
      if (userWorkState === "unemployed") {
         return <UnemployedFromField />;
      } else if (userWorkState === "isEmployed") {
         return [
            <ProfessionField />,
            <EmployerNameAndAddressField />,
            <EmployeeFromField />,
            <EmployeeToField />,
         ].map((question) => question);
      } else if (userWorkState === "isEnterPrenuer") {
         return [
            <BusinessDescriptionField />,
            <BusinessFromDateField />,
            <HaveProblemHealthForHardWorkingInCapacityField />,
            <IsWorkedDuringIncapacityFromField />,
            <IsWorkedDuringIncapacityToField />,
         ].map((question) => question);
      } else if (userWorkState === "isOtherJob") {
         return <OtherJobField />;
      }

      return null;
   };

   return (
      <GridContainer>
         <WholeLineContainer>
            <>
               <Heading
                  variant={"Medium"}
                  hasMargin={false}
                  mb=".5rem"
                  mt="1rem"
               >
                  {isThirdPerson
                     ? "Zaměstnání pojistné osoby"
                     : "Vaše zaměstnání"}
               </Heading>
               <Divider variant="Brand"></Divider>
            </>
         </WholeLineContainer>
         <Select
            labelText={
               isThirdPerson
                  ? "Byl/a pojištěná osoba k datu pojistné události"
                  : "K datu pojistné události jste byl/a"
            }
            name="select"
            placeholderText="Vyberte z možností"
            onChange={(e) => {
               setUserWorkState(e.target.value as UserWorkStateType);
               dispatch(resetClaimFieldErrors());
            }}
            options={[
               { text: "Zaměstnaný/á", value: "isEmployed" },
               { text: "OSVČ", value: "isEnterPrenuer" },
               { text: "Nezaměstnaný/á", value: "unemployed" },
               { text: "Mateřská / rodičovská dovolená", value: "IsHousewife" },
               { text: "Důchodce", value: "IsPensioner" },
               { text: "Student", value: "IsStudent" },
               { text: "Jiná činnost", value: "isOtherJob" },
            ]}
            testId="select"
            value={userWorkState}
            validationText={handleShowErrorMessage(
               "IsEmployed",
               claimFieldErrorsMessages
            )}
         />

         {renderWorkQuestions()}
      </GridContainer>
   );
};

export default WorkSection;
