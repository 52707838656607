import {
   Card,
   Checkbox,
   Heading,
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import WholeLineContainer from "../../../Components/ReportFormTemplate/WholeLineContainer";
import GridContainer from "../../../Components/ReportFormTemplate/GridContainer";
import UploadFilesSection from "./Sections/UploadDocumentsSection/UploadFilesSection";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../redux/store/store";
import { shouldRenderSpecField } from "../../../redux/reducers/visibilityMatrixesReducer";
import { updateFourthStepFields } from "../../../redux/reducers/claimReducer";
import ErrorMessage from "../../../Components/ErrorMessage/ErrorMessage";
import StatementSection from "./Sections/StatementSection/StatementSection";
import { Box } from "@mui/material";
import icon from "../../../Assets/iconForFourthStep.png";
import DoctorSection from "./Sections/DoctorSection/DoctorSection";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";

type FourthStepContainerProps = {
   errorMessage: string;
};

const FourthStepContainer: React.FC<FourthStepContainerProps> = ({
   errorMessage,
}) => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimModel = useSelector((state: StoreType) => state.claimState);

   const declarationValue = useSelector(
      (state: StoreType) =>
         state.claimState.documentsUploadModel.declarationOfOutOfWorkDuringPN
   );

   const dispatch = useDispatch();

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   return (
      <>
         <Card variant="Attentional">
            <GridContainer>
               <WholeLineContainer>
                  <Box
                     sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        gridTemplateAreas: {
                           xs: `
                      'heading icon'
                      'text text'
                      `,
                           sm: `
                      'heading icon'
                      'text icon'
                      `,
                        },
                     }}
                  >
                     <Box sx={{ gridArea: "heading" }}>
                        <Heading variant={"Large"} mb="1rem" hasMargin={false}>
                           Nahrajte nezbytné soubory
                        </Heading>
                     </Box>
                     <Box sx={{ gridArea: "text", marginBottom: "1rem" }}>
                        <Paragraph mt="1rem" hasMargin={false}>
                           Každý dokument se počítá. Čím více informací k
                           pojistné události budeme mít, tím lépe. Pošlete nám
                           proto vše, co máte k dispozici.
                           <Box
                              component={"span"}
                              sx={{
                                 fontWeight: `${fontWeightTokens.strongImportance.get}`,
                                 fontFamily: `${fontFamilyTokens.strongImportance.value}`,
                                 marginLeft: "4px",
                              }}
                           >
                              Dokumenty můžete nahrát i později
                           </Box>
                           , určitě s nimi ale neotálejte, jak na to, vám
                           shrneme v e-mailu.
                        </Paragraph>
                     </Box>
                     <Box
                        sx={{
                           gridArea: "icon",
                           height: { xs: "2rem", sm: "auto" },
                           transform: {
                              xs: "translateY(-1rem)",
                              sm: "translateY(0)",
                           },
                           "& > *": {
                              width: { xs: "4rem", sm: "auto" },
                           },
                        }}
                     >
                        <img src={icon} alt="icon of documents"></img>
                     </Box>
                  </Box>
               </WholeLineContainer>

               <WholeLineContainer
                  dependencyValue={shouldRenderSpecField(
                     "incapacity",
                     "declerationOutOfWorkDuringPN",
                     visibilityMatrixes
                  )}
               >
                  <Heading variant="Medium">
                     Dále od vás potřebujeme čestné prohlášení
                  </Heading>
               </WholeLineContainer>
               <WholeLineContainer
                  dependencyValue={shouldRenderSpecField(
                     "incapacity",
                     "declerationOutOfWorkDuringPN",
                     visibilityMatrixes
                  )}
               >
                  <Checkbox
                     dataState={declarationValue ? "Checked" : "NotChecked"}
                     labelText="Čestně prohlašuji, že po celou dobu pracovní neschopnosti nevykonávám a ani nebudu vykonávat pracovní činnost."
                     name="name"
                     onChange={() => {
                        dispatch(
                           updateFourthStepFields({
                              field: "declarationOfOutOfWorkDuringPN",
                              value: !declarationValue,
                           })
                        );
                     }}
                     variant="Small"
                  />
               </WholeLineContainer>
            </GridContainer>

            <UploadFilesSection />

            {errorMessage && (
               <GridContainer>
                  <WholeLineContainer>
                     <ErrorMessage errorMessage={errorMessage} />
                  </WholeLineContainer>
               </GridContainer>
            )}

            {/* <LastQuestionsSection /> */}
            <StatementSection />
         </Card>

         {claimModel?.reportModel?.documentsToDownloads.some(
            (document) => document.id === "8" || document.id === "9" || document.id === "11" || document.id === "12"
         ) && (
            <Card variant="Attentional" mt="2rem">
               <DoctorSection />
            </Card>
         )}
      </>
   );
};

export default FourthStepContainer;
