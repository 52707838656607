import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import CustomTextarea from "../../../../../Components/CustomInputs/CustomTextarea";
import { updateFifthStepFields } from "../../../../../redux/reducers/claimReducer";
import {
	Paragraph,
	useDesignTokens,
} from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";
import {
	getFontFamily,
	getFontWeight,
} from "@nn-design-system/design-tokens/web";

const NoticeSection = () => {
	const dispatch = useDispatch();
	const fieldNameLowerCase = "notice";
	const fieldValue = useSelector(
		(state: StoreType) => state.claimState.reportModel[fieldNameLowerCase]
	);

	const fontFamilyTokens = useDesignTokens(getFontFamily);
	const fontWeightTokens = useDesignTokens(getFontWeight);
	return (
		<WholeLineContainer>
			<Box>
				<Paragraph hasMargin={false}>
					<Box
						component={"span"}
						sx={{
							fontWeight: `${fontWeightTokens.strongImportance.get}`,
							fontFamily: `${fontFamilyTokens.strongImportance.value}`,
						}}
					>
						Je zde něco, co byste nám ještě rádi sdělili?{" "}
					</Box>
					(nepovinné)
				</Paragraph>
				<CustomTextarea
					labelText=""
					placeholderText="Uveďte nám jakékoliv vaše připomínky"
					value={(fieldValue as string) || ""}
					onChange={(e) => {
						dispatch(
							updateFifthStepFields({
								value: e.target.value.slice(0, 500),
								field: fieldNameLowerCase,
							})
						);
					}}
					validationText={""}
					maxCharacters="500"
					highlightMaxCharacters={fieldValue?.length === 500}
				/>
			</Box>
		</WholeLineContainer>
	);
};

export default NoticeSection;
