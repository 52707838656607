import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Checkbox,
   Divider,
   Heading,
} from "@nn-design-system/react-component-library";
import { updateFourthStepFields } from "../../../../../redux/reducers/claimReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { handleShowErrorMessage } from "../../../../../redux/reducers/claimFieldErrorsReducer";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";

const StatementSection = () => {
   const dispatch = useDispatch();
   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.documentsUploadModel.declarationOfOutOfWorkDuringPN
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   if (
      !shouldRenderSpecField(
         "GeneralFields",
         "DeclarationOfOutOfWorkDuringPN",
         visibilityMatrixes
      )
   ) {
      return null;
   }

   return (
      <GridContainer>
         <WholeLineContainer
            dependencyValue={shouldRenderSpecField(
               "GeneralFields",
               "DeclarationOfOutOfWorkDuringPN",
               visibilityMatrixes
            )}
         >
            <>
               <Heading variant="Small" hasMargin={false} mb=".5rem" mt="1rem">
                  Dále od vás potřebujeme čestné prohlášení
               </Heading>
               <Divider variant="Brand"></Divider>
               <Checkbox
                  mt="1rem"
                  labelText="Čestně prohlašuji, že po celou dobu pracovní neschopnosti nevykonávám a ani nebudu vykonávat pracovní činnost."
                  variant="Small"
                  onChange={(e) => {
                     dispatch(
                        updateFourthStepFields({
                           value: !fieldValue,
                           field: "declarationOfOutOfWorkDuringPN",
                        })
                     );
                  }}
                  dataState={fieldValue ? "Checked" : "NotChecked"}
                  validationText={handleShowErrorMessage(
                     "declarationOfOutOfWorkDuringPN",
                     claimFieldErrorsMessages
                  )}
               />
            </>
         </WholeLineContainer>
      </GridContainer>
   );
};

export default StatementSection;
