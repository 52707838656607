import { Box } from "@mui/material";
import {
   Button,
   Heading,
   Modal,
} from "@nn-design-system/react-component-library";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, setIsSelectedPerson } from "../../redux/reducers/authReducer";

type LogoutConfirmationModalProps = {
   isModalOpen: boolean;
   setModalOff: any;
};

const LogoutConfirmationModal: React.FC<LogoutConfirmationModalProps> = ({
   isModalOpen,
   setModalOff,
}) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const handleRedirect = () => {
      dispatch(logout());
      dispatch(setIsSelectedPerson(false));
      navigate("/");
      setModalOff(false);
   };

   return (
      <Modal
         variant="Elevated"
         isOpen={isModalOpen}
         onClose={() => {
            setModalOff(false);
         }}
         height="auto"
         width="clamp(5rem, 100vw, 30rem)"
         hasBackdrop
      >
         <Box
            sx={{
               justifyItems: "center",
               padding: "1rem",
            }}
         >
            <Heading mt="1rem" mb="2rem" variant={"Medium"}>
               Opravdu se chcete odhlásit?
            </Heading>
            <Box
               sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  "@media (max-width: 500px)": {
                     flexDirection: "column",
                  },
               }}
            >
               <Button
                  variant="PrimaryMediumFullWidth"
                  onClick={() => {
                     setModalOff(false);
                  }}
               >
                  Chci pokračovat
               </Button>
               <Button
                  variant="SecondaryMediumFullWidth"
                  onClick={handleRedirect}
               >
                  Odhlásit se
               </Button>
            </Box>
         </Box>
      </Modal>
   );
};

export default LogoutConfirmationModal;
