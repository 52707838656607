import SectionTemplate from "../../Template/SectionTemplate";
import HospitalizationAdditionalInjuryDescriptionField from "./HospitalizationFields/HospitalizationAdditionalInjuryDescriptionField";
import HospitalizationDateOfClaimFromField from "./HospitalizationFields/HospitalizationDateOfClaimFromField";
import HospitalizationTimeOfClaimField from "./HospitalizationFields/HospitalizationTimeOfClaimField";
import HospitalizationDateOfClaimToField from "./HospitalizationFields/HospitalizationDateOfClaimToField";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { useSelector } from "react-redux";

const HospitalizationSection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("hospitalization", visibilityMatrixes)) return null;
   return (
      <SectionTemplate heading="Hospitalizace" iconName="FirstAid">
         <HospitalizationDateOfClaimFromField />
         <HospitalizationDateOfClaimToField />
         <HospitalizationTimeOfClaimField />
         <HospitalizationAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default HospitalizationSection;
