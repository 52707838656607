import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import CustomDateInput from "../../../../../../../Components/CustomInputs/CustomDateInput";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";

const SeriousDiseasesDateOfClaimToField: React.FC = () => {
   const dispatch = useDispatch();

   const DateOfClaimToValueSeriousDiseases = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 5
         )?.dateOfClaimTo
   );
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   return (
      <CustomDateInput
         labelText="Datum škody do"
         name="DateOfClaimTo"
         value={DateOfClaimToValueSeriousDiseases || undefined}
         onChange={(e: any) => {
            dispatch(
               updateFirstStepDynamicFields({
                  value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
                  field: "dateOfClaimTo",
                  typeOfClaimEnum: 5,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            "seriousdiseases.DateOfClaimTo",
            claimFieldErrorsMessages
         )}
         placeholderText=""
         dependencyValue={shouldRenderSpecField(
            "seriousdiseases",
            "DateOfClaimTo",
            visibilityMatrixes
         )}
      />
   );
};

export default SeriousDiseasesDateOfClaimToField;
