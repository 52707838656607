import React from "react";
import CustomDateInput from "../../../../../../Components/CustomInputs/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const IsWorkedDuringIncapacityFromField: React.FC = () => {
   const fieldNameLowerCase = "isWorkedDuringIncapacityFrom";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.personModel[fieldNameLowerCase]
   );

   const dependencyValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.haveProblemHealthForHardWorkingInCapacity
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   return (
      <CustomDateInput
         labelText="Pracoval jsem v době trvání pracovní neschopnosti datum od?"
         placeholderText=""
         value={fieldValue || undefined}
         onChange={(e: any) => {
            dispatch(
               updateSecondStepGeneralsNestedFields({
                  value: e.toISOString(),
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            fieldNameUpperCase,
            claimFieldErrorsMessages
         )}
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue === false
         }
         inputRef={ref}
      />
   );
};

export default IsWorkedDuringIncapacityFromField;
