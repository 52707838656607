import {
   Button,
   Heading,
   Modal,
   Paragraph,
} from "@nn-design-system/react-component-library";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreType } from "../../redux/store/store";
import { Box } from "@mui/material";
import { resetClaimModel } from "../../redux/reducers/claimReducer";
import SuccesCheckmark from "../../Assets/SuccesCheckmark.png";

type FinishModalProps = {
   isModalOpen: boolean;
};

const FinishModal: React.FC<FinishModalProps> = ({ isModalOpen }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const userAuthenticationStatus = useSelector(
      (state: StoreType) => state.authState.userAuthenticationStatus
   );
   return (
      <Modal
         variant="Elevated"
         isOpen={isModalOpen}
         onClose={() => {}}
         height="auto"
         width="44rem"
         hasBackdrop
      >
         <Box sx={{ padding: "1rem", position: "relative" }}>
            <Box
               sx={{
                  display: "flex",
                  gap: "1.5rem",
                  flexDirection: "column",
                  "@media (min-width: 525px)": {
                     flexDirection: "row",
                  },
               }}
            >
               <Box>
                  <Box
                     sx={{
                        "@media (max-width: 525px)": {
                           paddingRight: "3rem",
                        },
                     }}
                  >
                     <Heading
                        hasMargin={false}
                        mt="1rem"
                        mb=".5rem"
                        variant="Medium"
                     >
                        Vaše pojistná událost byla úspěšně nahlášena
                     </Heading>
                  </Box>
                  <Paragraph hasMargin={false}>
                     Ve svém e-mailu již nyní najdete shrnutí hlášení včetně
                     čísla pojistné události nebo informace o tom, jak můžete
                     nahrát další dokumenty.
                  </Paragraph>
                  <Box
                     sx={{
                        display: "flex",
                        "& > *": {
                           padding: "0 2.5rem !important",
                           marginTop: "1rem",
                        },
                     }}
                  >
                     <Button
                        onClick={() => {
                           dispatch(resetClaimModel());
                           navigate(
                              userAuthenticationStatus === "authorized"
                                 ? "/personSelection"
                                 : "/claimSelection/anonym"
                           );
                        }}
                     >
                        Rozumím
                     </Button>
                  </Box>
               </Box>
               <Box
                  sx={{
                     transform: "translateY(1rem)",
                     "@media (max-width: 525px)": {
                        position: "absolute",
                        top: ".5rem",
                        right: ".5rem",
                        "& > img": {
                           width: "3rem",
                           height: "3rem",
                        },
                     },
                  }}
               >
                  <img src={SuccesCheckmark} alt="succesCheckMark" />
               </Box>
            </Box>
         </Box>
      </Modal>
   );
};

export default FinishModal;
