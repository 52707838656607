//  ------- Text -------
export const colourTextDefault = "#404040";
export const colourTextSubtle = "#6D6D6D";
export const colourTextInverted = "#FFFFFF";

//  ------- Background -------
export const colourBackgroundDefault = "#FFFFFF";
export const colourBackgroundTint = "#F1EDEB";

//  ------- Border -------
export const colourBorderDefault = "#8D8D8D";
export const colourBorderSubtle = "#E5DED9";
export const colourBorderHover = "#666666";
export const colourBorderActive = "#414141";

//  ------- Divider -------
export const colourDividerDefault = "#E6DFDA";
export const colourDividerBrand = "#EA650D";

//  ------- Interaction -------
export const colourInteractionDefault = "#EA650D";
export const colourInteractionPressed = "#E64415";
export const colourInteractionPressedBackground = "#FFF7EE";

//  ------- Icons -------
export const colourIconDefault = "#404040";
export const colourIconBrand = "#EA650D";
export const coulourIconInverted = "#FFFFFF";

//  ------- Feedback -------
export const colourInformativeDefault = "#336FCB";
export const colourInformativeLight = "#CCDBF2";
export const colourWarningDefault = "#EE7F00";
export const colourWarningLight = "#FFF7EE";
export const colourNegativeDefault = "#E64415";
export const colourNegativeLight = "#FFF7EE";
export const colourPositiveDefault = "#5AA896";
export const colourPositiveLight = "#D2E9E4";
export const colourExceededNegative = "#E64415";
export const colourExceededPositive = "#347663";

export const errorLight = "#E64415";
export const errorDark = "#9E2D0E";

//  ------- Brand -------
export const colourBrandWhite = "#FFFFFF";
export const colourBrandDark = "#E64415";
export const colourBrandMedium = "#EA650D";
export const colourBrandLight = "#EE7F00";
