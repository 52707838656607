import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { updateThirdStepGeneralFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import SanitizeNumber from "../../../../../../Utils/SanitizeInputs/SanitizeNumber";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";
import { Box } from "@mui/material";
import {
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";

const VariableNumberField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "variableNumber";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   return (
      <Box>
         <Paragraph hasMargin={false}>
            <Box
               component={"span"}
               sx={{
                  fontWeight: `${fontWeightTokens.strongImportance.get}`,
                  fontFamily: `${fontFamilyTokens.strongImportance.value}`,
               }}
            >
               Variabilní symbol{" "}
            </Box>
            (nepovinné)
         </Paragraph>
         <CustomInput
            labelText=""
            subtext=""
            placeholderText="Zadejte variabilní symbol"
            value={(fieldValue as string) || ""}
            onChange={(e) => {
               dispatch(
                  updateThirdStepGeneralFields({
                     value: SanitizeNumber(e.target.value),
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
         />
      </Box>
   );
};

export default VariableNumberField;
