import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import CustomDateInput from "../../../../../../../Components/CustomInputs/CustomDateInput";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { Paragraph } from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";

const HospitalizationDateOfClaimToField: React.FC = () => {
   const dispatch = useDispatch();

   const DateOfClaimToValueHospitalization = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 1
         )?.dateOfClaimTo
   );
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   return (
      <Box>
         <CustomDateInput
            labelText="Ukončení hospitalizace"
            //  subtext="(Při zatím nevyplněné hospitalizaci nevyplňujte)"
            name="DateOfClaimTo"
            value={DateOfClaimToValueHospitalization || undefined}
            onChange={(e: any) => {
               dispatch(
                  updateFirstStepDynamicFields({
                     value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
                     field: "dateOfClaimTo",
                     typeOfClaimEnum: 1,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               "hospitalization.DateOfClaimTo",
               claimFieldErrorsMessages
            )}
            dependencyValue={shouldRenderSpecField(
               "hospitalization",
               "DateOfClaimTo",
               visibilityMatrixes
            )}
         />
         <Paragraph variant="Small" mt=".75rem" hasMargin={false}>
            Pokud ještě nedošlo k ukončení hospitalizace, vyplňte pouze její
            počátek.
         </Paragraph>
      </Box>
   );
};

export default HospitalizationDateOfClaimToField;
