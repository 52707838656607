import {
   AttentionBlock,
   Button,
   Heading,
   Icon,
   IconButton,
   Modal,
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import React, { useEffect, useState } from "react";
import CustomInput from "../CustomInputs/CustomInput";
import { Box } from "@mui/material";
import useFetch from "../../CustomHooks/useFetch";
import MobileSign from "../../Assets/MobileSign.png";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";
import { useLocation } from "react-router-dom";
import { updateFifthStepFields } from "../../redux/reducers/claimReducer";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";

type SMSModalProps = {
   isModalOpen: boolean;
   setIsModalOpen: any;
   dataCreateClaim: any;
   openFinishModal: any;
   callCreateClaim: any;
};

const SMSModal: React.FC<SMSModalProps> = ({
   isModalOpen,
   setIsModalOpen,
   dataCreateClaim,
   openFinishModal,
   callCreateClaim,
}) => {
   const [securityCode, setSecurityCode] = useState("");
   const isAuthorized = useLocation().pathname === "/claimReport/authorized";
   const { token } = useSelector((state: StoreType) => state.tokenState);
   const dispatch = useDispatch();

   const clientIdentification = useSelector(
      (state: StoreType) => state.claimState.clientIdentification
   );

   const {
      sendRequest: sendRequestFinishBySMS,
      data: dataFinishBySMS,
      loading: loadingFinishBySMS,
      error: errorFinishBySMS,
      resetFetch: resetFetchFinishBySMS,
      fetchFired: fetchFiredFinishBySMS,
   } = useFetch();

   const {
      sendRequest: sendRequestResendSMS,
      data: dataResendSMS,
      loading: loadingResendSMS,
      error: errorResendSMS,
      resetFetch: resetFetchResendSMS,
      fetchFired: fetchFiredResendSMS,
   } = useFetch();

   const resendUrl = isAuthorized
      ? process.env.REACT_APP_ResendSignSmsCode
      : process.env.REACT_APP_ResendSignSmsCodeForAnonymous;

   useEffect(() => {
      dispatch(
         updateFifthStepFields({
            field: "claimReportRowKey",
            value: dataCreateClaim?.claimReportRowKey,
         })
      );
   }, []);

   const phoneNumber = useSelector(
      (state: StoreType) => state.claimState.personModel.phoneNumber
   );

   const { throutfulPersonPhoneNumber } = useSelector(
      (state: StoreType) => state.claimState.personModel.personThroutful
   );

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isDeathBeingReported = selectedTypeOfClaims.some(
      (claim: string) => claim === "death"
   );

   const actualPhoneNumber = isDeathBeingReported
      ? throutfulPersonPhoneNumber
      : phoneNumber;

   const callResendSMS = () => {
      sendRequestResendSMS({
         method: "POST",
         url: process.env.REACT_APP_BaseURL! + resendUrl,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            personalId: clientIdentification.personalId,
            contractId: clientIdentification.contractId,
         },
      });
   };

   const callFinishBySMS = () => {
      sendRequestFinishBySMS({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_SignBySmsCode,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            claimId: dataCreateClaim.claimId,
            code: securityCode,
            phoneNumber: actualPhoneNumber,
            claimReportRowKey: dataCreateClaim.claimReportRowKey,
            claimReportPartitionKey: dataCreateClaim.claimReportPartitionKey,
         },
      });
   };

   useEffect(() => {
      if (dataFinishBySMS) {
         openFinishModal();
         setIsModalOpen(false);
      }
   }, [dataFinishBySMS]);

   const renderMobileNumber = () => {
      if (!actualPhoneNumber) return null;
      return `${actualPhoneNumber.slice(0, 3)} ${actualPhoneNumber.slice(
         3,
         6
      )} ${actualPhoneNumber.slice(6, 9)}`;
   };

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   const handleCloseModal = () => {
      dispatch(
         updateFifthStepFields({
            field: "finishedClaimByMobileIdentification",
            value: false,
         })
      );
      callCreateClaim(4, false);
      setIsModalOpen(false);
      setSecurityCode("");
      resetFetchResendSMS();
      resetFetchFinishBySMS();
   };

   return (
      <Modal
         variant="Elevated"
         isOpen={isModalOpen}
         onClose={handleCloseModal}
         height="auto"
         width="clamp(5rem, 100vw, 35rem)"
         hasBackdrop
      >
         <Box
            sx={{
               padding: "2rem 1rem 1rem 1rem",
               display: "flex",
               flexDirection: "column",
               position: "relative",
            }}
         >
            <Box sx={{ position: "absolute", top: ".5rem", right: "-.25rem" }}>
               <IconButton iconName="Cross" onClick={handleCloseModal} />
            </Box>
            <Heading hasMargin={false} mb=".75rem" variant={"Large"}>
               SMS podpis
            </Heading>
            <Paragraph mb="2rem">
               Na telefonní číslo
               <Box
                  component={"span"}
                  sx={{
                     fontWeight: `${fontWeightTokens.strongImportance.get}`,
                     fontFamily: `${fontFamilyTokens.strongImportance.value}`,
                     marginLeft: "4px",
                  }}
               >
                  + 420 {renderMobileNumber()}
               </Box>{" "}
               jsme vám odeslali bezpečnostní kód, kterým podepíšete hlášení.
            </Paragraph>
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  "@media (min-width: 525px)": {
                     flexDirection: "row",
                  },
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     "@media (max-width: 525px)": {
                        alignItems: "center",
                     },
                  }}
               >
                  <CustomInput
                     labelText="Bezpečnostní kód"
                     placeholderText="Zadejte kód z SMS"
                     value={securityCode}
                     onChange={(e) => {
                        setSecurityCode(e.target.value);
                     }}
                     validationText={errorFinishBySMS ? "Nesprávný kód" : ""}
                  />
                  <Box
                     sx={{
                        "& > *": { textDecoration: "underline !important" },
                     }}
                  >
                     <Button
                        mt=".5rem"
                        variant="PlainSmall"
                        onClick={callResendSMS}
                     >
                        Poslat kód znovu
                     </Button>
                     {dataResendSMS && (
                        <AttentionBlock mt="1rem" mb="1rem" variant="Success">
                           <Paragraph>
                              SMS byla úspěšně znova odeslána
                           </Paragraph>
                        </AttentionBlock>
                     )}
                  </Box>
                  <Box sx={{ "& > *": { padding: "0 1.75rem !important" } }}>
                     <Button
                        mt=".5rem"
                        mb=".5rem"
                        iconProps={{
                           placement: "Right",
                           name: "ArrowRight",
                        }}
                        variant="PrimaryMedium"
                        onClick={callFinishBySMS}
                     >
                        Podepsat
                     </Button>
                  </Box>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     flex: "1",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <img src={MobileSign} alt="picture of phone"></img>
               </Box>
            </Box>
         </Box>
      </Modal>
   );
};

export default SMSModal;
