import { useEffect } from "react";
import { Box } from "@mui/material";
import { Heading, Paragraph } from "@nn-design-system/react-component-library";
import PersonSelectionField from "../../Components/PersonSelectionField";
import useFetch from "../../CustomHooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { setPagesToRender } from "../../redux/reducers/pagesToRenderReducer";
import {
   setCurrentlyReportedPerson,
   setIsSelectedPerson,
} from "../../redux/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { StoreType } from "../../redux/store/store";
import { setClientInfo } from "../../redux/reducers/claimReducer";
import icon from "../../Assets/peopleTableIcon.png";

const PersonSelectionPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const { personModel } = useSelector((state: StoreType) => state.authState);

   const { token } = useSelector((state: StoreType) => state.tokenState);

   const {
      sendRequest: sendRequestGetClientClaims,
      data: dataGetClientClaims,
      fetchFired: fetchFiredGetClientClaims,
   } = useFetch();

   const callGetClientClaims = (claimForPersonId: string) => {
      sendRequestGetClientClaims({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_GetClientClaims,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            personalId: personModel.personalId,
            contractId: "",
            claimForPersonId: claimForPersonId,
         },
      });
   };

   useEffect(() => {
      if (personModel.insuredPersons.length === 1) {
         dispatch(
            setCurrentlyReportedPerson({
               name: `${personModel.insuredPersons[0].firstname} ${personModel.insuredPersons[0].lastname}`,
               birthNumber: personModel.insuredPersons[0].personalId,
            })
         );
         callGetClientClaims(personModel.insuredPersons[0].personalId);
      }
   }, [personModel]);

   useEffect(() => {
      if (dataGetClientClaims) {
         if (dataGetClientClaims) {
            dispatch(setPagesToRender(dataGetClientClaims?.result.result));
            dispatch(setIsSelectedPerson(true));
            dispatch(
               setClientInfo({
                  personalId: dataGetClientClaims?.result.claimForPersonId,
                  claimForPersonId: personModel.personalId,
               })
            );
            navigate(`/claimSelection/authorized`);
         } else {
            // dataGetClientClaims.result.errors.map((error) => {
            // });
         }
      }
   }, [fetchFiredGetClientClaims, dataGetClientClaims, dispatch, navigate]);

   // Filter out persons who are not insured
   const insuredPersons = personModel.insuredPersons.filter(person => !person.isNotInsured);

   return (
      <Box
         sx={{
            width: "80%",
            "@media (max-width: 800px)": {
               width: "85%",
            },
            "@media (max-width: 550px)": {
               width: "92.5%",
            },
            padding: "0",
            "@media (max-width: 600px)": {
               padding: "0 1rem",
            },
         }}
      >
         <Box
            sx={{
               display: "grid",
               gridTemplateColumns: { xs: "8rem 1fr", sm: "10rem 1fr" },
               gridGap: 10,
               margin: "3rem 0 2rem 0",
               position: "relative",
            }}
         >
            <Box
               sx={{
                  gridColumn: "1 / span 2",
               }}
            >
               <Heading hasMargin={false} mb="1rem" variant={"Large"}>
                  Co o vás víme?
               </Heading>
            </Box>
            <Box sx={{}}>
               <Heading variant={"Small"} hasMargin={false}>
                  Přihlášený uživatel:
               </Heading>
            </Box>
            <Box sx={{}}>
               <Paragraph variant={"Small"} hasMargin={false}>
                  {personModel.firstName} {personModel.lastName}
               </Paragraph>
            </Box>
            <Box sx={{}}>
               <Heading variant={"Small"} hasMargin={false}>
                  Rodné číslo:
               </Heading>
            </Box>
            <Box sx={{}}>
               <Paragraph variant={"Small"} hasMargin={false}>
                  {`${personModel.personalId.slice(
                     0,
                     personModel.personalId.length - 4
                  )}/${personModel.personalId.slice(
                     personModel.personalId.length - 4
                  )}`}
               </Paragraph>
            </Box>
            <Box
               sx={{
                  position: "absolute",
                  top: "-2rem",
                  left: "clamp(16rem, 50vw, 35rem)",
                  display: { xs: "none", sm: "block" },
               }}
            >
               <img src={icon} alt="icon of documents"></img>
            </Box>
         </Box>
         <PersonSelectionField insuredPersons={insuredPersons} callGetClientClaims={callGetClientClaims} />
      </Box>
   );
};

export default PersonSelectionPage;