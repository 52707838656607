import { Box } from "@mui/material";
import { Icon, Paragraph } from "@nn-design-system/react-component-library";
import { NameType } from "@nn-design-system/react-component-library/dist/components/Icon/generated";
import React from "react";

type CustomChipProps = {
   label: string;
   icon: NameType;
};

const CustomChip: React.FC<CustomChipProps> = ({ label, icon }) => {
   return (
      <Box
         sx={{
            backgroundColor: "#FFDBB2",
            padding: ".2rem .4rem",
            borderRadius: ".25rem",
            display: "flex",
            gap: ".4rem",
            alignItems: "center",
            "& > *": { color: "#834600 !important" },
         }}
      >
         <Icon name={icon} color={"#834600"} customSize="1rem" />
         <Paragraph variant="Small" hasMargin={false}>
            {label}
         </Paragraph>
      </Box>
   );
};

export default CustomChip;
