import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import CustomInput from "../../../../../Components/CustomInputs/CustomInput";
import { updateFifthStepAgentFields } from "../../../../../redux/reducers/claimReducer";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import React from "react";
import { useScrollToTopErrorInput } from "../../../../../CustomHooks/useScrollToTopErrorInput";

const AgentLastNameField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "agentLastname";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.agentModel[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const { agentLastname } = useSelector(
      (state: StoreType) => state.authState.agentErorrs
   );

   const dependencyValue = useSelector(
      (state: StoreType) => state.claimState.reportModel.gdprAgreement
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <CustomInput
         labelText="Příjmení"
         placeholderText="Uveďte příjmení poradce"
         value={(fieldValue as string) || ""}
         onChange={(e) => {
            dispatch(
               updateFifthStepAgentFields({
                  value: e.target.value,
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={agentLastname}
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
         inputRef={ref}
      />
   );
};

export default AgentLastNameField;
