import { Navigate, Route, RouteProps } from "react-router-dom";
import React, { ComponentType } from "react";

function withAuth(Component: React.FC<{}>, booleanArray: boolean[]) {
   if (booleanArray.every((item) => item)) {
      return <Component />;
   } else {
      return <Navigate to="/" replace />;
   }
}

export default withAuth;
