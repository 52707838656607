import React from "react";
import GetValuesForSecondStepGeneralFields from "../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { Divider, Heading } from "@nn-design-system/react-component-library";
import ResponsibilityPersonForChildLastname from "./ResponsibilityPersonFields/ResponsibilityPersonForChildLastname";
import ResponsibilityPersonForChildName from "./ResponsibilityPersonFields/ResponsibilityPersonForChildName";
import ResponsibilityPersonForChildPersonId from "./ResponsibilityPersonFields/ResponsibilityPersonForChildPersonId";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";

const ResponsibilityPersonSection: React.FC = () => {
   const { fieldValue: value1, visibilityMatrixes } =
      GetValuesForSecondStepGeneralFields(
         "responsibilityPersonForChildLastname"
      );

   const { isChild, signModeAnonym } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );

   if (isChild && signModeAnonym) {
      return (
         <GridContainer>
            <WholeLineContainer>
               <>
                  <Heading
                     variant={"Medium"}
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     Osobní údaje zákonného zástupce
                  </Heading>
                  <Divider variant="Brand"></Divider>
               </>
            </WholeLineContainer>
            <ResponsibilityPersonForChildName />
            <ResponsibilityPersonForChildLastname />
            <ResponsibilityPersonForChildPersonId />
         </GridContainer>
      );
   } else {
      return null;
   }
};

export default ResponsibilityPersonSection;
