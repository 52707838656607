import { createSlice } from "@reduxjs/toolkit";

//holds a value of a form that user should be redirect to after succesfull login

export type token = string | null;

export type tokenInitialState = {
   token: token | undefined;
};

const initialState: tokenInitialState = {
   token: null,
};

type action = {
   payload: token;
   type: string;
};

const tokenSlice = createSlice({
   name: "token",
   initialState,
   reducers: {
      setToken: (state, action: action) => {
         state.token = action.payload;
      },
   },
});

export const { setToken } = tokenSlice.actions;
export default tokenSlice.reducer;
