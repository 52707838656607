import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { StoreType } from "../../../../../../redux/store/store";
import { Select } from "@nn-design-system/react-component-library";
import SingleLineContainer from "../../../../../../Components/ReportFormTemplate/SingleLineContainer";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import React from "react";

const InsuredRelationshipField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "insuredRelationship";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.personThroutful[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <SingleLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <div ref={ref}>
            <Select
               labelText="Vztah mezi obmyšlenou a zemřelou osobu byl"
               placeholderText="Vyberte vztah k zemřelé(mu)"
               value={fieldValue ?? undefined}
               onChange={(e: any) => {
                  dispatch(
                     updateSecondStepGeneralsNestedFields({
                        value: e.target.value,
                        sectionName: "personThroutful",
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               options={[
                  { text: "Manžel/Manželka", value: 0 },
                  { text: "Rodič", value: 1 },
                  { text: "Dítě", value: 2 },
                  { text: "Jiné", value: 3 },
               ]}
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </div>
      </SingleLineContainer>
   );
};

export default InsuredRelationshipField;
