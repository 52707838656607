import React from "react";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Checkbox,
   Divider,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import PhoneNumberField from "./ContactFields/PhoneNumberField";
import EmailField from "./ContactFields/EmailField";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";

type ContactSectionProps = {};

const ContactSection: React.FC<ContactSectionProps> = ({}) => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const { isChild } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );

   if (
      !shouldRenderSpecField("GeneralFields", "Email", visibilityMatrixes) &&
      !shouldRenderSpecField(
         "GeneralFields",
         "PhoneNumber",
         visibilityMatrixes
      ) &&
      !shouldRenderSpecField(
         "GeneralFields",
         "PProccesingByMailAgreement",
         visibilityMatrixes
      )
   ) {
      return null;
   }

   return (
      <GridContainer>
         <WholeLineContainer>
            <>
               <Heading
                  variant={"Medium"}
                  hasMargin={false}
                  mb=".5rem"
                  mt="1rem"
               >
                  {isChild
                     ? "Vaše kontaktní údaje"
                     : "Kontaktní údaje pojištěné osoby"}
               </Heading>
               <Divider variant="Brand"></Divider>
            </>
         </WholeLineContainer>

         <PhoneNumberField />
         <EmailField />
      </GridContainer>
   );
};

export default ContactSection;
