import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFetch from "../../CustomHooks/useFetch";
import { updateFifthStepFields } from "../../redux/reducers/claimReducer";
import { StoreType } from "../../redux/store/store";
import {
   AttentionBlock,
   Button,
   Card,
   Heading,
   Icon,
   IconButton,
   Link,
   Modal,
   Paragraph,
} from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";
import QRCodeSection from "../QRCodeSection";
import { colourBackgroundTint } from "../../Config/colors";
import FinishModal from "./FinishModal";

type QRSignatureModalProps = {
   isModalOpen: boolean;
   closeModal: any;
   dataCreateClaim: any;
   callCreateClaim: any;
   openFinishModal: any;
};

const QRSignatureModal: React.FC<QRSignatureModalProps> = ({
   isModalOpen,
   closeModal,
   dataCreateClaim,
   callCreateClaim,
   openFinishModal,
}) => {
   const {
      id,
      documentUrl,
      accessNumber,
      claimReportPartitionKey,
      claimReportRowKey,
   } = dataCreateClaim || {};
   const dispatch = useDispatch();

   const handleCopy = () => {
      navigator.clipboard.writeText(accessNumber);
   };

   const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);

   const {
      sendRequest: reguestSignDocument2D,
      data: dataSignDocument2D,
      resetFetch: resetFetchDocumentSign2D,
      error: errorSignDocument2D,
   } = useFetch();

   useEffect(() => {
      dispatch(
         updateFifthStepFields({
            field: "claimReportRowKey",
            value: claimReportRowKey,
         })
      );
   }, [claimReportRowKey, dispatch]);

   const { token } = useSelector((state: StoreType) => state.tokenState);

   const signDocument2D = () => {
      reguestSignDocument2D({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_SignDocument2D,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            claimId: id,
            documentUrl: documentUrl,
            sendLinkToPhoneNumber: false,
            sendLinkToEmail: false,
            accessNumber: accessNumber,
            claimReportRowKey: claimReportRowKey,
            claimReportPartitionKey: claimReportPartitionKey,
            email: "",
            phoneNumber: "",
         },
      });
   };

   useEffect(() => {
      if (dataSignDocument2D) {
         setIsFinishModalOpen(true);
      }
   }, [dataSignDocument2D]);

   useEffect(() => {
      resetFetchDocumentSign2D();
   }, [isModalOpen]);

   const finalURL = `https://apim.nn.cz/utils/qrcode?chs=250x250&cht=qr&chl=${documentUrl}&choe=UTF-8&chld=H|1`;

   const handleCloseModal = () => {
      dispatch(
         updateFifthStepFields({
            field: "finishedClaimBy2DSignature",
            value: false,
         })
      );
      callCreateClaim(4, false);
      closeModal();
   };

   return (
      <Modal
         variant="Elevated"
         isOpen={isModalOpen}
         onClose={handleCloseModal}
         height="auto"
         width="44rem"
         hasBackdrop
      >
         <Box
            sx={{
               position: "relative",
            }}
         >
            <Box sx={{ position: "absolute", top: ".5rem", right: "-.25rem" }}>
               <IconButton iconName="Cross" onClick={handleCloseModal} />
            </Box>
            <Heading variant="Medium" mr="2rem">
               Dokument pojistného hlášení čeká na váš podpis
            </Heading>
            <Paragraph>
               Klikněte na odkaz nebo si dokument otevřete jednoduše pomocí QR
               kódu (třeba na mobilu) a zadejte váš přístupový kód. Pak už jen
               stačí dokument podepsat.
            </Paragraph>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginTop: "2rem",
                  flexWrap: "wrap",
               }}
            >
               <Heading hasMargin={false} variant="Small">
                  Váš přístupový kód:
               </Heading>
               <Card variant="Tinted">
                  <Paragraph hasMargin={false}>
                     {accessNumber || "123456789"}
                  </Paragraph>
               </Card>
               <Button
                  onClick={handleCopy}
                  variant="PlainSmall"
                  iconProps={{
                     name: "Forms",
                     placement: "Right",
                  }}
               >
                  Zkopírovat
               </Button>
            </Box>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2rem",
                  "@media (max-width: 500px)": {
                     flexDirection: "column",
                     gap: ".5rem",
                  },
               }}
            >
               <Heading hasMargin={false} mr="1rem" variant={"Small"}>
                  Adresa podpisu dokumentu:
               </Heading>
               <Box sx={{ display: "flex" }}>
                  <Link
                     href={documentUrl}
                     testId="link-test-id"
                     text="Otevřít v prohlížeči"
                     mr="0.5rem"
                     target="_blank"
                  />
                  <Icon
                     mb=".2rem"
                     color="#EA650D"
                     name="ArrowBox"
                     customSize="1.2rem"
                  />
               </Box>
            </Box>
            <Box
               sx={{
                  backgroundColor: colourBackgroundTint,
                  padding: ".5rem",
                  margin: "1rem",
               }}
            >
               <Paragraph hasMargin={false}>
                  {documentUrl || "www.urlToCopy.com"}
               </Paragraph>
            </Box>

            <QRCodeSection
               QRCodeUrl={finalURL}
               documentUrl={documentUrl}
               accessNumber={accessNumber}
               claimReportPartitionKey={claimReportPartitionKey}
               claimReportRowKey={claimReportRowKey}
               claimId={id}
            />
            {errorSignDocument2D && (
               <AttentionBlock variant="Warning">
                  <Paragraph>Dokument prozatím nebyl podepsán</Paragraph>
               </AttentionBlock>
            )}
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                  "@media (max-width: 500px)": {
                     flexDirection: "column-reverse",
                     gap: ".5rem",
                     justifyContent: "center",
                     alignItems: "center",
                  },
               }}
            >
               <Button
                  variant="Secondary"
                  iconProps={{
                     name: "ArrowLeft",
                     placement: "Left",
                  }}
                  onClick={handleCloseModal}
               >
                  Zvolit jiný způsob
               </Button>
               <Button
                  variant="PrimaryMedium"
                  iconProps={{
                     name: "ArrowRight",
                     placement: "Right",
                  }}
                  onClick={signDocument2D}
               >
                  Podepsáno
               </Button>
            </Box>
            <FinishModal isModalOpen={isFinishModalOpen} />
         </Box>
      </Modal>
   );
};

export default QRSignatureModal;
