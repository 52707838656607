import { createSlice } from "@reduxjs/toolkit";

export type FinishModelType = {
   claimId: string;
   documentUrl: string;
   accessNumber: string;
   claimReportPartitionKey: string;
   claimReportRowKey: string;
};

export type InsuredPersons = {
   firstname: string;
   lastname: string;
   personalId: string;
   isNotInsured: boolean;
};

type personModel = {
   firstName: string;
   lastName: string;
   personalId: string;
   insuredPersons: InsuredPersons[];
};

export type authInitialState = {
   isLoggedIn: boolean;
   isPersonSelected: boolean;
   showCancelClaimModal: boolean;
   userAuthenticationStatus: "anonym" | "authorized" | null;
   personModel: personModel;
   currentlyReportedPerson: {
      name: string;
      birthNumber: string;
   };
   finishModel: FinishModelType;
   agentErorrs: {
      agentFirstname: string;
      agentLastname: string;
      agentPhoneNumber: string;
   };
};

const initialState: authInitialState = {
   isLoggedIn: false,
   isPersonSelected: false,
   showCancelClaimModal: false,
   userAuthenticationStatus: null,
   personModel: {
      firstName: "",
      lastName: "",
      personalId: "",
      insuredPersons: [],
   },
   currentlyReportedPerson: {
      name: "",
      birthNumber: "",
   },
   finishModel: {
      claimId: "",
      documentUrl: "",
      accessNumber: "",
      claimReportPartitionKey: "",
      claimReportRowKey: "",
   },
   agentErorrs: {
      agentFirstname: "",
      agentLastname: "",
      agentPhoneNumber: "",
   },
};

type setIsSelectedPersonType = {
   payload: boolean;
   type: string;
};

type setIsAnonymOrAuthorizedType = {
   payload: "anonym" | "authorized" | null;
   type: string;
};

type SetPersonModel = {
   payload: personModel;
   type: string;
};

const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      login: (state) => {
         state.isLoggedIn = true;
      },
      logout: (state) => {
         state.isLoggedIn = false;
      },
      setIsSelectedPerson: (state, action: setIsSelectedPersonType) => {
         state.isPersonSelected = action.payload;
      },
      setUserAuthenticationStatus: (
         state,
         action: setIsAnonymOrAuthorizedType
      ) => {
         state.userAuthenticationStatus = action.payload;
      },
      setPersonModel: (state, action: SetPersonModel) => {
         state.personModel = action.payload;
      },
      setCurrentlyReportedPerson: (state, action) => {
         state.currentlyReportedPerson = action.payload;
      },
      setShowCancelClaimModal: (state, action) => {
         state.showCancelClaimModal = action.payload;
      },
      setAgentErrors: (state, action) => {
         const { agentFirstname, agentLastname, agentPhoneNumber } =
            action.payload;
         state.agentErorrs.agentFirstname = agentFirstname;
         state.agentErorrs.agentLastname = agentLastname;
         state.agentErorrs.agentPhoneNumber = agentPhoneNumber;
      },
      resetAgentErrors: (state) => {
         state.agentErorrs.agentFirstname = "";
         state.agentErorrs.agentLastname = "";
         state.agentErorrs.agentPhoneNumber = "";
      },
      setFinishModel: (state, action) => {
         const {
            claimId,
            documentUrl,
            accessNumber,
            claimReportPartitionKey,
            claimReportRowKey,
         } = action.payload;

         return {
            ...state,
            finishModel: {
               claimId: claimId,
               documentUrl: documentUrl,
               accessNumber: accessNumber,
               claimReportPartitionKey: claimReportPartitionKey,
               claimReportRowKey: claimReportRowKey,
            },
         };
      },
   },
});

export const {
   login,
   logout,
   setIsSelectedPerson,
   setUserAuthenticationStatus,
   setPersonModel,
   setShowCancelClaimModal,
   setFinishModel,
   setCurrentlyReportedPerson,
   setAgentErrors,
   resetAgentErrors,
} = authSlice.actions;
export default authSlice.reducer;
