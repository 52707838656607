import React from "react";
import SectionTemplate from "../../Template/SectionTemplate";
import SeriousDiseasesDateOfClaimFromField from "./SeriousDiseasesFields/SeriousDiseasesDateOfClaimFromField";
import SeriousDiseasesDateOfClaimToField from "./SeriousDiseasesFields/SeriousDiseasesDateOfClaimToField";
import SeriousDiseasesTimeOfClaimField from "./SeriousDiseasesFields/SeriousDiseasesTimeOfClaimField";
import SeriousDiseasesAdditionalInjuryDescriptionField from "./SeriousDiseasesFields/SeriousDiseasesAdditionalInjuryDescriptionField";
import { StoreType } from "../../../../../../redux/store/store";
import { useSelector } from "react-redux";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import TypeOfInjuriesForSeriuseDieses from "../GeneralSection/GeneralFields/TypeOfInjuriesForSeriouseDieses";
import OtherDiagnoseTextField from "../GeneralSection/GeneralFields/OtherDiagnoseTextField";

const SeriousDiseasesSection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("seriousdiseases", visibilityMatrixes)) return null;
   return (
      <SectionTemplate
         heading="Popis závažného onemocnění nebo zdravotního následku"
         iconName="Alert"
        //  description="Pojistnou událost chceme vyřešit co nejrychleji. Bez podrobností o vzniku závažného onemocnění nebo zdravotního následku se neobjedeme. Prosím, vyplňte proto pečlivě následující otázky."
      >
         <SeriousDiseasesDateOfClaimFromField />
         <SeriousDiseasesDateOfClaimToField />
         <SeriousDiseasesTimeOfClaimField />
         <SeriousDiseasesAdditionalInjuryDescriptionField />
         <TypeOfInjuriesForSeriuseDieses />
         <OtherDiagnoseTextField />
      </SectionTemplate>
   );
};

export default SeriousDiseasesSection;
