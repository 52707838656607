import { useEffect } from "react";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { updateThirdStepGeneralFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import {
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";
import { Box } from "@mui/material";

const PaymentAdressField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "paymentAddress";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const adressValue = useSelector(
      (state: StoreType) => state.claimState.personModel.address
   );

   const { throutfulPersonAddress } = useSelector(
      (state: StoreType) => state.claimState.personModel.personThroutful
   );

   const cityValue = useSelector(
      (state: StoreType) => state.claimState.personModel.city
   );

   const { throutfulPersonCity } = useSelector(
      (state: StoreType) => state.claimState.personModel.personThroutful
   );

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isDeathBeingReported = selectedTypeOfClaims.some(
      (claim: string) => claim === "death"
   );

   const actualAdress = isDeathBeingReported
      ? throutfulPersonAddress
      : adressValue;
   const actualCity = isDeathBeingReported ? throutfulPersonCity : cityValue;

   useEffect(() => {
      if (!fieldValue) {
         dispatch(
            updateThirdStepGeneralFields({
               value: `${actualAdress} ${actualCity}`,
               field: fieldNameLowerCase,
            })
         );
      }
   }, [dispatch]);

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   return (
      <Box>
         <Paragraph hasMargin={false}>
            <Box
               component={"span"}
               sx={{
                  fontWeight: `${fontWeightTokens.strongImportance.get}`,
                  fontFamily: `${fontFamilyTokens.strongImportance.value}`,
               }}
            >
               Adresa{" "}
            </Box>
            (Vyplatíme složenkou na adresu)
         </Paragraph>
         <CustomInput
            // labelText="Adressa"
            // subtext="(Vyplatíme složenkou na adresu)"
            labelText=""
            subtext=""
            placeholderText="Uveďte adresu"
            value={(fieldValue as string) || ""}
            onChange={(e) => {
               dispatch(
                  updateThirdStepGeneralFields({
                     value: e.target.value,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            //  dataState="ReadOnly"
         />
      </Box>
   );
};

export default PaymentAdressField;
