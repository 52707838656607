import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../CustomInputs/CustomInput";
import {
   AttentionBlock,
   Button,
   Paragraph,
   Popover,
} from "@nn-design-system/react-component-library";
import useCustomState from "../../../CustomHooks/useCustomState";
import useFetch from "../../../CustomHooks/useFetch";
import { useDispatch } from "react-redux";
import { setPagesToRender } from "../../../redux/reducers/pagesToRenderReducer";
import {
   login,
   setIsSelectedPerson,
   setUserAuthenticationStatus,
} from "../../../redux/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { setClientInfo } from "../../../redux/reducers/claimReducer";
import { checkLength } from "../../../Utils/CheckLength";

type noAuthFormType = {
   PersonalId: string;
   ContractId: string;
};

type LoginFormContainerProps = {
   isSectionOpen: boolean;
   setIsSectionOpen: any;
};

const LoginFormContainer: React.FC<LoginFormContainerProps> = ({
   isSectionOpen,
   setIsSectionOpen,
}) => {
   const {
      state: noAuthForm,
      stateValidation: noAuthFormValidation,
      setStateHandler: setFormHandler,
      resetStateValidation,
      updateStateValidation,
   } = useCustomState<noAuthFormType>({
      PersonalId: "",
      ContractId: "",
   });

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [errorState, setErrorState] = useState("");

   const {
      sendRequest: sendRequestGetAnonymousClientClaims,
      data: dataGetAnonymousClientClaims,
      error: errorGetAnonymousClientClaims,
      fetchFired: fetchFiredGetAnonymousClientClaims,
   } = useFetch();

   const callGetAnonymousClientClaims = () => {
      sendRequestGetAnonymousClientClaims({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_GetAnonymousClientClaims,
         headers: {
            "Content-Type": "application/json",
         },
         data: {
            personalId: noAuthForm.PersonalId.replace(/[\s/]+/g, ""),
            contractId: noAuthForm.ContractId.replace(/\s+/g, ""),
            claimForPersonId: noAuthForm.PersonalId.replace(/\s+/g, ""),
         },
      });
   };

   useEffect(() => {
      if (errorGetAnonymousClientClaims) {
         resetStateValidation();
         setErrorState("Kombinace rodného čísla a smlouvy bohužel neexistuje");
      } else if (dataGetAnonymousClientClaims) {
         resetStateValidation();
         dispatch(login());
         dispatch(
            setPagesToRender(dataGetAnonymousClientClaims?.result.result)
         );
         dispatch(setIsSelectedPerson(true));
         dispatch(
            setClientInfo({
               personalId: noAuthForm.PersonalId.replace(/[\s/]+/g, ""),
               contractId: noAuthForm.ContractId.replace(/\s+/g, ""),
               claimForPersonId: noAuthForm.PersonalId.replace(/[\s/]+/g, ""),
            })
         );
         dispatch(setUserAuthenticationStatus("anonym"));
         navigate(`/claimSelection/anonym`);
      }
   }, [fetchFiredGetAnonymousClientClaims]);

   const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (
         noAuthForm.PersonalId.length < 9 ||
         noAuthForm.PersonalId.length > 10
      ) {
         setErrorState("");
         updateStateValidation(
            "PersonalId",
            "Rodné číslo musí mít 9 či 10 znaků"
         );
      } else {
         callGetAnonymousClientClaims();
      }
   };

   return (
      <>
         <form onSubmit={handleFormSubmit}>
            {isSectionOpen && (
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     gap: "1rem",
                  }}
               >
                  <CustomInput
                     labelText="Rodné číslo"
                     value={noAuthForm.PersonalId}
                     onChange={(e) => {
                        setFormHandler(
                           "PersonalId",
                           checkLength(e.target.value, 10),
                           "birthNumber"
                        );
                        if (
                           noAuthForm.PersonalId.length === 9 ||
                           noAuthForm.PersonalId.length === 10
                        ) {
                           updateStateValidation("PersonalId", "");
                        }
                     }}
                     validationText={noAuthFormValidation.PersonalId}
                     placeholderText="Zadejte rodné číslo bez lomítka"
                     //  subtext="(Cizinci zadávají číslo pojištěnce)"
                  />
                  <CustomInput
                     labelText="Číslo smlouvy"
                     value={noAuthForm.ContractId}
                     onChange={(e) => {
                        setFormHandler("ContractId", e.target.value, "number");
                        updateStateValidation("ContractId", "");
                     }}
                     validationText={noAuthFormValidation.ContractId}
                     placeholderText="Zadejte číslo smlouvy"
                     popoverText="Najdete ho nejen na smlouvě, ale také například v e-mailu nebo dopisu od nás."
                  />

                  {errorState && (
                     <AttentionBlock variant="Error">
                        <Paragraph>{errorState}</Paragraph>
                     </AttentionBlock>
                  )}

                  <Box>
                     <Button type="Submit" variant={"PrimaryMedium"}>
                        Pokračovat
                     </Button>
                  </Box>
               </Box>
            )}
         </form>
         {!isSectionOpen && (
            <Button
               mt=".5rem"
               variant={"PrimaryMedium"}
               type="Button"
               onClick={() => {
                  setIsSectionOpen(!isSectionOpen);
               }}
            >
               Pokračovat bez přihlášení
            </Button>
         )}
      </>
   );
};

export default LoginFormContainer;
