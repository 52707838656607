import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomInput from "../../../../../../../Components/CustomInputs/CustomInput";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const InvalidClientOrChildBeforeDiagnoseField: React.FC = () => {
   const fieldNameLowerCase = "invalidClientOrChildBeforeDiagnose";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const dependencyValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase
            .isInvalidClientOrChildBeforeInjury
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <WholeLineContainer
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
      >
         <CustomInput
            labelText="Popis diagnózy"
            placeholderText="Popište nám vaši diagnózu"
            value={fieldValue || ""}
            onChange={(e) => {
               dispatch(
                  updateFirstStepGeneralFields({
                     value: e.target.value,
                     field: "invalidClientOrChildBeforeDiagnose",
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               "invalidClientOrChildBeforeDiagnose",
               claimFieldErrorsMessages
            )}
            maxCharacters="100"
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default InvalidClientOrChildBeforeDiagnoseField;
