import React, { useEffect } from "react";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import CustomInput from "../../../../../../../Components/CustomInputs/CustomInput";
import { useDispatch } from "react-redux";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import GetValuesForFirstStepGeneralFields from "../../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForFirstStepGeneralFields";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const InjuryBodyPartField: React.FC = () => {
   const fieldNameLowerCase = "injuryBodyPart";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForFirstStepGeneralFields(fieldNameLowerCase);

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <CustomInput
            labelText="Která část těla byla poraněna?"
            placeholderText="Uveďte poraněnou část těla"
            value={(fieldValue as string) || ""}
            onChange={(e) => {
               dispatch(
                  updateFirstStepGeneralFields({
                     value: e.target.value,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            maxCharacters="100"
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default InjuryBodyPartField;
