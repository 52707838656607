import { createSlice } from "@reduxjs/toolkit";

export type ErrorMessage = {
   propertyId: string;
   message: string;
};

const initialState: ErrorMessage[] = [];

type AddErrorMessage = {
   payload: {
      propertyId: string;
      message: string;
   };
   type: string;
};

const claimFieldErrorsSlice = createSlice({
   name: "claimFieldErrors",
   initialState,
   reducers: {
      setClaimFieldErrors: (state, action) => {
         return action.payload;
      },
      resetClaimFieldErrors: (state) => {
         return initialState;
      },
      addErrorMessage: (state, action: AddErrorMessage) => {
         state.unshift(action.payload);
         return state;
      },
      removeErrorMessage: (state, action) => {
         return state.filter((error) => error.propertyId !== action.payload);
      },
   },
});

export const {
   setClaimFieldErrors,
   resetClaimFieldErrors,
   removeErrorMessage,
   addErrorMessage,
} = claimFieldErrorsSlice.actions;
export default claimFieldErrorsSlice.reducer;

export const handleShowErrorMessage = (
   field: string,
   errorMessages: ErrorMessage[]
) => {
   if (errorMessages.length === 0) {
      return "";
   } else {
      return (
         errorMessages.find((error: ErrorMessage) => {
            return error.propertyId === field;
         })?.message || ""
      );
   }
};
