import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { updateThirdStepGeneralFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import SanitizeNumber from "../../../../../../Utils/SanitizeInputs/SanitizeNumber";

const PrefixBankAccountNumberField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "prefixBankAccountNumber";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   return (
      <CustomInput
         labelText="Předčíslí"
         placeholderText="Předčíslí"
         value={(fieldValue as string) || ""}
         onChange={(e) => {
            dispatch(
               updateThirdStepGeneralFields({
                  value: SanitizeNumber(e.target.value),
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            fieldNameUpperCase,
            claimFieldErrorsMessages
         )}
      />
   );
};
export default PrefixBankAccountNumberField;
