import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { StoreType } from "../../../../../../redux/store/store";
import CustomDateInput from "../../../../../../Components/CustomInputs/CustomDateInput";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const BusinessFromDateField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "businessFromDate";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.enterprenuerModel[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <CustomDateInput
         labelText="Provozováno od"
         value={(fieldValue as Date) || null}
         onChange={(e: any) => {
            dispatch(
               updateSecondStepGeneralsNestedFields({
                  value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
                  sectionName: "enterprenuerModel",
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            fieldNameUpperCase,
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      />
   );
};

export default BusinessFromDateField;
