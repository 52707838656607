import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { StoreType } from "../../../../../../redux/store/store";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";

const EmployerNameAndAddressField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "employerNameAndAddress";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.employeeModel[fieldNameLowerCase]
   );

   const dependencyValue = useSelector(
      (state: StoreType) => state.claimState.personModel.isEmployed
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <WholeLineContainer
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
      >
         <CustomInput
            labelText="Název a adresa zaměstnavatele"
            placeholderText="Uveďte název a adresu zaměstnavatele"
            value={(fieldValue as string) || ""}
            onChange={(e) => {
               dispatch(
                  updateSecondStepGeneralsNestedFields({
                     value: e.target.value,
                     sectionName: "employeeModel",
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default EmployerNameAndAddressField;
