import React from "react";
import CustomInput from "../../../../../../../Components/CustomInputs/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const InjuryTimeOfClaimField: React.FC = () => {
   const dispatch = useDispatch();

   const TimeOfClaimValueInjury = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 0
         )?.timeOfClaim
   );
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, "injury.TimeOfClaim");
   return (
      <CustomInput
         labelText="V kolik hodin se úraz stal"
         placeholderText="Uveďte čas"
         name="TimeOfClaim"
         value={TimeOfClaimValueInjury || ""}
         onChange={(e) => {
            dispatch(
               updateFirstStepDynamicFields({
                  value: e.target.value,
                  field: "timeOfClaim",
                  typeOfClaimEnum: 0,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            "injury.TimeOfClaim",
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "injury",
            "TimeOfClaim",
            visibilityMatrixes
         )}
         inputRef={ref}
      />
   );
};

export default InjuryTimeOfClaimField;
