import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { StoreType } from "../../../../../../redux/store/store";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomRadioGroup from "../../../../../../Components/CustomInputs/CustomRadioGroup";
import React, { useEffect } from "react";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const ThroutfulPersonNationalityField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "throutfulPersonIsForeigner";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.personThroutful[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

  //  useEffect(() => {
  //     dispatch(
  //        updateSecondStepGeneralsNestedFields({
  //           value: false,
  //           sectionName: "personThroutful",
  //           field: fieldNameLowerCase,
  //        })
  //     );
  //  }, []);

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <CustomRadioGroup
            heading="Státní příslušnost"
            firstLabel="Česká"
            secondLabel="Jiná"
            value={!fieldValue as boolean}
            updateFunc={(e: any) => {
               dispatch(
                  updateSecondStepGeneralsNestedFields({
                     value: e === "true" ? false : true,
                     sectionName: "personThroutful",
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default ThroutfulPersonNationalityField;
