type SanitizeBirthNumberProps = {
   value: string;
};

type Char = string & { length: 1 };

const SanitizeBirthNumber = ({ value }: SanitizeBirthNumberProps) => {
   const validChars = "0123456789 ";
   const sanitizedValue = value
      .split("")
      .filter((char) => validChars.includes(char as Char))
      .join("");
   return sanitizedValue;
};

export default SanitizeBirthNumber;
