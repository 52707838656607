import { useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";
import { PersonModel } from "../../redux/reducers/claimReducer";

const GetValuesForSecondStepGeneralFields = (
   fieldName: keyof PersonModel,
   dependencyFieldName?: keyof PersonModel
) => {
   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.personModel[fieldName]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const dependencyValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel[dependencyFieldName || fieldName]
   );

   return {
      fieldValue,
      visibilityMatrixes,
      claimFieldErrorsMessages,
      dependencyValue,
   };
};

export default GetValuesForSecondStepGeneralFields;
