import { useSelector } from "react-redux";
import { StoreType } from "../redux/store/store";
import { useEffect } from "react";
import { ScrollToRef } from "../Utils/ScrollToRef";

export const useScrollToTopErrorInput = (
   ref: React.RefObject<HTMLDivElement>,
   fieldNameUpperCase: string
) => {
   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   useEffect(() => {
      const shouldScrollTo =
         claimFieldErrorsMessages[0]?.propertyId === fieldNameUpperCase;
      if (shouldScrollTo) {
         ScrollToRef(ref);
      }
   }, [claimFieldErrorsMessages]);
};
