import { Box } from "@mui/material";
import CardAuthentication from "../../Components/LoginComponents/CardAuthentication/CardAuthentication";
import CardNoAuthentication from "../../Components/LoginComponents/CardNoAuthentication/CardNoAuthentication";
import { Paragraph } from "@nn-design-system/react-component-library";
import LoginBackground from "../../Assets/LoginBackground.jpg";
import NNHeading from "../../Components/NNHeading/NnHeading";

const LoginPage = () => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            overflow: "hidden",
         }}
      >
         <Box
            sx={{ paddingLeft: "1rem", margin: "4.5rem 0 2rem 0", zIndex: "4" }}
         >
            <Box
               sx={{
                  marginRight: "1rem",
                  "& > * > *": { color: "#ee7f00 !important" },
               }}
            >
               <NNHeading text="Než se pustíte do vyplňování" />
            </Box>
            <Paragraph mt=".5rem">
               Máte dvě možnosti, jak událost nahlásit.
            </Paragraph>
         </Box>
         <Box
            sx={{
               display: "grid",
               "@media (max-width: 600px)": {
                  gridTemplateColumns: "repeat(auto-fit, minmax(17rem, 1fr)) ",
               },
               gridTemplateColumns: "repeat(auto-fit, minmax(24rem, 1fr)) ",
               justifyContent: "center",
               alignItems: "end",
               gap: "2rem",
               width: "100%",
               gridAutoColumns: "1fr",
               padding: "1rem",
               zIndex: "5",
               marginBottom: "2rem",
            }}
         >
            <CardAuthentication />
            <CardNoAuthentication />
         </Box>
         <Box
            sx={{
               position: "absolute",
               right: "-10%",
               "& > img": {
                  zIndex: "1",
                  position: "relative",
                  "@media (max-width: 600px)": {
                     opacity: "0.7",
                  },
               },
            }}
         >
            <img alt="people sitting" src={LoginBackground} />
         </Box>
      </Box>
   );
};

export default LoginPage;
