import { useEffect } from "react";
import { updateThirdStepGeneralFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import {
   Checkbox,
   Paragraph,
   Popover,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";
import { Box } from "@mui/material";

const NeedAdvancePaymentField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "needAdvancePayment";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   useEffect(() => {
      dispatch(
         updateThirdStepGeneralFields({
            value: false,
            field: fieldNameLowerCase,
         })
      );
   }, []);

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <>
            <Paragraph hasMargin={false} mb=".75rem">
               <Box
                  component={"span"}
                  sx={{
                     fontWeight: `${fontWeightTokens.strongImportance.get}`,
                     fontFamily: `${fontFamilyTokens.strongImportance.value}`,
                  }}
               >
                  Potřebuji zálohu{" "}
               </Box>
               (nepovinné)
            </Paragraph>
            <Checkbox
               labelText="Žádám o zálohové plnění."
               variant="Small"
               onChange={() => {
                  dispatch(
                     updateThirdStepGeneralFields({
                        value: !fieldValue,
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               dataState={fieldValue ? "Checked" : "NotChecked"}
               popoverElement={
                  <Popover
                     headingText=""
                     placement="top-start"
                     testId="testId"
                     variant="Icon"
                  >
                     <Paragraph>
                        Pokud od vaší události uplynulo alespoň 6 týdnů, můžete
                        si požádat o zálohu. Tu vám můžeme poskytnout 1x
                        měsíčně. K jejímu vyplacení budeme potřebovat doložit
                        vždy aktuální podklady (zejména lékařskou zprávu).
                     </Paragraph>
                  </Popover>
               }
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </>
      </WholeLineContainer>
   );
};

export default NeedAdvancePaymentField;
