import React from "react";
import CustomDateInput from "../../../../../../../Components/CustomInputs/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import SingleLineRightContainer from "../../../../../../../Components/ReportFormTemplate/SingleLineRightContainer";
import SingleLineContainer from "../../../../../../../Components/ReportFormTemplate/SingleLineContainer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const InvalidClientOrChildBeforeInjuryFromField: React.FC = () => {
   const fieldNameLowerCase = "invalidClientOrChildBeforeInjuryFrom";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const dependencyValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase
            .isInvalidClientOrChildBeforeInjury
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <SingleLineContainer
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
      >
         <CustomDateInput
            labelText="Invalidní ode dne"
            placeholderText="DD.MM.RRRR"
            value={fieldValue || undefined}
            onChange={(e: any) => {
               dispatch(
                  updateFirstStepGeneralFields({
                     value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            inputRef={ref}
         />
      </SingleLineContainer>
   );
};

export default InvalidClientOrChildBeforeInjuryFromField;
