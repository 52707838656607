import { Box } from "@mui/material";
import {
   Card,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import React, { useState } from "react";
import LoginFormContainer from "./LoginFormContainer";

const CardNoAuthentication: React.FC = () => {
   const [isSectionOpen, setIsSectionOpen] = useState(false);

   return (
      <Box>
         <Card variant="Attentional">
            <Box
               sx={{
                  padding: ".5rem",
                  "@media (max-width: 500px)": {
                     padding: "0rem",
                  },
               }}
            >
               <Heading mb="1rem" variant="Large" hasMargin={false}>
                  Vyplním si sám/a
               </Heading>

               {!isSectionOpen && (
                  <Box sx={{ "& > *": { color: "#6D6D6D" } }}>
                     <Paragraph hasMargin={false} mb=".5rem">
                        Budete potřebovat vaše rodné číslo a číslo smlouvy.
                     </Paragraph>
                  </Box>
               )}
               <LoginFormContainer
                  isSectionOpen={isSectionOpen}
                  setIsSectionOpen={setIsSectionOpen}
               />
            </Box>
         </Card>
      </Box>
   );
};

export default CardNoAuthentication;
