import {
   Card,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import React from "react";
import GridContainer from "../../../Components/ReportFormTemplate/GridContainer";
import WholeLineContainer from "../../../Components/ReportFormTemplate/WholeLineContainer";
import FinishClaimSection from "./Sections/FinishClaimSection/FinishClaimSection";
import AgreementSection from "./Sections/AgreementSection/AgreementSection";
import NoticeSection from "./Sections/NoticeSection/NoticeSection";
import icon from "../../../Assets/iconForFifthStep.png";
import { Box } from "@mui/material";
import AgentSection from "./Sections/AgentSection/AgentSection";

const FifthStepContainer: React.FC = () => {
   return (
      <>
         <Card variant="Attentional">
            <GridContainer>
               <WholeLineContainer>
                  <Box
                     sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        gridTemplateRows: "auto auto",
                        gridTemplateAreas: `
                        'heading1 img'
                        'heading2 img'
                        'text img'
                      `,
                        "@media (max-width: 500px)": {
                           gridTemplateAreas: `
                          'heading1 img'
                          'heading2 img'
                          'text text'
                        `,
                        },
                     }}
                  >
                     <Box sx={{ gridArea: "heading1" }}>
                        <Heading variant={"Large"} hasMargin={false}>
                           Souhlasy a obecné informace
                        </Heading>
                     </Box>
                     <Box sx={{ gridArea: "heading2" }}>
                        <Heading mb="1rem" variant="Medium">
                           Seznamte se se souhlasy
                        </Heading>
                     </Box>
                     <Box sx={{ gridArea: "text" }}>
                        <Paragraph hasMargin={false}>
                           Od úspěšného odeslání vašeho hlášení pojistné
                           události vás dělí jen jeden krok. Potřebujeme, abyste
                           se seznámili se souhlasy zobrazenými níže. Více
                           informací se dočtete po rozbalení jednotlivých
                           souhlasů.
                        </Paragraph>
                     </Box>
                     <Box
                        sx={{
                           gridArea: "img",
                           "& > *": {
                              width: { xs: "6rem", sm: "9rem", md: "auto" },
                           },
                        }}
                     >
                        <img src={icon} alt="icon of documents"></img>
                     </Box>
                  </Box>
               </WholeLineContainer>
               <AgreementSection />
               <AgentSection />
               <NoticeSection />
            </GridContainer>
         </Card>
         <FinishClaimSection />
      </>
   );
};

export default FifthStepContainer;
