import {
	Alert,
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	useMediaQuery,
} from "@mui/material";
import {
	Icon,
	Paragraph,
	Popover,
} from "@nn-design-system/react-component-library";
import React from "react";
import { useTheme } from "@mui/material/styles";

type CustomRadioGroupProps = {
	value: boolean | null;
	updateFunc: any;
	heading: string;
	firstLabel: string;
	secondLabel: string;
	validationText: string;
	name?: string;
	dependencyValue?: boolean | null;
	alignment?: "horizontal" | "vertical";
	widthPercent?: string;
	popoverHeading?: string;
	popoverText?: string;
	popoverText2?: string;
	inputRef?: React.RefObject<HTMLDivElement>;
};

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
	value,
	updateFunc,
	heading,
	firstLabel,
	secondLabel,
	validationText,
	dependencyValue = true,
	alignment = "horizontal",
	widthPercent = "42%",
	popoverHeading,
	popoverText,
	popoverText2,
	inputRef,
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return dependencyValue ? (
		<div ref={inputRef}>
			<FormControl
				sx={{
					display: "flex",
					flexDirection: alignment === "horizontal" ? "row" : "column",
					alignItems: alignment === "horizontal" ? "center" : "flex-start",
					gap: heading.length === 0 ? "" : "1.5rem",
				}}
			>
				<FormLabel
					sx={{
						color: "#414141 !important",
						fontFamily: "NittiGroteskNN-TextMedium",
						fontWeight: 500,
						width: isSmallScreen ? "100%" : widthPercent,
						whiteSpace: alignment === "horizontal" ? "wrap" : "nowrap",
					}}
					id="demo-row-radio-buttons-group-label"
				>
					<Box>
						{heading}
						{popoverText && (
							<Popover
								headingText={popoverHeading}
								placement="top-start"
								testId="popover"
								variant="Icon"
							>
								<Paragraph>{popoverText}</Paragraph>
								<Paragraph mt=".5rem">{popoverText2}</Paragraph>
							</Popover>
						)}
					</Box>
				</FormLabel>
				<RadioGroup
					row={alignment !== "vertical" && !isSmallScreen}
					value={value}
					onChange={(e) => {
						updateFunc(e.target.value);
					}}
					sx={{ flexWrap: "nowrap" }}
				>
					<FormControlLabel
						value={true}
						control={
							<Radio
								sx={{
									"& .MuiSvgIcon-root": {
										fontSize: 28,
										color: value ? "#EA650D" : "",
									},
								}}
							/>
						}
						label={firstLabel}
						sx={{
							color: "#414141 !important",
							fontFamily: "NittiGroteskNN-TextMedium !important",
						}}
					/>
					<FormControlLabel
						value={false}
						control={
							<Radio
								sx={{
									"& .MuiSvgIcon-root": {
										fontSize: 28,
										color: value === false ? "#EA650D" : "",
									},
								}}
							/>
						}
						label={secondLabel}
						sx={{
							color: "#414141 !important",
							fontFamily: "NittiGroteskNN-TextMedium !important",
						}}
					/>
				</RadioGroup>
			</FormControl>
			{validationText && (
				<Alert
					icon={<Icon color="#EA650D" name="Alert" />}
					severity="warning"
					//  sx={{
					//     color: "#414141 !important",
					//     fontFamily: "NittiGroteskNN-TextMedium !important",
					//     fontSize: "1rem",
					//     fontWeight: "normal !important",
					//  }}
				>
					<Paragraph hasMargin={false}>{validationText}</Paragraph>
				</Alert>
			)}
		</div>
	) : null;
};

export default CustomRadioGroup;
