import React, { useEffect } from "react";
import CustomDateInput from "../../../../../../../Components/CustomInputs/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import {
   addErrorMessage,
   handleShowErrorMessage,
   removeErrorMessage,
} from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import useFetch from "../../../../../../../CustomHooks/useFetch";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const DisabilityDateOfClaimFromField: React.FC = () => {
   const dispatch = useDispatch();

   const DateOfClaimFromValueDisability = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 4
         )?.dateOfClaimFrom
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const token = useSelector((state: StoreType) => state.tokenState.token);
   const { personalId } = useSelector(
      (state: StoreType) => state.claimState.clientIdentification
   );

  //  const {
  //     sendRequest: sendRequestValidateDate,
  //     data: dataValidateDate,
  //     error: errorValidateDate,
  //     resetFetch: resetFetchValidateDate,
  //     fetchFired: fetchFiredValidateDate,
  //  } = useFetch();

  //  const callValidateDate = (date: string) => {
  //     sendRequestValidateDate({
  //        method: "POST",
  //        url:
  //           process.env.REACT_APP_BaseURL! +
  //           process.env.REACT_APP_ValidateDateForClaimType!,
  //        headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //        },
  //        data: {
  //           personId: personalId,
  //           injuryDateValue: date,
  //           typeOfClaimEnum: 4,
  //        },
  //     });
  //  };

  //  useEffect(() => {
  //     if (dataValidateDate === false) {
  //        dispatch(
  //           addErrorMessage({
  //              propertyId: "disability.DateOfClaimFrom",
  //              message: "Pojištění nebylo aktivní v uvedeném datu.",
  //           })
  //        );
  //     } else {
  //        dispatch(removeErrorMessage("disability.DateOfClaimFrom"));
  //     }
  //  }, [fetchFiredValidateDate]);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, "disability.DateOfClaimFrom");
   return (
      <CustomDateInput
         labelText="Kdy invalidita nastala"
         name="DateOfClaimFrom"
         value={DateOfClaimFromValueDisability || undefined}
         onChange={(e: any) => {
            if (Date.parse(e)) {
              //  callValidateDate(e);
            }
            dispatch(
               updateFirstStepDynamicFields({
                  value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
                  field: "dateOfClaimFrom",
                  typeOfClaimEnum: 4,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            "disability.DateOfClaimFrom",
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "disability",
            "DateOfClaimFrom",
            visibilityMatrixes
         )}
      />
   );
};

export default DisabilityDateOfClaimFromField;
