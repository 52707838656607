import icon from "../../../../../Assets/fourthStepDoctorIcon.png";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Heading,
   Icon,
   Link,
   Paragraph,
} from "@nn-design-system/react-component-library";
import { colourInteractionDefault } from "../../../../../Config/colors";
import { StoreType } from "../../../../../redux/store/store";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const DoctorSection = () => {
   const claimModel = useSelector((state: StoreType) => state.claimState);
   const { token } = useSelector((state: StoreType) => state.tokenState);

   const downloadFile = (fileId: string, fileName: string) => {
      fetch(
         process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_DownloadClaimDocument,
         {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMKey!,
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
               documentId: fileId,
               claimViewModelList: claimModel,
            }),
         }
      )
         .then((response) => response.blob())
         .then((blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
         });
   };

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isPermBeingReported = selectedTypeOfClaims.some(
      (claim: string) => claim === "permconsequences"
   );

   const heading = isPermBeingReported
      ? "Navštivte vašeho lékaře"
      : "Potvrzení od lékaře";

   const description = isPermBeingReported
      ? "Ke zpracování hlášení budeme potřebovat vyplněný formulář od vašeho lékaře. Ten si stáhnete níže. Pak už jen stačí, abyste nám jej poslali dle instrukcí, které vám shrneme v e-mailu."
      : "Pokud byste chtěl/a navíc doložit formulář Potvrzení lékaře, naleznete ho níže. Ke zpracování pojistné události ho ale nevyžadujeme.";

   return (
      <GridContainer>
         <WholeLineContainer>
            <Box
               sx={{
                  display: "grid",
                  marginTop: { xs: "2.5rem", sm: "0rem" },
                  gridTemplateColumns: "1fr auto",
                  gridTemplateAreas: {
                     xs: `
                  'heading icon'
                  'text text'
                  `,
                     sm: `
                  'heading heading'
                  'text icon'
                  `,
                  },
               }}
            >
               <Box sx={{ gridArea: "heading" }}>
                  <Heading variant="Medium" hasMargin={false}>
                     {heading}
                  </Heading>
               </Box>
               <Box sx={{ gridArea: "text" }}>
                  <Paragraph hasMargin={false} mt="1rem">
                     {description}
                  </Paragraph>
               </Box>
               <Box
                  sx={{
                     gridArea: "icon",
                     height: { xs: "2rem", sm: "4rem" },
                     transform: {
                        xs: "translateY(-1.5rem)",
                        sm: "translateY(-2rem)",
                     },
                     "& > *": {
                        width: { xs: "3rem", sm: "auto" },
                     },
                  }}
               >
                  <img src={icon}></img>
               </Box>
            </Box>
         </WholeLineContainer>
         <WholeLineContainer>
            <>
               <Heading variant="Small" hasMargin={false} mb="1rem">
                  Formulář ke stažení
               </Heading>
               <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
               >
                  {claimModel.reportModel.documentsToDownloads
                     .filter(
                        (document) => document.id === "8" || document.id === "9" || document.id === "11" || document.id === "12"
                     )
                     .map((document, index) => (
                        <Box
                           key={index}
                           sx={{
                              display: "flex",
                              gap: "1rem",
                              "& > a": {
                                 textDecoration: "underline !important",
                              },
                           }}
                        >
                           <Icon
                              color={colourInteractionDefault}
                              name="ArrowDownLine"
                              customSize="1.5rem"
                           />
                           <Link
                              onClick={() => {
                                 downloadFile(document.id, document.name);
                              }}
                              variant="SecondaryStrong"
                              text={document.name}
                           />
                        </Box>
                     ))}
               </Box>
            </>
         </WholeLineContainer>
      </GridContainer>
   );
};

export default DoctorSection;
