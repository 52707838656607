import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import {
   Autocomplete,
   Paragraph,
   Popover,
} from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import useGetListOfCountries from "../../../../../../CustomHooks/useGetListOfCountries";

const StateOfTaxResidanceField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "stateOfTaxResidance";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   const { listOfCountries } = useGetListOfCountries();

   useEffect(() => {
      if (fieldValue === "") {
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: "Česká republika",
               field: fieldNameLowerCase,
            })
         );
      }
   }, [dispatch]);

   if (
      !shouldRenderSpecField(
         "GeneralFields",
         fieldNameUpperCase,
         visibilityMatrixes
      )
   ) {
      return null;
   }

   return (
      <Box
         sx={{
            position: "relative",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
               {
                  borderColor: "black",
               },
            "& > div > div > div > div > div": {
               height: "3rem",
            },
         }}
         ref={ref}
      >
         <Autocomplete
            variant="Select"
            labelText="Stát daňové rezidence"
            placeholderText="Uveďte váš stát daňové rezidence"
            options={
               listOfCountries?.map((country: string) => {
                  return {
                     text: country,
                     value: country,
                  };
               }) ?? []
            }
            value={fieldValue as string}
            onChange={(e) => {
               dispatch(
                  updateSecondStepGeneralsNestedFields({
                     value: e.target.value.toString() as string,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            popoverElement={
               <Popover headingText="" placement="top-start">
                  <Paragraph hasMargin={false}>
                     Daňovým rezidentem je ten, kdo má v Česku úmysl trvale se
                     zde zdržovat. Může bydlet ve vlastním domě či bytě nebo v
                     pronájmu.
                  </Paragraph>
                  <Paragraph mt=".5rem">
                     Pokud má poplatník stálé bydlení ve dvou či více zemích,
                     rozhoduje o tom, zda je tuzemským rezidentem to, jak dlouho
                     v ČR pobývá. Musí se zdržovat v ČR minimálně 183 dní.
                  </Paragraph>
               </Popover>
            }
         />
      </Box>
   );
};

export default StateOfTaxResidanceField;
