type Char = string & { length: 1 };

const SanitizeNumber = (value: string | null | undefined) => {
   if (!value) return "";
   const validChars = "0123456789 ";
   const sanitizedValue = value
      .split("")
      .filter((char) => validChars.includes(char as Char))
      .join("");
   return sanitizedValue;
};

export default SanitizeNumber;
