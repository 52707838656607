import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Divider,
   FileEntryType,
   FileUpload,
   Heading,
   List,
   ListItem,
} from "@nn-design-system/react-component-library";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { Box } from "@mui/material";
import {
   addFile,
   removeFile as RemoveFileRedux,
} from "../../../../../redux/reducers/filesReducer";
import { useCallback } from "react";
import { NeededDocumentsInnerText } from "../../../../../Config/types/types";

const neededDocumentsInnerText: NeededDocumentsInnerText = {
   CopyOfMessageFromAmbulanceDoc: "Kopie lékařské zprávy",
   CopyOfLayoffReportMessageDoc: "Kopie propouštěcí zprávy",
   CopyOfDecisionInCapacityDoc:
      "Kopie rozhodnutí o dočasné pracovní neschopnosti, pokud jste OSVČ tak čestné prohlášení",
   CopyOfTerminationOfCapacityDoc: "Kopie ukončení pracovní neschopnosti",
   CopyOfDecisionChildTreatmentDoc: "Kopie rozhodnutí o ošetřování dítěte",
   CopyOfChildTreatmentTerminationDoc: "Kopie ukončení ošetřování dítěte",
   CopyOfDeclarationOfInvalidityDoc: "Kopie posudku OSSZ o přiznání invalidity",
   CopyOfCertificateOfDeathDoc: "Ověřená kopie úmrtního listu",
   CopyOfMedicalReportOfDeathDoc:
      "Kopie lékařské zprávy s uvedením příčiny úmrtí",
   CopyOfPoliceProtocolDoc: "Jiné (policejní protokol, fotodokumentace, apod.)",
};

const UploadFilesSection = () => {
   const uploadedFiles: any = useSelector(
      (state: StoreType) => state.filesState
   );

   const reguiredclassFields = useSelector(
      (state: StoreType) =>
         state.claimState.documentsUploadModel
            .visibilityOfDocumentsFromClientMatrix
   );
   const dispatch = useDispatch();

   const onChange = useCallback((_e: any, value: FileEntryType[]) => {
      value.forEach((fileEntry) => {
         dispatch(
            addFile({
               fileEntry: fileEntry,
            })
         );
      });
   }, []);

   const removeFile = (_e: any, specFile: File) => {
      dispatch(
         RemoveFileRedux({
            fileNamePlusSize: specFile.name + specFile.size,
         })
      );
   };

   const filterVisibilityOfDocumentsFromClientMatrix = () => {
      const propertyIds = new Set<keyof NeededDocumentsInnerText>();

      reguiredclassFields.forEach((claim) => {
         claim.classFields.forEach((field) => {
            if (field.isVisible) {
               propertyIds.add(field.propertyId);
            }
         });
      });

      return Array.from(propertyIds); // Convert Set back to array for output
   };

   return (
      <GridContainer>
         <WholeLineContainer>
            <>
               <Heading mb="0.5rem" hasMargin={false} variant={"Small"}>
                  Na základě údajů, které jste nám sdělil/a v hlášení, od vás
                  potřebujeme tyto dokumenty:
               </Heading>
               <Divider variant="Brand" mb="1rem"></Divider>
               {/* <List>
                  {reguiredclassFields.map((field) => {
                     if (!field.isVisible) return null;
                     return (
                        <ListItem
                           key={field.propertyId}
                           variant="AttentionalUnordered"
                        >
                           {
                              neededDocumentsInnerText[
                                 field.propertyId as keyof NeededDocumentsInnerText
                              ]
                           }
                        </ListItem>
                     );
                  })}
               </List> */}
               <Box sx={{ marginLeft: "1rem" }}>
                  <List>
                     {filterVisibilityOfDocumentsFromClientMatrix().map(
                        (field) => (
                           <ListItem key={field} variant="AttentionalUnordered">
                              {
                                 neededDocumentsInnerText[
                                    field as keyof NeededDocumentsInnerText
                                 ]
                              }
                           </ListItem>
                        )
                     )}
                  </List>
               </Box>
               <FileUpload
                  buttonText="Nahrát soubor"
                  canUploadMultiple
                  hasUploadButton
                  labelText={""}
                  onChange={(e, v) => {
                     onChange(e, v);
                  }}
                  onUploadStop={(e, v) => {
                     removeFile(e, v);
                  }}
                  subtext=""
                  value={uploadedFiles.map((file: any) => file.fileEntry)}
                  variant="Large"
               />
            </>
         </WholeLineContainer>
      </GridContainer>
   );
};

export default UploadFilesSection;
