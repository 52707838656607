import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
   Button,
   Heading,
   Paragraph,
   useDesignTokens,
} from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import { StoreType } from "../../redux/store/store";
import {
   resetClaimModel,
   setSelectedTypeClaims,
} from "../../redux/reducers/claimReducer";
import { useTheme } from "@mui/material/styles";
import { resetClaimFieldErrors } from "../../redux/reducers/claimFieldErrorsReducer";
import { resetVisilityMatrixes } from "../../redux/reducers/visibilityMatrixesReducer";
import ClaimCard from "../../Components/ClaimCard";
import { resetFiles } from "../../redux/reducers/filesReducer";
import {
   getFontFamily,
   getFontWeight,
} from "@nn-design-system/design-tokens/web";
import { claimCards, claimsType } from "../../Config/types/types";

const AnonymClaimSelectionPage: React.FC = () => {
   const [selectedClaims, setSelectedClaims] = useState<claimsType[]>([]);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   function toggleClaim(claim: claimsType) {
      const index = selectedClaims.indexOf(claim);
      if (index === -1) {
         setSelectedClaims([...selectedClaims, claim]);
      } else {
         setSelectedClaims([
            ...selectedClaims.slice(0, index),
            ...selectedClaims.slice(index + 1),
         ]);
      }
   }

   const { pages } = useSelector(
      (state: StoreType) => state.pagesToRenderState
   );

   const renderClaimCards = () => {
      const filteredCards = claimCards.filter((card) =>
         pages.includes(card.url)
      );

      return filteredCards.map((card) => (
         <ClaimCard
            key={card.url}
            icon={card.icon}
            url={card.url}
            heading={card.heading}
            toggleClaim={toggleClaim}
            selectedClaims={selectedClaims}
         />
      ));
   };

   const handleCreateClaim = () => {
      dispatch(setSelectedTypeClaims(selectedClaims));
      navigate(`/claimReport/anonym`);
   };

   useEffect(() => {
      dispatch(resetClaimModel());
      dispatch(resetClaimFieldErrors());
      dispatch(resetVisilityMatrixes());
      dispatch(resetFiles());
   }, [dispatch]);

   const fontFamilyTokens = useDesignTokens(getFontFamily);
   const fontWeightTokens = useDesignTokens(getFontWeight);

   const displaySelectedClaimsMessage = () => {
      return selectedClaims.length === 0
         ? "Pro pokračování prosím vyberte oblast hlášení"
         : selectedClaims.length > 4
         ? `Máte vybráno ${selectedClaims.length} událostí k hlášení`
         : `Máte ${
              selectedClaims.length === 1
                 ? `vybranou 1 událost`
                 : `vybrané ${selectedClaims.length} události`
           } k hlášení.`;
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            position: "relative",
            marginTop: "3rem",
         }}
      >
         <Box sx={{ width: "85%", zIndex: 50 }}>
            <Heading variant="Large" hasMargin={false} mt="1rem">
               Vyberte, co chcete hlásit
            </Heading>
            <Paragraph hasMargin={false} mt="1rem" mb="1rem">
               Pokud se chystáte hlásit více událostí, které spolu souvisí,
               stačí je vybrat.
               <Box
                  component={"span"}
                  sx={{
                     fontWeight: `${fontWeightTokens.strongImportance.get}`,
                     fontFamily: `${fontFamilyTokens.strongImportance.value}`,
                     marginLeft: "4px",
                  }}
               >
                  Spojíme je do jednoho hlášení.
               </Box>
            </Paragraph>
         </Box>
         {false && <Button>Pokračovat do hlášení</Button>}
         <Box
            sx={{
               display: "grid",
               gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr)) ",
               justifyContent: "center",
               alignContent: "center",
               gap: "2rem",
               width: "85%",
               gridAutoColumns: "1fr",
            }}
         >
            {renderClaimCards()}
         </Box>
         <Box
            sx={{
               width: "85%",
               display: "flex",
               justifyContent: isSmallScreen ? "center" : "flex-end",
               flexDirection: isSmallScreen ? "column" : "row",
               alignItems: "center",
               marginTop: "2rem",
               flexWrap: "wrap",
            }}
         >
            <Paragraph hasMargin={false} mr="1rem" variant="MediumCentered">
               {displaySelectedClaimsMessage()}
            </Paragraph>
            {selectedClaims.length ? (
               <Button
                  onClick={() => {
                     handleCreateClaim();
                  }}
                  mb="2rem"
               >
                  Přejít na hlášení
               </Button>
            ) : null}
         </Box>
      </Box>
   );
};

export default AnonymClaimSelectionPage;
