import { colourBorderSubtle } from "../../Config/colors";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
   Icon,
   Link,
   Paragraph,
} from "@nn-design-system/react-component-library";

const getFooterHeight = (isSmallScreen: boolean) => {
   return isSmallScreen ? 11 : 5;
};

const Footer = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const height = getFooterHeight(isSmallScreen);
   return (
      <Box
         component={"footer"}
         sx={{
            width: "100%",
            height: `5rem`,
            "@media (max-width: 600px)": {
               height: "11rem",
            },
            borderTop: `${colourBorderSubtle} 1px solid`,
            backgroundColor: "#F7F7F7"
         }}
      >
         <Box
            sx={{
               display: "flex",
               maxWidth: "70em",
               flexDirection: "column",
               alignContent: "center",
               margin: "auto",
               padding: "0 1.5rem",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  gap: "1rem",
                  margin: ".75rem 0 .5rem 0",
                  "& *:last-child": {
                     marginLeft: "auto",
                  },
                  "@media (max-width: 600px)": {
                     flexDirection: "column",
                     alignItems: "center",
                     gap: "0.5rem",
                     "& *:last-child": {
                        marginLeft: "0",
                     },
                  },
               }}
            >
               <Box
                  sx={{ display: "flex", alignItems: "center", gap: ".25rem" }}
               >
                  <Icon customSize="1.25rem" name="Envelope" />
                  <Box
                     sx={{
                        "& > *": { textDecoration: "underline !important" },
                     }}
                  >
                     <Paragraph variant="Small" hasMargin={false}>
                        dotazy@nn.cz
                     </Paragraph>
                  </Box>
               </Box>
               <Box
                  sx={{ display: "flex", alignItems: "center", gap: ".25rem" }}
               >
                  <Icon customSize="1.25rem" name="PhoneReceiver" />
                  <Box
                     sx={{
                        "& > *": { textDecoration: "underline !important" },
                     }}
                  >
                     <Paragraph variant="Small" hasMargin={false}>
                        244 090 800
                     </Paragraph>
                  </Box>
               </Box>
               <Box
                  sx={{ "& > *": { textDecoration: "underline !important" } }}
               >
                  <Link
                     href="https://www.nn.cz/"
                     text="Zpracování osobních údajů"
                     target="_blank"
                     variant="Secondary"
                  />
               </Box>
            </Box>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  "@media (max-width: 600px)": {
                     flexDirection: "column",
                     alignItems: "center",
                     gap: "0.5rem",
                     "& *:last-child": {
                        marginLeft: "0",
                     },
                  },
               }}
            >
               <Box
                  sx={{
                     "& > *": { opacity: ".8" },
                  }}
               >
                  <Paragraph hasMargin={false} variant="MediumCentered">
                     K dispozici každý všední den od 8 do 16 hodin.
                  </Paragraph>
               </Box>
               <Box
                  sx={{
                     "& > *": { opacity: ".8" },
                  }}
               >
                  <Paragraph hasMargin={false}>
                     © {new Date().getFullYear()} NN Životní pojišťovna
                  </Paragraph>
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

export { Footer, getFooterHeight };
