import React from "react";
import { useDispatch } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import { Checkbox } from "@nn-design-system/react-component-library";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const IsContactAddressDifferent: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "isContactAddressDifferent";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <div ref={ref}>
            <Checkbox
               labelText="Kontaktní adresa se liší od adresy trvalého pobytu."
               variant="Small"
               onChange={(e) => {
                  dispatch(
                     updateSecondStepGeneralsNestedFields({
                        value: !fieldValue,
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               dataState={fieldValue ? "Checked" : "NotChecked"}
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </div>
      </WholeLineContainer>
   );
};

export default IsContactAddressDifferent;
