import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { Navbar, getNavbarHeight } from "../Navbar/Navbar";
import { Footer, getFooterHeight } from "../Footer/Footer";
import { colourBorderSubtle } from "../../Config/colors";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import useFetch from "../../CustomHooks/useFetch";
import InfoBlock from "../InfoBlock";

type MainLayoutProps = {
   children: React.ReactNode;
};

const isEmbedded = false;

const MainLayout: React.FC<MainLayoutProps> = (props) => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const {
      sendRequest: reguestGetInfo,
      data: dataGetInfo,
      error: errorGetInfo,
      loading: loadingGetInfo,
      resetFetch: resetFetchGetInfo,
   } = useFetch();

   const callGetInfo = () => {
      reguestGetInfo({
         method: "GET",
         url: "https://serviceofflineprod.azurewebsites.net/api/GetServicesOffline?code=7xKrQfY9XHdM1v8NDvHUVKx5tBuEZHXnwDMGH15xVtW7JLqRu8iSgw==",
         headers: {
            "Content-Type": "application/json",
         },
      });
   };

   useEffect(() => {
      callGetInfo();
   }, []);

   if (isEmbedded) {
      return <>{props.children}</>;
   } else {
      return (
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               width: "100%",
            }}
         >
            <LoadingIcon />
            <InfoBlock data={dataGetInfo} />
            <Navbar />
            <Box
               sx={{
                  minHeight: `calc(100vh - ${
                     getFooterHeight(isSmallScreen) +
                     getNavbarHeight(isSmallScreen)
                  }rem)`,
                  width: "clamp(10rem, 100%, 70em) !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
               }}
            >
               {props.children}
            </Box>
            <Footer />
         </Box>
      );
   }
};

export default MainLayout;
