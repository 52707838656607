import { createSlice } from "@reduxjs/toolkit";

export type loadingInitialState = {
   isLoading: boolean;
};

const initialState: loadingInitialState = {
   isLoading: false,
};

const LoadingSlice = createSlice({
   name: "loading",
   initialState,
   reducers: {
      setLoadingOn: (state) => {
         state.isLoading = true;
      },
      setLoadingOff: (state) => {
         state.isLoading = false;
      },
   },
});

export const { setLoadingOn, setLoadingOff } = LoadingSlice.actions;
export default LoadingSlice.reducer;
