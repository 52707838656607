import { Box, Chip } from "@mui/material";
import { Heading } from "@nn-design-system/react-component-library";
import React from "react";
import CustomChip from "./CustomChip";
import { claimCards } from "../../Config/types/types";

export type Data = {
   injury: string | number;
   hospitalization: string | number;
   incapacity: string | number;
   childcare: string | number;
   disability: string | number;
   seriousdiseases: string | number;
   death: string | number;
   permconsequences: string | number;
};

type DisplayCurrentClaimsProps = {
   claims: string[];
};

export const data = {
   injury: "Úraz",
   hospitalization: "Hospitalizace",
   incapacity: "Pracovní neschopnost",
   childcare: "Ošetřovné dítěte",
   disability: "Invalidita",
   seriousdiseases: "Závažná onemocnění",
   death: "Úmrtí",
   permconsequences: "Trvalé následky",
};

const DisplayCurrentClaims: React.FC<DisplayCurrentClaimsProps> = ({
   claims,
}) => {
   return (
      <Box
         sx={{
            backgroundColor: "#FFF7EE",
            // width: "200%",
            // transform: "translateX(-20%)",
            position: "relative",
            overflow: "hidden",
         }}
      >
         <Box
            sx={{
               display: "flex",
               flexWrap: "wrap",
               padding: "1.5rem",
               alignItems: "center",
               gap: "1rem",
               position: "relative",
               zIndex: "4",
               //  transform: "translateX(20%)",
            }}
         >
            <Heading hasMargin={false} variant="Small">
               Hlásíte tyto pojistné události:
            </Heading>
            {claims.map((claim, index) => (
               <CustomChip
                  key={index}
                  label={data[claim as keyof Data]}
                  icon={claimCards.find((card) => card.url === claim)!.icon}
               />
            ))}
         </Box>
         <Box
            sx={{
               position: "absolute",
               height: "100%",
               width: "200%",
               backgroundColor: "#FFF7EE",
               top: "0",
               zIndex: "2",
               left: "50%",
               transform: "translate(-50%)",
            }}
         />
      </Box>
   );
};

export default DisplayCurrentClaims;
