import React from "react";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { Divider, Heading } from "@nn-design-system/react-component-library";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import FirstNameField from "./PersonalFields/FirstNameField";
import LastNameField from "./PersonalFields/LastNameField";
import PlaceOfBirthField from "./PersonalFields/PlaceOfBirthField";
import IsForeignerField from "./PersonalFields/IsForeignerField";
import PersonalIDField from "./PersonalFields/PersonalIdField";
import StateOfTaxResidanceField from "./PersonalFields/StateOfTaxResidanceField";
import CodeOfHealthInsuranceField from "./PersonalFields/CodeOfHealthInsuranceField";
import IsAnotherInsuranceField from "./PersonalFields/IsAnotherInsuranceField";
import NameOfAnotherInsuriensField from "./PersonalFields/NameOfAnotherInsuriensField";
import CitizenshipField from "./PersonalFields/CitizenshipField";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import IsStudentField from "./PersonalFields/IsStudentField";

const PersonalSection: React.FC = ({}) => {
   const { isThirdPerson } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isDeathClaim = selectedTypeOfClaims.some((claim) => claim === "death");

   return (
      <GridContainer>
         <WholeLineContainer>
            <>
               <Heading
                  variant={"Medium"}
                  hasMargin={false}
                  mb=".5rem"
                  mt="1rem"
               >
                  {isDeathClaim
                     ? "Osobní údaje zesnulé osoby"
                     : isThirdPerson
                     ? "Osobní údaje pojištěné osoby"
                     : "Vaše osobní údaje"}
               </Heading>
               <Divider variant="Brand"></Divider>
            </>
         </WholeLineContainer>
         <FirstNameField />
         <LastNameField />
         <PlaceOfBirthField />

         <PersonalIDField />
         <IsForeignerField />
         <CitizenshipField />
         <StateOfTaxResidanceField />
         <CodeOfHealthInsuranceField />
         <IsAnotherInsuranceField />
         <NameOfAnotherInsuriensField />
         <IsStudentField />
      </GridContainer>
   );
};

export default PersonalSection;
