import React from "react";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomRadioGroup from "../../../../../../../Components/CustomInputs/CustomRadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const IsInjuryDueCarAccidentField: React.FC = () => {
   const fieldNameLowerCase = "isInjuryDueCarAccident";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const fieldHeading = () => {
      if (
         selectedTypeOfClaims.some((claim: string) => claim === "injury") &&
         selectedTypeOfClaims.some((claim: string) => claim === "death")
      ) {
         return "Došlo k úrazu/úmrtí při dopravní nehodě?";
      } else if (
         selectedTypeOfClaims.some((claim: string) => claim === "injury") ||
         selectedTypeOfClaims.some(
            (claim: string) => claim === "permconsequences"
         )
      ) {
         return "Došlo k úrazu při dopravní nehodě?";
      } else {
         return "Došlo k úmrtí při dopravní nehodě?";
      }
   };
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <CustomRadioGroup
            heading={fieldHeading()}
            firstLabel="Ano"
            secondLabel="Ne"
            value={fieldValue}
            updateFunc={(e: any) => {
               dispatch(
                  updateFirstStepGeneralFields({
                     value: e === "true" ? true : false,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default IsInjuryDueCarAccidentField;
