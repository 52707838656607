import { useState } from "react";
import SetFormHandler from "../Utils/SetFormHandler/SetFormHandler";

export default function useCustomState<T extends Record<string, any>>(
   initialState: T
) {
   const [state, setState] = useState<T>(initialState);

   const [stateValidation, setStateValidation] = useState<T>({
      ...initialState,
   });

   const { setFormHandler: setStateHandler } = SetFormHandler<T>({
      formInfo: state,
      setFormInfo: setState,
   });

   const resetState = () => {
      setState(initialState);
   };

   const resetStateValidation = () => {
      setStateValidation({ ...initialState });
   };

   function updateStateValidation<K extends keyof T>(key: K, value: T[K]) {
      setStateValidation((prevState) => ({
         ...prevState,
         [key]: value,
      }));
   }

   return {
      state,
      resetState,
      stateValidation,
      resetStateValidation,
      setStateHandler,
      updateStateValidation,
   };
}
