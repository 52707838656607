import { createSlice } from "@reduxjs/toolkit";
import { claimsType } from "../../Config/types/types";

export type PagesToRenderInitialState = {
   pages: claimsType[];
};

const initialState: PagesToRenderInitialState = {
   pages: [],
};

type action = {
   payload: claimsType[];
   type: string;
};

const PagesToRenderSlice = createSlice({
   name: "pagesToRender",
   initialState,
   reducers: {
      setPagesToRender: (state, action: action) => {
         state.pages = action.payload;
      },
   },
});

export const { setPagesToRender } = PagesToRenderSlice.actions;
export default PagesToRenderSlice.reducer;
