import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

type SingleLineContainerProps = {
   children: React.ReactElement;
   dependencyValue?: boolean | null;
};

const SingleLineContainer: React.FC<SingleLineContainerProps> = ({
   children,
   dependencyValue = true,
}) => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   if (!dependencyValue) {
      return null;
   }
   return (
      <>
         {children}
         {!isSmallScreen && <Box></Box>}
      </>
   );
};

export default SingleLineContainer;
