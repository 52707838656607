import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import SanitizeBirthNumber from "../../../../../../Utils/SanitizeInputs/SanitizeBirthNumber";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import { checkLength } from "../../../../../../Utils/CheckLength";
import { StoreType } from "../../../../../../redux/store/store";

const PersonalIDField: React.FC = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "personalId";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   const authStatus = useSelector(
      (state: StoreType) => state.authState.userAuthenticationStatus
   );

   const { claimForPersonId } = useSelector(
      (state: StoreType) => state.claimState
   );

   useEffect(() => {
      if (authStatus === "anonym") {
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: claimForPersonId,
               field: fieldNameLowerCase,
            })
         );
      }
   }, [authStatus, dispatch, claimForPersonId]);

   return (
      <CustomInput
         dataState={authStatus === "anonym" ? "ReadOnly" : undefined}
         labelText="Rodné číslo"
         placeholderText="Zadejte rodné číslo bez lomítka"
         value={(fieldValue as string) || ""}
         onChange={(e) => {
            dispatch(
               updateSecondStepGeneralsNestedFields({
                  value: SanitizeBirthNumber({
                     value: checkLength(e.target.value, 10),
                  }),
                  field: fieldNameLowerCase,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            fieldNameUpperCase,
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
         inputRef={ref}
      />
   );
};

export default PersonalIDField;
