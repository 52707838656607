import { Box } from "@mui/material";
import {
   DatepickerInput,
   TextInput,
} from "@nn-design-system/react-component-library";
import React from "react";
import { colourTextDefault } from "../../Config/colors";

type CustomDateInputProps = {
   labelText: string;
   value: Date | undefined;
   subtext?: string;
   onChange: any;
   validationText: string;
   name?: string;
   placeholderText?: string;
   dependencyValue?: boolean | null;
   onBlur?: any;
   inputRef?: React.RefObject<HTMLDivElement>;
};

const CustomDateInput: React.FC<CustomDateInputProps> = ({
   labelText,
   value,
   onChange,
   validationText,
   name,
   subtext,
   placeholderText = "DD.MM.RRRR",
   dependencyValue = true,
   onBlur,
   inputRef,
}) => {
   return dependencyValue ? (
      <Box
         sx={{
            "& > div > div > div > div > div": {
               outlineColor: `${colourTextDefault} !important`,
               "&:focus": {},
            },
         }}
      >
         <div ref={inputRef}>
            <DatepickerInput
               subtext={subtext}
               name={name}
               labelText={labelText}
               value={value}
               onChange={onChange}
               validationText={validationText}
               placeholderText={placeholderText}
               onBlur={onBlur}
            />
         </div>
      </Box>
   ) : null;
};

export default CustomDateInput;
