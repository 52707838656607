import { Box, Typography } from "@mui/material";
import React from "react";

type NNHeadingProps = {
	text: string;
	isBig?: boolean;
	align?: "inherit" | "left" | "center" | "right" | "justify";
	margin?: string;
};

const NNHeading: React.FC<NNHeadingProps> = ({
	text,
	isBig = true,
	align = "left",
	margin = "0rem 0 .5rem 0 !important",
}) => {
	const sxLight = {
		margin: ".5rem 0 1rem 0 !important",
		color: "#ee7f00",
		fontSize: isBig ? "2rem" : "1.4rem",
	};
	const sxMedium = {
		color: "#ea650d",
		fontSize: isBig ? "2rem" : "1.4rem",
	};
	const sxDark = { color: "#e64415", fontSize: isBig ? "2rem" : "1.4rem" };

	const words = text.split(" ");
	const wordClassNames = [sxLight, sxMedium, sxDark, sxMedium, sxLight];

	return (
		<Typography
			sx={{
				fontFamily: "NittiGroteskNN-TextMedium",
				margin: margin,
				color: "#ee7f00",
				fontSize: isBig ? "2rem" : "1.4rem",
			}}
			align={align}
		>
			{words.map((word, i) => {
				const string = i ? ` ${word}` : word;
				const wordClassName =
					wordClassNames[words.length > 5 ? Math.floor(i / 2) : i];
				return (
					<Box component="span" key={word} sx={wordClassName}>
						{string}
					</Box>
				);
			})}
		</Typography>
	);
};

export default NNHeading;
