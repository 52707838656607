import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import SectionTemplate from "../../Template/SectionTemplate";
import PermConsequencesDateOfClaimFromField from "./PermConsequencesFields/PermConsequencesDateOfClaimFromField";
import PermConsequencesDateOfClaimToField from "./PermConsequencesFields/PermConsequencesDateOfClaimToField";
import PermConsequencesTimeOfClaimField from "./PermConsequencesFields/PermConsequencesTimeOfClaimField";
import PermConsequencesAdditionalInjuryDescriptionField from "./PermConsequencesFields/PermConsequencesAdditionalInjuryDescriptionField";

const PermConsequences: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("permconsequences", visibilityMatrixes))
      return null;

   return (
      <SectionTemplate heading="Trvalé následky" iconName="PersonBrokenArm">
         <PermConsequencesDateOfClaimFromField />
         <PermConsequencesDateOfClaimToField />
         <PermConsequencesTimeOfClaimField />
         <PermConsequencesAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default PermConsequences;
