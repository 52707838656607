import React from "react";
import CustomTextarea from "../../../../../../../Components/CustomInputs/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const ChildCareAdditionalInjuryDescriptionField: React.FC = () => {
   const dispatch = useDispatch();

   const AdditionalInjuryDescriptionValueChildcare = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 3
         )?.additionalInjuryDescription
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, "childcare.AdditionalInjuryDescription");
   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "childcare",
            "AdditionalInjuryDescription",
            visibilityMatrixes
         )}
      >
         <CustomTextarea
            labelText="Důvod ošetřování dítěte"
            placeholderText="Uveďte důvod ošetřování dítěte"
            name="AdditionalInjuryDescription"
            value={AdditionalInjuryDescriptionValueChildcare || ""}
            onChange={(e) => {
               dispatch(
                  updateFirstStepDynamicFields({
                     value: e.target.value.slice(0, 240),
                     field: "additionalInjuryDescription",
                     typeOfClaimEnum: 3,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               "childcare.AdditionalInjuryDescription",
               claimFieldErrorsMessages
            )}
            inputRef={ref}
            maxCharacters="240"
            highlightMaxCharacters={
               AdditionalInjuryDescriptionValueChildcare?.length === 240
            }
         />
      </WholeLineContainer>
   );
};

export default ChildCareAdditionalInjuryDescriptionField;
