import React from "react";
import { Button } from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setShowCancelClaimModal } from "../../redux/reducers/authReducer";
import { ClaimType } from "../../redux/reducers/claimReducer";

type BackNextButtonsProps = {
   previousStepHandler: () => void;
   nextStepHandler: () => void;
   currentStep: number;
   dataCreateClaim: ClaimType;
};

const BackNextButtons: React.FC<BackNextButtonsProps> = ({
   previousStepHandler,
   nextStepHandler,
   currentStep,
   dataCreateClaim,
}) => {
   const isFirstStep = currentStep === 0;

   const dispatch = useDispatch();

   if (!dataCreateClaim) return null;

   return (
      <Box
         sx={{
            margin: "2rem 1rem 2rem 1rem",
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",
            "@media (max-width: 500px)": {
               flexDirection: "column-reverse",
               gap: ".5rem",
               justifyContent: "center",
               alignItems: "center",
            },
         }}
      >
         <Button
            iconProps={{
               name: "ArrowLeft",
               placement: "Left",
            }}
            variant="Secondary"
            onClick={() => {
               isFirstStep
                  ? dispatch(setShowCancelClaimModal(true))
                  : previousStepHandler();
            }}
         >
            Předchozí krok
         </Button>
         <Button
            iconProps={{
               name: "ArrowRight",
               placement: "Right",
            }}
            onClick={nextStepHandler}
         >
            {currentStep === 4
               ? "Nahlásit pojistnou událost"
               : "Pokračovat na další krok"}
         </Button>
      </Box>
   );
};

export default BackNextButtons;
