import { Box } from "@mui/material";
import {
   Card,
   Divider,
   Heading,
   Icon,
   Paragraph,
} from "@nn-design-system/react-component-library";
import { ReactNode, useRef, useState } from "react";

interface CollapsableProps {
   heading: string;
   subHeading?: string;
   text?: string;
   children?: ReactNode;
   padding?: string;
   defaultClosed?: boolean;
   isFirst?: boolean;
}

export const Collapsable: React.FC<CollapsableProps> = ({
   heading,
   subHeading,
   text,
   children,
   padding,
   defaultClosed = true,
   isFirst,
}) => {
   const [isClosed, setIsClosed] = useState(defaultClosed);
   const contentRef = useRef<HTMLDivElement>(null);

   const toggleCollapsible = () => {
      setIsClosed(!isClosed);
   };

   return (
      <>
         {isFirst && <Divider />}
         <Box
            onClick={toggleCollapsible}
            sx={{
               cursor: "pointer",
               display: "flex",
               alignItems: "center",
               gap: "1rem",
               margin: ".8rem 0 .75rem 0",
            }}
         >
            <Box
               sx={{
                  transition: "transform 300ms ease",
                  transform: `rotate(${isClosed ? "0" : "-180deg"})`,
               }}
            >
               <Icon name="ChevronDown" customSize="1.25rem" color="#EA650D" />
            </Box>
            <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
               <Heading hasMargin={false} variant={"Small"}>
                  {heading}
               </Heading>
               <Paragraph>{subHeading}</Paragraph>
            </Box>
         </Box>
         <Box
            ref={contentRef}
            sx={{
               maxHeight: isClosed
                  ? "0 !important"
                  : contentRef?.current?.scrollHeight + "px !important",
               overflow: "hidden",
               transition: "max-height 0.2s ease-out",
            }}
         >
            <Box sx={{ padding: padding }}>
               <Paragraph mt="1rem" hasMargin={false}>
                  {text}
               </Paragraph>
               {children}
            </Box>
         </Box>
         <Divider></Divider>
      </>
   );
};
