import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomTextarea from "../../../../../../../Components/CustomInputs/CustomTextarea";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";

const PermConsequencesAdditionalInjuryDescriptionField: React.FC = () => {
   const dispatch = useDispatch();

   const AdditionalInjuryDescriptionValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 7
         )?.additionalInjuryDescription
   );
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "permconsequences",
            "AdditionalInjuryDescription",
            visibilityMatrixes
         )}
      >
         <CustomTextarea
            labelText="Podrobný popis vzniku úrazu, či nemoci"
            name="AdditionalInjuryDescription"
            value={AdditionalInjuryDescriptionValue || ""}
            onChange={(e) => {
               dispatch(
                  updateFirstStepDynamicFields({
                     value: e.target.value.slice(0, 240),
                     field: "dateOfClaimTo",
                     typeOfClaimEnum: 7,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               "permconsequences.AdditionalInjuryDescription",
               claimFieldErrorsMessages
            )}
            placeholderText="Popište co nejpodrobněji"
            maxCharacters="240"
            highlightMaxCharacters={
               AdditionalInjuryDescriptionValue?.length === 240
            }
         />
      </WholeLineContainer>
   );
};

export default PermConsequencesAdditionalInjuryDescriptionField;
