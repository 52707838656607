import React from "react";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomTextarea from "../../../../../../../Components/CustomInputs/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import { Paragraph } from "@nn-design-system/react-component-library";
import CustomInput from "../../../../../../../Components/CustomInputs/CustomInput";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const NotaryNameAndAddressHandleEstateField: React.FC = () => {
   const fieldNameLowerCase = "notaryNameAndAddressHandleEstate";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);
   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <>
            <CustomInput
               labelText="Jméno a kontaktní údaje na notáře, který vyřizuje pozůstalost"
               placeholderText="Uveďte notáře a jeho addresu"
               value={fieldValue || ""}
               onChange={(e: any) => {
                  dispatch(
                     updateFirstStepGeneralFields({
                        value: e.target.value,
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
               maxCharacters="100"
               inputRef={ref}
            />
            <Paragraph mt="1rem" variant="Small" hasMargin={false}>
               V případě, že zatím nemáte přiděleného notáře, nevadí. Doplníte
               nám jej později.
            </Paragraph>
         </>
      </WholeLineContainer>
   );
};

export default NotaryNameAndAddressHandleEstateField;
