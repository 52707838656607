import { Box } from "@mui/material";
import {
   Button,
   Heading,
   Modal,
} from "@nn-design-system/react-component-library";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { setShowCancelClaimModal } from "../../redux/reducers/authReducer";

type ClaimCancelModalProps = {
   isModalOpen: boolean;
};

const ClaimCancelModal: React.FC<ClaimCancelModalProps> = ({ isModalOpen }) => {
   const navigate = useNavigate();

   const userAuthenticationStatus = useSelector(
      (state: StoreType) => state.authState.userAuthenticationStatus
   );

   const dispatch = useDispatch();

   const handleRedirect = () => {
      if (userAuthenticationStatus === "authorized") {
         navigate("/claimSelection/authorized");
      } else {
         navigate("/claimSelection/anonym");
      }
      dispatch(setShowCancelClaimModal(false));
   };

   return (
      <Modal
         variant="Elevated"
         isOpen={isModalOpen}
         onClose={() => {
            dispatch(setShowCancelClaimModal(false));
         }}
         height="auto"
         width="clamp(5rem, 100vw, 35rem)"
         hasBackdrop
      >
         <Box sx={{ padding: "1rem" }}>
            <Heading hasMargin={false} mt="1rem" mb="2rem" variant={"Medium"}>
               Opravdu chcete zrušit hlášení?
            </Heading>
            <Box
               sx={{
                  display: "flex",
                  gap: "1rem",
                  "@media (max-width: 580px)": { flexDirection: "column" },
               }}
            >
               <Button
                  variant="PrimaryMediumFullWidth"
                  onClick={() => {
                     dispatch(setShowCancelClaimModal(false));
                  }}
               >
                  Chci pokračovat v hlášení
               </Button>
               <Button
                  variant="SecondaryMediumFullWidth"
                  onClick={handleRedirect}
               >
                  Zrušit hlášení
               </Button>
            </Box>
         </Box>
      </Modal>
   );
};

export default ClaimCancelModal;
