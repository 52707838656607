import { useEffect, useState } from "react";
import {
   Button,
   Card,
   Heading,
   RadioButton,
   RadioButtonGroup,
} from "@nn-design-system/react-component-library";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import {
   finishMethodsType,
   updateFifthStepFinishFields,
} from "../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../redux/reducers/claimFieldErrorsReducer";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import ErrorMessage from "../../../../../Components/ErrorMessage/ErrorMessage";

const FinishClaimSection = () => {
   const [areOptionsOpen, setAreOptionsOpen] = useState<boolean>(false);
   const dispatch = useDispatch();

   const finishMethods = useSelector(
      (state: StoreType) => state.claimState.reportModel.claimFinishMethods
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const isFinishedBySMS = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.finishedClaimByMobileIdentification
   );

   const isFinishedByDocumentUpload = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.finishedClaimBySendDocumentOnline
   );

   const isFinishedBy2DSignature = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.finishedClaimBy2DSignature
   );

   useEffect(() => {
      if (isFinishedByDocumentUpload || isFinishedBy2DSignature) {
         setAreOptionsOpen(true);
      }
   }, []);

   const phoneNumber = useSelector(
      (state: StoreType) => state.claimState.personModel.phoneNumber
   );

   if (finishMethods.length === 0) {
      return null;
   }

   return (
      <Card variant="Attentional" mt="2rem">
         <GridContainer>
            <WholeLineContainer>
               <>
                  <Heading hasMargin={false} mb="1rem" variant="Large">
                     Už zbývá jen váš podpis. Jak na to?
                  </Heading>
                  <RadioButtonGroup
                     labelText=""
                     onChange={(e) => {
                        dispatch(
                           updateFifthStepFinishFields({
                              value: e.target.value as finishMethodsType,
                           })
                        );
                     }}
                     subtext=""
                     value={isFinishedBySMS ? "mobileIdentification" : ""}
                     variant="Default"
                  >
                     {finishMethods.includes("mobileIdentification") && (
                        <RadioButton
                           labelText="Podepsat pomocí SMS"
                           subtext={
                              "Potvrzovací kód zašleme na telefon pojištěné osoby: " +
                              phoneNumber
                           }
                           value="mobileIdentification"
                        />
                     )}
                  </RadioButtonGroup>
               </>
            </WholeLineContainer>
            <WholeLineContainer>
               <Button
                  variant="PlainSmall"
                  onClick={() => {
                     setAreOptionsOpen(!areOptionsOpen);
                  }}
                  iconProps={{
                     name: areOptionsOpen ? "ChevronUp" : "ChevronDown",
                     placement: "Right",
                  }}
               >
                  {areOptionsOpen
                     ? "Skrýt další možnosti"
                     : "Zvolit jiný způsob"}
               </Button>
            </WholeLineContainer>
            <WholeLineContainer dependencyValue={areOptionsOpen}>
               <RadioButtonGroup
                  labelText=""
                  onChange={(e) => {
                     dispatch(
                        updateFifthStepFinishFields({
                           value: e.target.value as finishMethodsType,
                        })
                     );
                  }}
                  subtext=""
                  value={
                     isFinishedByDocumentUpload
                        ? "senddocumentonline"
                        : isFinishedBy2DSignature
                        ? "signature2d"
                        : undefined
                  }
                  variant="Default"
               >
                  {finishMethods.includes("signature2d") && (
                     <RadioButton
                        labelText="Podepsat elektronicky (doporučujeme použít mobil nebo jiné dotykové zařízení)"
                        subtext="Přesměrujeme vás do zabezpečeného portálu, kde si dokument projdete a elektronicky jej vlastnoručně podepíšete."
                        value="signature2d"
                     />
                  )}
                  {finishMethods.includes("senddocumentonline") && (
                     <RadioButton
                        labelText="Podepsat vlastnoručně"
                        subtext="Dokument vytisknete, vlastnoručně podepíšete a oskenovaný nahrajete do našeho systému."
                        value="senddocumentonline"
                     />
                  )}
               </RadioButtonGroup>
            </WholeLineContainer>
            <WholeLineContainer
               dependencyValue={
                  handleShowErrorMessage("", claimFieldErrorsMessages)
                     .length === 0
               }
            >
               <ErrorMessage
                  errorMessage={handleShowErrorMessage(
                     "FinishedClaimBy2DSignature",
                     claimFieldErrorsMessages
                  )}
               />
            </WholeLineContainer>
         </GridContainer>
      </Card>
   );
};

export default FinishClaimSection;
