import { NameType } from "@nn-design-system/react-component-library/dist/components/Icon/generated";

export type FirstStepFormType = {};

export type SecondStepFormType = {
   ContractId: string;
   FirstName: string;
   SecondName: string;
   LastName: string;
   PlaceOfBirth: string;
   Nationality: string;
   PersonalId: string;
   StateOfTaxResidance: string;
   CodeOfTaxResidance: string;
   IsAnotherInsurance: string;
   NameOfAnotherInsuries: string;
   Adress: string;
   City: string;
   PostCode: string;
   PhoneNumber: string;
   Email: string;
   IsEmployed?: boolean;
   Profession: string;
   EmployerNameAndAddress: string;
   EmployeeFrom: string;
   EmployeeTo: string;
   IsEnterprenuer: boolean;
   BusinessDescription: string;
   BusinessFromDate: string;
   UnemployedFrom: string;
   IsHouseWife: boolean;
   IsPensioner: boolean;
   IsStudent: boolean;
   OtherJob: string;
   ProcessingByMailAgreement: boolean;
};

export type ThirdStepFormType = {
   dateOfFirstTreatment: string;
   timeOfFirstTreatment: string;
   nameOfDoctorAndAdressOfFirstTreatmentFacility: string;
   nameOfDoctorAndAdressOfLaterTreatmentFacility: string;
};

export type FourthStepFormType = {
   methodOfPayments: string;
   variableSymbol: string;
   adress: string;
};

export type claimsType =
   | "injury"
   | "hospitalization"
   | "incapacity"
   | "childcare"
   | "disability"
   | "seriousdiseases"
   | "death"
   | "permconsequences";

type error = {
   errorCode: string | null;
   errorMessage: string | null;
   errorException: string | null;
   errorDetail: string | null;
   errorParameterName: string | null;
};

export type DynamicFields =
   | "dateOfClaimFrom"
   | "dateOfClaimTo"
   | "timeOfClaim"
   | "additionalInjuryDescription";

export const claimCards: {
   icon: NameType;
   url: claimsType;
   heading: string;
}[] = [
   {
      icon: "PersonBrokenArm",
      url: "injury",
      heading: "Úraz",
   },
   {
      icon: "FirstAid",
      url: "hospitalization",
      heading: "Hospitalizace",
   },
   {
      icon: "Calendar",
      url: "incapacity",
      heading: "Pracovní neschopnost",
   },
   {
      icon: "UmbrellaKids",
      url: "childcare",
      heading: "Ošetřovné dítěte",
   },
   {
      icon: "Wheelchair",
      url: "disability",
      heading: "Invalidita a dlouhodobá péče",
   },
   {
      icon: "HealthCross",
      url: "seriousdiseases",
      heading: "Závažná onemocnění",
   },
   {
      icon: "Angel",
      url: "death",
      heading: "Úmrtí",
   },
   {
      icon: "MedkitCalendar",
      url: "permconsequences",
      heading: "Trvalé následky úrazu",
   },
];

export type documentText =
   | "Kopie lékařské zprávy"
   | "Kopie propouštěcí zprávy"
   | "Kopie rozhodnutí o dočasné pracovní neschopnosti, pokud jste OSVČ tak čestné prohlášení"
   | "Kopie ukončení pracovní neschopnosti"
   | "Kopie rozhodnutí o ošetřování dítěte"
   | "Kopie ukončení ošetřování dítěte"
   | "Kopie posudku OSSZ o přiznání invalidity"
   | "Ověřená kopie úmrtního listu"
   | "Kopie lékařské zprávy s uvedením příčiny úmrtí"
   | "Jiné (policejní protokol, fotodokumentace, apod.)";

export type documentName =
   | "CopyOfMessageFromAmbulanceDoc"
   | "CopyOfLayoffReportMessageDoc"
   | "CopyOfDecisionInCapacityDoc"
   | "CopyOfTerminationOfCapacityDoc"
   | "CopyOfDecisionChildTreatmentDoc"
   | "CopyOfChildTreatmentTerminationDoc"
   | "CopyOfDeclarationOfInvalidityDoc"
   | "CopyOfCertificateOfDeathDoc"
   | "CopyOfMedicalReportOfDeathDoc"
   | "CopyOfPoliceProtocolDoc";

export type NeededDocumentsInnerText = {
   CopyOfMessageFromAmbulanceDoc: documentText;
   CopyOfLayoffReportMessageDoc: documentText;
   CopyOfDecisionInCapacityDoc: documentText;
   CopyOfTerminationOfCapacityDoc: documentText;
   CopyOfDecisionChildTreatmentDoc: documentText;
   CopyOfChildTreatmentTerminationDoc: documentText;
   CopyOfDeclarationOfInvalidityDoc: documentText;
   CopyOfCertificateOfDeathDoc: documentText;
   CopyOfMedicalReportOfDeathDoc: documentText;
   CopyOfPoliceProtocolDoc: documentText;
};
