import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { Box } from "@mui/material";
import PaymentAdressField from "./BankSectionFields/PaymentAdressField";
import VariableNumberField from "./BankSectionFields/VariableNumberField";
import BankCodeField from "./BankSectionFields/BankCodeField";
import BankAccountNumberField from "./BankSectionFields/BankAccountNumberField";
import NeedAdvancePaymentField from "./BankSectionFields/NeedAdvancePayment";
import PrefixBankAccountNumberField from "./BankSectionFields/PrefixBankAccountNumberField";
import ErrorMessage from "../../../../../Components/ErrorMessage/ErrorMessage";
import { handleShowErrorMessage } from "../../../../../redux/reducers/claimFieldErrorsReducer";
import { Divider, Heading } from "@nn-design-system/react-component-library";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";

const PaymentBankFields = () => {
   const isPayByBankAccount = useSelector(
      (state: StoreType) => state.claimState.paymentModel.isPayByBankAccount
   );
   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const renderErrorMessage = () => {
      if (
         handleShowErrorMessage(
            "BankAccountNumber",
            claimFieldErrorsMessages
         ) &&
         handleShowErrorMessage("BankCode", claimFieldErrorsMessages)
      ) {
         return (
            <Box sx={{ gridColumn: "1/-1" }}>
               <ErrorMessage
                  errorMessage={
                     "Pro správné zpracování Vašeho hlášení od Vás potřebujeme vyplnit vyznačené pole."
                  }
               />
            </Box>
         );
      } else if (
         handleShowErrorMessage("BankAccountNumber", claimFieldErrorsMessages)
      ) {
         if (
            handleShowErrorMessage(
               "BankAccountNumber",
               claimFieldErrorsMessages
            ) ===
            "Abychom mohli vaši odpověď zaznamenat, potřebujeme alespoň 4 znaků"
         ) {
            return (
               <Box sx={{ gridColumn: "1/-1" }}>
                  <ErrorMessage
                     errorMessage={
                        "Abychom mohli vaši odpověď zaznamenat, potřebujeme alespoň 4 znaky."
                     }
                  />
               </Box>
            );
         } else {
            return (
               <Box sx={{ gridColumn: "1/-1" }}>
                  <ErrorMessage
                     errorMessage={handleShowErrorMessage(
                        "BankAccountNumber",
                        claimFieldErrorsMessages
                     )}
                  />
               </Box>
            );
         }
      } else {
         return (
            <Box sx={{ gridColumn: "1/-1" }}>
               <ErrorMessage
                  errorMessage={handleShowErrorMessage(
                     "BankCode",
                     claimFieldErrorsMessages
                  )}
               />
            </Box>
         );
      }
   };

   return (
      <>
         {isPayByBankAccount ? (
            <Box
               sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(3, auto)",
                  gridTemplateColumns: "25% 40% 25%",
                  gridColumnGap: "1rem",
                  gridRowGap: "1rem",
                  position: "relative",
               }}
            >
               <PrefixBankAccountNumberField />
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     position: "absolute",
                     top: "1rem",
                     left: "26%",
                  }}
               >
                  <Heading mt="1.5rem" variant={"Medium"} hasMargin={false}>
                     -
                  </Heading>
               </Box>
               <BankAccountNumberField />
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     position: "absolute",
                     top: "1rem",
                     left: "70%",
                  }}
               >
                  <Heading
                     mt="1.5rem"
                     variant={"Medium"}
                     hasMargin={false}
                  ></Heading>
               </Box>
               <BankCodeField />
               <Box sx={{ gridColumn: "1/-1" }}>
                  <VariableNumberField />
               </Box>
               {renderErrorMessage()}
            </Box>
         ) : (
            <PaymentAdressField />
         )}
         {shouldRenderSpecField(
            "GeneralFields",
            "NeedAdvancePayment",
            visibilityMatrixes
         ) && (
            <WholeLineContainer>
               <Divider />
            </WholeLineContainer>
         )}
         <NeedAdvancePaymentField />
      </>
   );
};

export default PaymentBankFields;
