import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import CustomRadioGroup from "../../../../../../Components/CustomInputs/CustomRadioGroup";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const NationalityField: React.FC = () => {
   const fieldNameLowerCase = "isForeigner";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

  //  useEffect(() => {
  //     dispatch(
  //        updateSecondStepGeneralsNestedFields({
  //           value: false,
  //           field: fieldNameLowerCase,
  //        })
  //     );
  //  }, [dispatch]);

   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <CustomRadioGroup
            heading="Státní příslušnost"
            firstLabel="Česká"
            secondLabel="Jiná"
            value={!fieldValue as boolean}
            updateFunc={(e: any) => {
               dispatch(
                  updateSecondStepGeneralsNestedFields({
                     value: e === "true" ? false : true,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default NationalityField;
