import { useDispatch, useSelector } from "react-redux";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import SingleLineContainer from "../../../../../../Components/ReportFormTemplate/SingleLineContainer";
import { StoreType } from "../../../../../../redux/store/store";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import React, { useEffect } from "react";
import { Autocomplete } from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import useGetListOfCountries from "../../../../../../CustomHooks/useGetListOfCountries";

const ThroutfulPersonCitizenshipField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "throutfulPersonCitizenship";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.personThroutful[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const dependencyValue = useSelector(
      (state: StoreType) =>
         state.claimState.personModel.personThroutful.throutfulPersonIsForeigner
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   const { listOfCountries } = useGetListOfCountries();

   useEffect(() => {
      if (fieldValue === "") {
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: "Česká republika",
               sectionName: "personThroutful",
               field: fieldNameLowerCase,
            })
         );
      }
   }, [dispatch]);

   return (
      <SingleLineContainer
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
      >
         <Box
            sx={{
               position: "relative",
               "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                     borderColor: "black",
                  },
               "& > div > div > div > div > div": {
                  height: "3rem",
               },
            }}
            ref={ref}
         >
            <Autocomplete
               variant="Select"
               labelText="Státní příslušnost (stát)"
               placeholderText="Uveďte stát vaší státní příslušnosti"
               options={
                  listOfCountries?.map((country: string) => {
                     return {
                        text: country,
                        value: country,
                     };
                  }) ?? []
               }
               value={fieldValue as string}
               onChange={(e) => {
                  dispatch(
                     updateSecondStepGeneralsNestedFields({
                        value: e.target.value,
                        sectionName: "personThroutful",
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </Box>
      </SingleLineContainer>
   );
};

export default ThroutfulPersonCitizenshipField;
