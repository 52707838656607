import { FileEntryType } from "@nn-design-system/react-component-library";
import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
   fileName: string;
   fileSize: string;
   fileEntry: FileEntryType;
};

const initialState: InitialState[] = [];

const authSlice = createSlice({
   name: "files",
   initialState,
   reducers: {
      addFile: (state, action) => {
         const { fileEntry } = action.payload;

         state.push({
            fileName: fileEntry?.file?.name,
            fileSize: fileEntry?.file?.size,
            fileEntry: fileEntry,
         });
      },
      removeFile: (state, action) => {
         const { fileNamePlusSize } = action.payload;
         return state.filter(
            (file) => file.fileName + file.fileSize !== fileNamePlusSize
         );
      },
      resetFiles: () => {
         return initialState;
      },
   },
});

export const { addFile, removeFile, resetFiles } = authSlice.actions;
export default authSlice.reducer;
