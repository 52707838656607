import { useEffect } from "react";
import { updateThirdStepGeneralFields } from "../../../../../../redux/reducers/claimReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import useFetch from "../../../../../../CustomHooks/useFetch";
import { Select } from "@nn-design-system/react-component-library";
import SanitizeNumber from "../../../../../../Utils/SanitizeInputs/SanitizeNumber";
import { Box } from "@mui/material";

const BankCodeField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "bankCode";
   const fieldValue = useSelector(
      (state: StoreType) => state.claimState.paymentModel[fieldNameLowerCase]
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const { sendRequest: sendRequestGetBankCodes, data: dataGetBankCodes } =
      useFetch();

   const callGetBankCodes = () => {
      sendRequestGetBankCodes({
         method: "GET",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_GetListOfBankCode,
         headers: {
            "Content-Type": "application/json",
         },
      });
   };

   useEffect(() => {
      callGetBankCodes();
   }, []);

   const isThereSpecError = () => {
      return claimFieldErrorsMessages.some(
         (error) => error.propertyId === "BankCode"
      );
   };

   return (
      <Box sx={{ "& > div > label": { whiteSpace: "nowrap" } }}>
         <Select
            labelText="Kód banky"
            //  variant="Select"
            placeholderText="Kód"
            options={
               dataGetBankCodes
                  ? dataGetBankCodes.map((code: string) => ({
                       text: code,
                       value: code,
                    }))
                  : []
            }
            value={fieldValue || ""}
            onChange={(e: any) => {
               dispatch(
                  updateThirdStepGeneralFields({
                     value: SanitizeNumber(e.target.value),
                     field: "bankCode",
                  })
               );
            }}
            dataState={isThereSpecError() ? "Invalid" : undefined}
         />
      </Box>
   );
};

export default BankCodeField;
