import {
	Button,
	Divider,
	FileEntryType,
	FileUpload,
	Heading,
	Icon,
	IconButton,
	Link,
	Modal,
	Paragraph,
	useDesignTokens,
} from "@nn-design-system/react-component-library";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useFetch from "../../CustomHooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";
import { updateFifthStepFields } from "../../redux/reducers/claimReducer";
import { colourInteractionDefault } from "../../Config/colors";
import {
	getFontFamily,
	getFontWeight,
} from "@nn-design-system/design-tokens/web";
import icon from "../../Assets/iconForFifthStep.png";

type UploadFilesModalProps = {
	isModalOpen: boolean;
	setIsModalOpen: any;
	dataCreateClaim: any;
	openFinishModal: any;
	callCreateClaim: any;
};

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
	isModalOpen,
	setIsModalOpen,
	dataCreateClaim,
	openFinishModal,
	callCreateClaim,
}) => {
	const [files, setFiles] = useState<FileEntryType[]>([]);
	const claimId = useSelector((state: StoreType) => state.claimState.id);
	const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState("");
	const dispatch = useDispatch();

	const claimModel = useSelector((state: StoreType) => state.claimState);

	const documentsToDownloadList = dataCreateClaim.documentsToDownloadList;

	const {
		sendRequest: sendRequestUploadFiles,
		data: dataUploadFiles,
		loading: loadingUploadFiles,
		error: errorUploadFiles,
		resetFetch: resetFetchUploadFiles,
		fetchFired: fetchFiredUploadFiles,
	} = useFetch();

	const {
		sendRequest: sendRequestSignOnlineByOwnSign,
		data: dataSignOnlineByOwnSign,
		loading: loadingSignOnlineByOwnSign,
		error: errorSignOnlineByOwnSign,
		resetFetch: resetFetchSignOnlineByOwnSign,
		fetchFired: fetchFiredSignOnlineByOwnSign,
	} = useFetch();

	const { token } = useSelector((state: StoreType) => state.tokenState);

	const downloadFile = (id: string, name: string) => {
		fetch(
			process.env.REACT_APP_BaseURL! +
				process.env.REACT_APP_DownloadClaimDocument,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMKey!,
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					documentId: id,
					claimViewModelList: claimModel,
				}),
			}
		)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = name;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
	};

	useEffect(() => {
		dispatch(
			updateFifthStepFields({
				field: "claimReportRowKey",
				value: dataCreateClaim?.claimReportRowKey,
			})
		);
	}, []);

	const callUploadFiles = () => {
		if (files.length === 0) {
			callSignOnlineByOwnSign();
			// Kontrolu zatim vyhazujeme, ale mozna do budoucna nejaky warning?
			// setFileUploadErrorMessage("Nahrajte alespoň jeden soubor");
			return;
		}
		const finalFiles = new FormData();

		files.forEach((fileObj) => {
			finalFiles.append(
				"files",
				fileObj.file,
				"signedDocument_" + fileObj.file.name
			);
		});

		finalFiles.append("claimId", claimId as string);

		sendRequestUploadFiles({
			method: "POST",
			url:
				process.env.REACT_APP_BaseURL! + process.env.REACT_APP_UploadFiles,
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
			data: finalFiles,
		});
	};

	const callSignOnlineByOwnSign = () => {
		sendRequestSignOnlineByOwnSign({
			method: "POST",
			url:
				process.env.REACT_APP_BaseURL! +
				process.env.REACT_APP_SignOnlineByOwnSign,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			data: {
				id: dataCreateClaim.id,
				claimReportRowKey: dataCreateClaim.claimReportRowKey,
				claimReportPartitionKey: dataCreateClaim.claimReportPartitionKey,
			},
		});
	};

	useEffect(() => {
		if (errorUploadFiles) {
			setFileUploadErrorMessage("Nahrajte alespoň jeden soubor");
			resetFetchUploadFiles();
		} else if (dataUploadFiles) {
			callSignOnlineByOwnSign();
		}
	}, [fetchFiredUploadFiles]);

	useEffect(() => {
		if (dataSignOnlineByOwnSign) {
			openFinishModal();
			setIsModalOpen(false);
		}
	}, [fetchFiredSignOnlineByOwnSign]);

	const addFiles = (newFiles: FileEntryType[]) => {
		// Kontrolu zatim vyhazujeme, ale mozna do budoucna nejaky warning?
		// if (fileUploadErrorMessage) {
		// 	setFileUploadErrorMessage("");
		// }
		setFiles((prevState) => {
			return [...prevState, ...newFiles];
		});
	};

	const removeFile = (fileToRemove: File) => {
		setFiles((prevState) => {
			return [
				...prevState.filter((file) => file.file.name !== fileToRemove.name),
			];
		});
	};

	const handleCloseModal = () => {
		dispatch(
			updateFifthStepFields({
				field: "finishedClaimBySendDocumentOnline",
				value: false,
			})
		);
		callCreateClaim(4, false);
		setIsModalOpen(false);
		setFiles([]);
	};

	const fontFamilyTokens = useDesignTokens(getFontFamily);
	const fontWeightTokens = useDesignTokens(getFontWeight);

	return (
		<Modal
			variant="Elevated"
			isOpen={isModalOpen}
			onClose={handleCloseModal}
			height="auto"
			width="clamp(5rem, 100vw, 46rem)"
			hasBackdrop
		>
			<Box
				sx={{
					padding: "2rem 1rem 1rem 1rem",
					display: "flex",
					flexDirection: "column",
					position: "relative",
				}}
			>
				<Box sx={{ position: "absolute", top: ".5rem", right: "-.25rem" }}>
					<IconButton iconName="Cross" onClick={handleCloseModal} />
				</Box>
				<Heading hasMargin={false} mb=".75rem" variant={"Large"} mr="1rem">
					Vlastnoruční podpis vašeho hlášení
				</Heading>
				<Box
					sx={{
						display: "flex",
						"@media (max-width: 600px)": {
							flexDirection: "column-reverse",
							alignItems: "center",
							marginBottom: "1rem",
						},
					}}
				>
					<Paragraph hasMargin={false}>
						Níže si
						<Box
							component={"span"}
							sx={{
								fontWeight: `${fontWeightTokens.strongImportance.get}`,
								fontFamily: `${fontFamilyTokens.strongImportance.value}`,
								marginLeft: "4px",
							}}
						>
							stáhněte podpisový arch
						</Box>
						. Ten vytiskněte a
						<Box
							component={"span"}
							sx={{
								fontWeight: `${fontWeightTokens.strongImportance.get}`,
								fontFamily: `${fontFamilyTokens.strongImportance.value}`,
								marginLeft: "4px",
							}}
						>
							vlastnoručně podepište
						</Box>
						. Podepsaný dokument oskenujte nebo vyfoťte a nahrajte nám ho
						zpět. Můžete tak učinit i později skrze email.
					</Paragraph>
					<Box
						sx={{
							gridArea: "img",
							"& > *": {
								width: { xs: "6rem", sm: "7rem", md: "8rem" },
								transform: {
									xs: "translateY(0)",
									sm: "translateY(-1rem)",
									md: "translateY(-1.5rem)",
								},
								"@media (max-width: 600px)": {
									marginBottom: "0",
								},
								marginBottom: "-1.5rem",
							},
						}}
					>
						<img src={icon} alt="icon of documents"></img>
					</Box>
				</Box>
				<Heading hasMargin={false} variant={"Small"} mt="1rem">
					Podpisový arch ke stažení
				</Heading>
				<Box
					sx={{
						display: "flex",
						gap: "0.5rem",
						margin: ".5rem 0 2rem .5rem",
					}}
				>
					<Icon
						color={colourInteractionDefault}
						name="ArrowDownLine"
						customSize="1.5rem"
					/>
					<Box
						sx={{ "& > *": { textDecoration: "underline !important" } }}
					>
						<Link
							onClick={() => {
								downloadFile(
									documentsToDownloadList[
										documentsToDownloadList.length - 1
									].id,
									documentsToDownloadList[
										documentsToDownloadList.length - 1
									].name
								);
							}}
							variant="SecondaryStrong"
							text={
								documentsToDownloadList[
									documentsToDownloadList.length - 1
								].name
							}
						/>
					</Box>
				</Box>
				<Heading hasMargin={false} variant={"Medium"} mb=".5rem">
					Nahrajte podepsaný dokument
				</Heading>
				<Divider variant="Brand" mb=".5rem" />
				<FileUpload
					buttonText="Přiložit soubor"
					canUploadMultiple
					hasUploadButton
					labelText={""}
					onChange={(e, v) => {
						addFiles(v);
					}}
					onUploadStop={(e, v) => {
						removeFile(v);
					}}
					subtext=""
					value={files}
					variant="Large"
					validationText={fileUploadErrorMessage}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginTop: "2rem",
						flexDirection: "row",
						"@media (max-width: 500px)": {
							flexDirection: "column !important",
							gap: "1rem",
						},
					}}
				>
					<Button
						iconProps={{
							placement: "Left",
							name: "ArrowLeft",
						}}
						variant="SecondaryMedium"
						onClick={handleCloseModal}
					>
						Zvolit jiný způsob
					</Button>
					<Button
						iconProps={{
							placement: "Right",
							name: "ArrowRight",
						}}
						variant="PrimaryMedium"
						onClick={callUploadFiles}
					>
						Odeslat podpis
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default UploadFilesModal;
