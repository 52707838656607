import SectionTemplate from "../../Template/SectionTemplate";
import InjuryDateOfClaimFromField from "./InjuryFields/InjuryDateOfClaimFromField";
import InjuryDateOfClaimToField from "./InjuryFields/InjuryDateOfClaimToField";
import InjuryTimeOfClaimField from "./InjuryFields/InjuryTimeOfClaimField";
import InjuryAdditionalInjuryDescriptionField from "./InjuryFields/InjuryAdditionalInjuryDescriptionField";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";

const InjurySection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("injury", visibilityMatrixes)) return null;

   return (
      <SectionTemplate heading="Úraz" iconName="PersonBrokenArm">
         <InjuryDateOfClaimFromField />
         <InjuryDateOfClaimToField />
         <InjuryTimeOfClaimField />
         <InjuryAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default InjurySection;
