import React from "react";
import SectionTemplate from "../../Template/SectionTemplate";
import { StoreType } from "../../../../../../redux/store/store";
import { useSelector } from "react-redux";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import DisabilityDateOfClaimFromField from "./DisabilityFields/DisabilityDateOfClaimFromField";
import DisabilityDateOfClaimToField from "./DisabilityFields/DisabilityDateOfClaimToField";
import DisabilityTimeOfClaimField from "./DisabilityFields/DisabilityTimeOfClaimField";
import DisabilityAdditionalInjuryDescriptionField from "./DisabilityFields/DisabilityAdditionalInjuryDescriptionField";

const DisabilitySection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("disability", visibilityMatrixes)) return null;
   return (
      <SectionTemplate heading="Invalidita" iconName="RockingChair">
         <DisabilityDateOfClaimFromField />
         <DisabilityDateOfClaimToField />
         <DisabilityTimeOfClaimField />
         <DisabilityAdditionalInjuryDescriptionField />
      </SectionTemplate>
   );
};

export default DisabilitySection;
