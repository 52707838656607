import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { colourTextSubtle, errorLight } from "../../Config/colors";
import { setShowCancelClaimModal } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../redux/store/store";
import { useLocation } from "react-router-dom";
import {
   Button,
   Heading,
   Icon,
   Logo,
   Paragraph,
} from "@nn-design-system/react-component-library";
import ClaimCancelModal from "../Modals/ClaimCancelModal";
import { useState } from "react";
import LogoutConfirmationModal from "../Modals/LogoutConfirmationModal";

const getNavbarHeight = (isSmallScreen: boolean) => {
   return isSmallScreen ? 3.5 : 5.5;
};

const Navbar = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
   const height = getNavbarHeight(isSmallScreen);
   const dispatch = useDispatch();
   const location = useLocation();
   const isLoginPage = location.pathname === "/";
   const isLoggedIn = useSelector(
      (state: StoreType) => state.authState.isLoggedIn
   );

   const userAuthenticationStatus = useSelector(
      (state: StoreType) => state.authState.userAuthenticationStatus
   );

   const showCancelClaimModal = useSelector(
      (state: StoreType) => state.authState.showCancelClaimModal
   );

   const personModel = useSelector(
      (state: StoreType) => state.authState.personModel
   );

   const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] =
      useState(false);

   const handleRedirect = () => {
      if (location.pathname.includes("/claimReport")) {
         dispatch(setShowCancelClaimModal(true));
      } else {
         setShowLogoutConfirmationModal(true);
         //  dispatch(logout());
         //  dispatch(setIsSelectedPerson(false));
         //  navigate("/");
         //  dispatch(setShowLogoutModal(true));
      }
   };

   const LogoutAndClaimCancelButton = (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginRight: isSmallScreen ? "1rem" : "1rem",
            zIndex: "10",
            marginTop: "0rem",
         }}
      >
         {!isSmallScreen && userAuthenticationStatus === "authorized" && (
            <Paragraph variant="Small" hasMargin={false}>
               {`Přihlášen: ${personModel.firstName} ${personModel.lastName}`}
            </Paragraph>
         )}
         <Box
            sx={{
               display: "flex",
               gap: ".4rem",
                alignItems: "center",
               cursor: "pointer",
               ":hover": {
                  "& > span > svg": { color: errorLight },
                  "& > *": { color: errorLight },
               },
            }}
            onClick={handleRedirect}
         >
            <Icon
               customSize=".9rem"
               mt=".1rem"
               name={
                  location.pathname.includes("/claimReport") ||
                  location.pathname.includes("/claimQRSignature")
                     ? "Cross"
                     : "LockClosed"
               }
            />
            <Paragraph variant="Small" hasMargin={false}>
               {isSmallScreen
                  ? ""
                  : location.pathname.includes("/claimReport") ||
                    location.pathname.includes("/claimQRSignature")
                  ? "Zrušit hlášení"
                  : "Odhlásit"}
            </Paragraph>
         </Box>
      </Box>
   );

   return (
      <Box
         component={"header"}
         sx={{
            width: "100%",
            height: `${height}rem`,
            // borderBottom: `${colourBorderSubtle} 1px solid`,
            boxShadow: "0px 4px 12px rgba(65, 65, 65, 0.15)",
            display: "flex",
            alignItems: "center",
            position: "relative",
            justifyContent: "center",
            zIndex: "10",
            "& > h1": { color: colourTextSubtle },
         }}
      >
         <Box
            sx={{
               width: "clamp(10rem, 100%, 70em) !important",
               position: "relative",
               alignItems: "center",
               justifyContent: "space-between",
               "@media (max-width: 650px)": {
                  justifyContent: "flex-start",
               },
               display: "flex",
            }}
         >
            <Logo name="NNGroup" customSize={isSmallScreen ? "3rem" : "5rem"} />
            <Box
               sx={{
                  position: "absolute",
                  height: "100%",
                  left: "0",
                  right: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "10",
                  "& > h1": { color: colourTextSubtle },
               }}
            >
               <Heading
                  hasMargin={false}
                  htmlTag="h1"
                  variant={isSmallScreen ? "XS" : "Medium"}
                  ml={isSmallScreen ? "1rem" : "0"}
               >
                  Online hlášení pojistné události
               </Heading>
            </Box>
            {isLoginPage ? (
               <Box
                  sx={{
                     position: "relative",
                     right: "1rem",
                     zIndex: "1000",
                     marginRight: "1rem",
                     "@media (max-width: 650px)": {
                        marginRight: "0rem",
                        display: "none",
                     },
                     "& > * > *": { color: "white" },
                  }}
               >
                  <Button
                     onClick={() => {
                        window.location.href = "https://www.nn.cz";
                     }}
                     variant="PrimaryMedium"
                  >
                     Web NN Pojišťovny
                  </Button>
               </Box>
            ) : (
               LogoutAndClaimCancelButton
            )}
            <ClaimCancelModal isModalOpen={showCancelClaimModal} />
            <LogoutConfirmationModal
               isModalOpen={showLogoutConfirmationModal}
               setModalOff={setShowLogoutConfirmationModal}
            />
         </Box>
      </Box>
   );
};

export { Navbar, getNavbarHeight };
