import React from "react";
import SectionTemplate from "../../Template/SectionTemplate";
import InjuryDescriptionField from "./GeneralFields/InjuryDescriptionField";
import InjuryBodyPartField from "./GeneralFields/InjuryBodyPartField";
import InjuryBodyPartInjuredPreviouslyField from "./GeneralFields/InjuryBodyPartInjuredPreviouslyField";
import HasHealthProblemsBeforeField from "./GeneralFields/HasHealthProblemsBeforeField";
import InjuryBodyPartInjuredPreviouslyDescriptionField from "./GeneralFields/InjuryBodyPartInjuredPreviouslyDescriptionField";
import InjuryDuringSportField from "./GeneralFields/InjuryDuringSportField";
import TypeOfSportField from "./GeneralFields/TypeOfSportField";
import HaveSportClubRegistrationField from "./GeneralFields/HaveSportClubRegistrationField";
import DateOfSportRegistrationField from "./GeneralFields/DateOfSportRegistrationField";
import ContactToSportRegistrationField from "./GeneralFields/ContactToSportRegistrationField";
import IsInjuryOccuriedDueJobField from "./GeneralFields/IsInjuryOccuriedDueJobField";
import IsInvalidClientOrChildBeforeInjuryField from "./GeneralFields/IsInvalidClientOrChildBeforeInjuryField";
import InvalidClientOrChildBeforeDiagnoseField from "./GeneralFields/InvalidClientOrChildBeforeDiagnoseField";
import NeedAccountElapsedExternalWaitingTimeField from "./GeneralFields/NeedAccountElapsedExternalWaitingTimeField";
import IsWorkInjuryField from "./GeneralFields/IsWorkInjuryField";
import IsInjuryDueCarAccidentField from "./GeneralFields/IsInjuryDueCarAccidentField";
import IsDeadDueInjuryField from "./GeneralFields/IsDeadDueInjuryField";
import IsIntoxicatedOrInfluencedOfAlcoholField from "./GeneralFields/IsIntoxicatedOrInfluencedOfAlcoholField";
import IsContactedToPoliceField from "./GeneralFields/IsContactedToPoliceField";
import PoliceAddressField from "./GeneralFields/PoliceAddressField";
import YourActivityJobDuringInjuryField from "./GeneralFields/YourActivityJobDuringInjuryField";
import InvalidClientOrChildBeforeInjuryFromField from "./GeneralFields/InvalidClientOrChildBeforeInjuryFromField";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../../redux/store/store";
import { shouldRenderSection } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import InjuryPlaceField from "./GeneralFields/InjuryPlaceField";
import HasProblemsChildOrYouBeforeDescField from "./GeneralFields/HasProblemsChildOrYouBeforeDescField";
import InvolvedAccidentTypeEnumField from "./GeneralFields/InvolvedAccidentTypeEnumField";
import DateOfFirstTreatmentField from "./GeneralFields/DateOfFirstTreatmentField";
import TimeOfFirstTreatmentField from "./GeneralFields/TimeOfFirstTreatmentField";

const GeneralSection: React.FC = () => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (!shouldRenderSection("GeneralFields", visibilityMatrixes)) return null;

   return (
      <SectionTemplate
         heading="Obecné informace k pojistné události"
      >
         <DateOfFirstTreatmentField />
         <TimeOfFirstTreatmentField />
         <InjuryPlaceField />
         <InjuryDescriptionField />
         <InjuryBodyPartField />
         <InjuryBodyPartInjuredPreviouslyField />
         <InjuryBodyPartInjuredPreviouslyDescriptionField />
         <HasHealthProblemsBeforeField />
         <HasProblemsChildOrYouBeforeDescField />
         <InjuryDuringSportField />
         <TypeOfSportField />
         <HaveSportClubRegistrationField />
         <DateOfSportRegistrationField />
         <ContactToSportRegistrationField />
         <IsInvalidClientOrChildBeforeInjuryField />
         <InvalidClientOrChildBeforeDiagnoseField />
         <InvalidClientOrChildBeforeInjuryFromField />
         <IsDeadDueInjuryField />
         <IsInjuryOccuriedDueJobField />
         <IsWorkInjuryField />
         <YourActivityJobDuringInjuryField />
         <IsInjuryDueCarAccidentField />
         <InvolvedAccidentTypeEnumField />
         <IsIntoxicatedOrInfluencedOfAlcoholField />
         <IsContactedToPoliceField />
         <PoliceAddressField />
         <NeedAccountElapsedExternalWaitingTimeField />
      </SectionTemplate>
   );
};

export default GeneralSection;
