import React from "react";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomTextarea from "../../../../../../../Components/CustomInputs/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import CustomInput from "../../../../../../../Components/CustomInputs/CustomInput";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const PersonalDoctorOrAmbulanceNameField: React.FC = () => {
   const fieldNameLowerCase = "personalDoctorOrAmbulanceName";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
   const dispatch = useDispatch();

   const fieldValue = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const { isChild, isThirdPerson } = useSelector(
      (state: StoreType) => state.claimState.reportModel
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   const { selectedTypeOfClaims } = useSelector(
      (state: StoreType) => state.claimState
   );

   const isDeathBeingReported = selectedTypeOfClaims.some(
      (claim: string) => claim === "death"
   );
   return (
      <WholeLineContainer
         dependencyValue={shouldRenderSpecField(
            "GeneralFields",
            fieldNameUpperCase,
            visibilityMatrixes
         )}
      >
         <CustomInput
            labelText={
               isChild
                  ? "Jméno, příjmení a adresa praktického lékaře (pediatra)"
                  : isThirdPerson
                  ? "Jméno, příjmení a adresa praktického lékaře pojištěné osoby"
                  : isDeathBeingReported
                  ? "Jméno, příjmení a adresa praktického lékaře"
                  : "Jméno, příjmení a adresa vašeho praktického lékaře"
            }
            name="PersonalDoctorOrAmbulanceName"
            placeholderText="Uveďte lékaře a jeho adresu"
            value={fieldValue || ""}
            onChange={(e: any) => {
               dispatch(
                  updateFirstStepGeneralFields({
                     value: e.target.value,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
            maxCharacters="100"
            inputRef={ref}
         />
      </WholeLineContainer>
   );
};

export default PersonalDoctorOrAmbulanceNameField;
