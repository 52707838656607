import React from "react";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import IsExposedPersonField from "./IsExposedPersonField";
import { Collapsable } from "../../../../../Components/Collapsable";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";

const text1 =
   "Prohlášení pojistníka: Pokud jsem uvedl/a svůj e-mail, kontaktní adresu nebo telefonní číslo (dále také „kontakty“), souhlasím s tím, aby se mnou společnosti skupiny NN, zejména pak NN Penzijní společnost a.s., NN Životní pojišťovna N.V., pobočka pro Českou republiku (pojišťovna), a společnost NN Finance, s.r.o., prostřednictvím těchto kontaktů komunikovaly. Dávám souhlas ke sdílení svých uvedených kontaktů všemi výše uvedenými společnostmi. V rámci elektronické komunikace budou společnosti skupiny NN používat vždy nejaktuálnější, tj. poslední udané kontaktní údaje. Pokud v budoucnu uzavřu smlouvu s některou ze společností skupiny NN nebo provedu změnu kontaktů ve stávajících smlouvách, nově uvedené kontakty budou považovány za žádost o změnu na všech mnou uzavřených smlouvách. Písemnosti doručené prostřednictvím elektronické komunikace mi nebudou zasílány v listinné podobě. I přes udělený souhlas s elektronickou komunikací mi mohou společnosti skupiny NN zasílat vybrané písemnosti v listinné podobě. Souhlasím s využitím kontaktů pro elektronické právní jednání týkající se všech uzavřených smluv se společnostmi skupiny NN. Souhlasím, že pomocí výše uvedených kontaktů mohu potvrzovat změny všech těchto mých smluv.";
const text2 =
   "V případě, že vám budeme vyplácet více než 25 tis. Kč, potřebujeme mít jistotu, že je vyplácíme správné osobě. Proto vás budeme potřebovat identifikovat. Identifikaci můžete provést na obchodním místě NN, www.nn.cz/kontakty/ nebo za poplatek na Czech Point či obecním úřadě. Pokud vám budeme vyplácet částku vyšší než 500 tis. Kč, tak mimo vaši identifikaci ještě potřebujeme od vás nebo od osoby uplatňující nárok na pojistné plnění ověřený podpis. To můžete provést na Czech Pointu nebo u notáře. Ověření podpisu neplatí pro výplatu na vaši smlouvu u KK INVESTMENT PARTNERS, a.s. Jste-li zákonný zástupce dítěte a pojistné plnění bude vyšší než 25 tis. Kč., budeme potřebovat kromě vaší identifikace doložit i doklad, který vás opravňuje k zastupování dítěte (např. rodný list, rozhodnutí soudu, občanský průkaz se zápisem dítěte). Pokud jste příjemce výplaty a zároveň jste politicky exponovanou osobou (dle zákona 253/2008 Sb. o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu.), můžeme vás požádat o vyplnění finančního dotazníku.";
const text3 =
   "Souhlasím, aby si pojišťovna a jí určená zdravotnická zařízení vyžádala veškerou dokumentaci o mém zdravotním stavu, příp. o zdravotním stavu pojištěného dítěte, a o průběhu léčení pro potřeby šetření škodní události. Dále zprošťuji lékaře od jejich slibu mlčenlivosti a rovněž souhlasím, aby si pojišťovna vyžádala potřebné podklady u policie, správních orgánů a jiných pojišťoven o této škodní události. Zplnomocňuji všechny lékaře a zdravotnická zařízení, u kterých jsem byl/a já nebo pojištěné dítě léčen/a či ošetřen/a, a ostatní pojišťovny, u kterých jsem byl/a já nebo pojištěné dítě veden/a jako pojištěný, k poskytování úplných informací o zdravotním stavu v případě, že o ně pojišťovna požádá. Prohlašuji, že jsem veškeré dotazy zodpověděl/a plně a pravdivě a že jsem si vědom/a důsledků nesprávných nebo neúplných odpovědí na povinnost pojišťovny plnit. Jsem si vědom/a svých prohlášení týkajících se zdravotního stavu a dokumentace učiněných v pojistné smlouvě a ve zdravotním dotazníku.";

const textDeath =
   "Souhlasím, aby si pojišťovna a jí určená zdravotnická zařízení vyžádala veškerou dokumentaci o zdravotním stavu pojištěného, příp. o zdravotním stavu pojištěného dítěte, a o průběhu léčení pro potřeby šetření škodní události. Dále zprošťuji lékaře od jejich slibu mlčenlivosti a rovněž souhlasím, aby si pojišťovna vyžádala potřebné podklady u policie, správních orgánů a jiných pojišťoven o této škodní události. Zplnomocňuji všechny lékaře a zdravotnická zařízení, u kterých byl pojištěný nebo pojištěné dítě léčen/a či ošetřen/a, a ostatní pojišťovny, u kterých byl pojištěný nebo pojištěné dítě veden/a jako pojištěný, k poskytování úplných informací o zdravotním stavu v případě, že o ně pojišťovna požádá. Prohlašuji, že jsem veškeré dotazy zodpověděl/a plně a pravdivě a že jsem si vědom/a důsledků nesprávných nebo neúplných odpovědí na povinnost pojišťovny plnit. Jsem si vědom/a prohlášení týkajících se zdravotního stavu a dokumentace učiněných v pojistné smlouvě a ve zdravotním dotazníku.";
const AgreementSection: React.FC = () => {
   const isDeathSelected = useSelector(
      (state: StoreType) => state.claimState.selectedTypeOfClaims
   ).some((item) => item === "death");

   return (
      <>
         <WholeLineContainer>
            <>
               <Collapsable
                  heading="Souhlas s elektronickou komunikací a kontraktací"
                  text={text1}
                  padding="0rem 1rem 1rem 1rem"
                  isFirst={true}
               />
               <Collapsable
                  heading="Obecné informace ke každému hlášení"
                  text={text2}
                  padding="0rem 1rem 1rem 1rem"
               />
               {isDeathSelected ? (
                  <Collapsable
                     heading="Prohlášení obmyšlené osoby / příjemce výplaty"
                     text={textDeath}
                     padding="0rem 1rem 1rem 1rem"
                  />
               ) : (
                  <Collapsable
                     heading="Prohlášení pojištěného / zástupce pojištěného"
                     text={text3}
                     padding="0rem 1rem 1rem 1rem"
                  />
               )}
            </>
         </WholeLineContainer>
         <IsExposedPersonField />
      </>
   );
};

export default AgreementSection;
