import React from "react";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Divider,
   Heading,
   Paragraph,
} from "@nn-design-system/react-component-library";
import GridContainer from "../../../../../Components/ReportFormTemplate/GridContainer";
import CityField from "./AdressFields/CityField";
import AdressField from "./AdressFields/AddressField";
import PostCodeField from "./AdressFields/PostCodeField";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import IsContactAddressDifferent from "./AdressFields/IsContactAddressDifferent";
import ContactAddressField from "./AdressFields/ContactAddressField";
import ContactCityField from "./AdressFields/ContactCityField";
import ContactPostCodeField from "./AdressFields/ContactPostCodeField";

type AddressSectionProps = {};

const AddressSection: React.FC<AddressSectionProps> = ({}) => {
   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   if (
      !shouldRenderSpecField("GeneralFields", "Address", visibilityMatrixes) &&
      !shouldRenderSpecField("GeneralFields", "City", visibilityMatrixes) &&
      !shouldRenderSpecField("GeneralFields", "PostCode", visibilityMatrixes)
   ) {
      return null;
   }
   return (
      <GridContainer>
         <WholeLineContainer>
            <>
               <Heading
                  variant={"Medium"}
                  hasMargin={false}
                  mb=".5rem"
                  mt="1rem"
               >
                  Adresa trvalého bydliště
               </Heading>
               <Divider variant="Brand"></Divider>
            </>
         </WholeLineContainer>
         <WholeLineContainer>
            <Paragraph variant="Small" mt="-.5rem" hasMargin={false}>
               Pokud došlo ke změně trvalé adresy, doložte nám změnu zasláním
               kopie občanského průkazu ve čtvrtém kroku.
            </Paragraph>
         </WholeLineContainer>
         <AdressField />
         <CityField />
         <PostCodeField />
         <IsContactAddressDifferent />
         <ContactAddressField />
         <ContactCityField />
         <ContactPostCodeField />
      </GridContainer>
   );
};

export default AddressSection;
