import { Box } from "@mui/material";
import { Paragraph, Textarea } from "@nn-design-system/react-component-library";
import React from "react";
import { colourTextDefault } from "../../Config/colors";

type CustomTextareaProps = {
	labelText: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	validationText: string;
	numberOfRows?: number;
	name?: string;
	placeholderText?: string;
	dependencyValue?: boolean | null;
	maxCharacters?: string;
	inputRef?: React.RefObject<HTMLDivElement>;
	highlightMaxCharacters?: boolean;
};

const CustomTextarea: React.FC<CustomTextareaProps> = ({
	labelText,
	value,
	onChange,
	validationText,
	numberOfRows = 2,
	name,
	placeholderText,
	dependencyValue = true,
	maxCharacters = "500",
	inputRef,
	highlightMaxCharacters = false,
}) => {
	return dependencyValue ? (
		<div ref={inputRef}>
			<Box
				sx={{
					position: "relative",
					"& > div > div > div": {
						outlineColor: `${colourTextDefault} !important`,
						"&:focus": {},
					},
				}}
			>
				<Textarea
					name={name}
					labelText={labelText}
					value={value}
					numberOfRows={numberOfRows}
					onChange={onChange}
					validationText={validationText}
					placeholderText={placeholderText}
				/>
				{maxCharacters && (
					<Box
						sx={{
							position: "absolute",
							top: `${!labelText ? "-1.6rem" : "0rem"}`,
							right: "0rem",
							opacity: "0.7",
							"@media (max-width: 700px)": {
								bottom: "-1.5rem",
								top: "auto",
							},
							"@keyframes shake": {
								"0%": { transform: "translate(0, 0)" },
								"10%": { transform: "translate(-4px, 0)" },
								"20%": { transform: "translate(4px, 0)" },
								"30%": { transform: "translate(-4px, 0)" },
								"40%": { transform: "translate(4px, 0)" },
								"50%": { transform: "translate(-4px, 0)" },
								"60%": { transform: "translate(4px, 0)" },
								"70%": { transform: "translate(-4px, 0)" },
								"80%": { transform: "translate(4px, 0)" },
								"90%": { transform: "translate(-4px, 0)" },
								"100%": { transform: "translate(0, 0)" },
							},
							"& > p": {
								color: highlightMaxCharacters ? "red" : "inherit",
								animation: highlightMaxCharacters
									? "shake 0.3s"
									: "none",
							},
						}}
					>
						<Paragraph hasMargin={false} variant="Small">
							Maximálně {maxCharacters} znaků.
						</Paragraph>
					</Box>
				)}
				{/* {minCharacters && (
           <Box
           sx={{
             position: "absolute",
             bottom: "-26px",
             right: "0rem",
             opacity: "0.7",
             "@media (max-width: 500px)": {
               bottom: "-1.5rem",
               top: "auto",
              },
            }}
            >
            <Paragraph hasMargin={false} variant="Small">
            Minimálně {minCharacters} znaků.
            </Paragraph>
            </Box>
          )} */}
			</Box>
		</div>
	) : null;
};

export default CustomTextarea;
