import React from "react";

export type inputType =
   | "text"
   | "number"
   | "birthNumber"
   | "date"
   | "boolean"
   | "noValidation";
type sanitizedValue = string | Date | number;
type Char = string & { length: 1 };

type SetFormhandler<T> = {
   formInfo: T;
   setFormInfo: React.Dispatch<React.SetStateAction<T>>;
};

function SetFormHandler<T>({ formInfo, setFormInfo }: SetFormhandler<T>) {
   const setFormHandler = (
      field: keyof T,
      value: any,
      inputType: inputType
   ) => {
      let sanitizedValue: sanitizedValue;

      switch (inputType) {
         case "text":
            sanitizedValue = value.replace(/['";]/g, "");
            break;
         case "number":
            const validChars = "0123456789 ";
            sanitizedValue = value
               .split("")
               .filter((char: Char) => validChars.includes(char))
               .join("");
            break;
         case "birthNumber":
            const validCharsBirthNumber = "0123456789 /";
            sanitizedValue = value
               .split("")
               .filter((char: Char) => validCharsBirthNumber.includes(char))
               .join("");
            break;
         case "date":
            if (value === null || !isNaN(value)) {
               sanitizedValue = new Date(value);
               sanitizedValue.setDate(sanitizedValue.getDate() + 1);
               sanitizedValue = sanitizedValue.toISOString().slice(0, 10);
            } else {
               sanitizedValue = value;
            }
            break;
         case "noValidation":
            sanitizedValue = value;
            break;
         default:
            sanitizedValue = value;
      }

      setFormInfo(() => ({
         ...formInfo,
         [field]: sanitizedValue,
      }));
   };

   return { setFormHandler };
}

export default SetFormHandler;
