import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import useFetch from "../../../../../../CustomHooks/useFetch";
import { Select } from "@nn-design-system/react-component-library";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const CodeOfHealthInsuranceField: React.FC = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const dispatch = useDispatch();
   const fieldNameLowerCase = "codeOfHealthInsurance";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);

   const {
      sendRequest: sendRequestGetInsuranceCodes,
      data: dataGetInsuranceCodes,
      loading: loadingGetInsuranceCodes,
      error: errorGetInsuranceCodes,
      resetFetch: resetFetchGetInsuranceCodes,
      fetchFired: fetchFiredGetInsuranceCodes,
   } = useFetch();

   const callGetInsuranceCodes = () => {
      sendRequestGetInsuranceCodes({
         method: "GET",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_GetListOfHealthInsurance,
         headers: {
            "Content-Type": "application/json",
         },
      });
   };

   useEffect(() => {
      callGetInsuranceCodes();
   }, []);

   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   if (
      !shouldRenderSpecField(
         "GeneralFields",
         fieldNameUpperCase,
         visibilityMatrixes
      )
   ) {
      return null;
   }
   return (
      <div ref={ref}>
         <Select
            labelText="Kód zdravotní pojišťovny"
            placeholderText="Vyberte pojišťovnu"
            name="select"
            onChange={(e) => {
               dispatch(
                  updateSecondStepGeneralsNestedFields({
                     value: e.target.value.toString() as string,
                     field: fieldNameLowerCase,
                  })
               );
            }}
            options={
               dataGetInsuranceCodes?.result.map((code: any) => {
                  return {
                     text: isSmallScreen
                        ? code.insurenceCodeId
                        : `${code.insurenceCodeId} - ${code.name}`,
                     value: code.insurenceCodeId,
                  };
               }) || [{ text: "", value: "" }]
            }
            testId="select"
            value={fieldValue as string}
            validationText={handleShowErrorMessage(
               fieldNameUpperCase,
               claimFieldErrorsMessages
            )}
         />
      </div>
   );
};

export default CodeOfHealthInsuranceField;
