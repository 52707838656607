import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import { StoreType } from "../../../../../../redux/store/store";
import React, { useEffect } from "react";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";
import { Box } from "@mui/material";
import { Autocomplete } from "@nn-design-system/react-component-library";
import SingleLineContainer from "../../../../../../Components/ReportFormTemplate/SingleLineContainer";
import useGetListOfCountries from "../../../../../../CustomHooks/useGetListOfCountries";

const CitizenshipField = () => {
   const dispatch = useDispatch();
   const fieldNameLowerCase = "citizenship";
   const fieldNameUpperCase =
      fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

   const { fieldValue, visibilityMatrixes, claimFieldErrorsMessages } =
      GetValuesForSecondStepGeneralFields(fieldNameLowerCase);

   const dependencyValue = useSelector(
      (state: StoreType) => state.claimState.personModel.isForeigner
   );
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, fieldNameUpperCase);

   const { listOfCountries } = useGetListOfCountries();

   useEffect(() => {
      if (fieldValue === "") {
         dispatch(
            updateSecondStepGeneralsNestedFields({
               value: "Česká republika",
               field: fieldNameLowerCase,
            })
         );
      }
   }, [dispatch]);

   return (
      <SingleLineContainer
         dependencyValue={
            shouldRenderSpecField(
               "GeneralFields",
               fieldNameUpperCase,
               visibilityMatrixes
            ) && dependencyValue
         }
      >
         <Box
            sx={{
               position: "relative",
               "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                     borderColor: "black",
                  },
               "& > div > div > div > div > div": {
                  height: "3rem",
               },
            }}
            ref={ref}
         >
            <Autocomplete
               variant="Select"
               labelText="Státní příslušnost (stát)"
               placeholderText="Uveďte vaši státní příslušnosti"
               options={
                  listOfCountries?.map((country: string) => {
                     return {
                        text: country,
                        value: country,
                     };
                  }) ?? []
               }
               value={fieldValue as string}
               onChange={(e) => {
                  dispatch(
                     updateSecondStepGeneralsNestedFields({
                        value: e.target.value.toString() as string,
                        field: fieldNameLowerCase,
                     })
                  );
               }}
               validationText={handleShowErrorMessage(
                  fieldNameUpperCase,
                  claimFieldErrorsMessages
               )}
            />
         </Box>
      </SingleLineContainer>
   );
};

export default CitizenshipField;
