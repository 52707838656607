import React from "react";
import WholeLineContainer from "../../../../../Components/ReportFormTemplate/WholeLineContainer";
import {
   Divider,
   Heading,
   Icon,
   Paragraph,
} from "@nn-design-system/react-component-library";
import { Box } from "@mui/material";
import { IconName } from "@nn-design-system/react-component-library/dist/components/Icon/index.types";
import { colourInteractionDefault } from "../../../../../Config/colors";

type SectionTemplateProps = {
   children?: React.ReactNode;
   heading: string;
   iconName?: IconName;
   description?: string;
};

const SectionTemplate: React.FC<SectionTemplateProps> = ({
   children,
   heading,
   iconName,
   description,
}) => {
   return (
      <>
         <WholeLineContainer>
            <>
               <Box
                  sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}
               >
                  {iconName && (
                     <Box>
                        <Icon
                           name={iconName}
                           color={colourInteractionDefault}
                        ></Icon>
                     </Box>
                  )}
                  <Heading
                     variant="Medium"
                     hasMargin={false}
                     mb=".5rem"
                     mt="1rem"
                  >
                     {heading}
                  </Heading>
               </Box>
               <Divider variant="Brand"></Divider>
               <Paragraph hasMargin={false}>{description}</Paragraph>
            </>
         </WholeLineContainer>
         {children}
      </>
   );
};

export default SectionTemplate;
