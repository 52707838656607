import React, { useEffect } from "react";
import CustomDateInput from "../../../../../../../Components/CustomInputs/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { updateFirstStepDynamicFields } from "../../../../../../../redux/reducers/claimReducer";
import {
   addErrorMessage,
   handleShowErrorMessage,
   removeErrorMessage,
   resetClaimFieldErrors,
} from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import useFetch from "../../../../../../../CustomHooks/useFetch";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const WorkIncapacityDateOfClaimFromField: React.FC = () => {
   const dispatch = useDispatch();

   const DateOfClaimFromValueWorkIncapacity = useSelector(
      (state: StoreType) =>
         state.claimState.injuryDescriptionModelBase.injuryDescriptionModel.find(
            (model) => model.typeOfClaimEnum === 2
         )?.dateOfClaimFrom
   );

   const visibilityMatrixes = useSelector(
      (state: StoreType) => state.visibilityMatrixesState
   );

   const claimFieldErrorsMessages = useSelector(
      (state: StoreType) => state.claimFieldErrorsState
   );

   const token = useSelector((state: StoreType) => state.tokenState.token);
   const { personalId } = useSelector(
      (state: StoreType) => state.claimState.clientIdentification
   );

   //  const {
   //     sendRequest: sendRequestValidateDate,
   //     data: dataValidateDate,
   //     error: errorValidateDate,
   //     resetFetch: resetFetchValidateDate,
   //     fetchFired: fetchFiredValidateDate,
   //  } = useFetch();

   //  const callValidateDate = (date: string) => {
   //     sendRequestValidateDate({
   //        method: "POST",
   //        url:
   //           process.env.REACT_APP_BaseURL! +
   //           process.env.REACT_APP_ValidateDateForClaimType!,
   //        headers: {
   //           "Content-Type": "application/json",
   //           Authorization: `Bearer ${token}`,
   //        },
   //        data: {
   //           personId: personalId,
   //           injuryDateValue: date,
   //           typeOfClaimEnum: 2,
   //        },
   //     });
   //  };

   //  useEffect(() => {
   //     if (dataValidateDate === false) {
   //        dispatch(
   //           addErrorMessage({
   //              propertyId: "incapacity.DateOfClaimFrom",
   //              message: "Pojištění nebylo aktivní v uvedeném datu",
   //           })
   //        );
   //     } else {
   //        dispatch(removeErrorMessage("incapacity.DateOfClaimFrom"));
   //     }
   //  }, [fetchFiredValidateDate]);
   const ref = React.useRef<HTMLDivElement>(null);
   useScrollToTopErrorInput(ref, "incapacity.DateOfClaimFrom");
   return (
      <CustomDateInput
         labelText="Počátek pracovní neschopnosti"
         name="DateOfClaimFrom"
         value={DateOfClaimFromValueWorkIncapacity || undefined}
         onChange={(e: any) => {
            if (Date.parse(e)) {
               //  callValidateDate(e);
            }
            dispatch(
               updateFirstStepDynamicFields({
                  value: !isNaN(Date.parse(e)) ? e?.toISOString() : null,
                  field: "dateOfClaimFrom",
                  typeOfClaimEnum: 2,
               })
            );
         }}
         validationText={handleShowErrorMessage(
            "incapacity.DateOfClaimFrom",
            claimFieldErrorsMessages
         )}
         dependencyValue={shouldRenderSpecField(
            "incapacity",
            "DateOfClaimFrom",
            visibilityMatrixes
         )}
         inputRef={ref}
      />
   );
};

export default WorkIncapacityDateOfClaimFromField;
